import { useCallback, Suspense } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Outlet, ScrollRestoration, UIMatch, Location } from "react-router-dom";

const excludedPathsFromRestoration: string[] = ["/contact", "/about-us"];
export const MainLayout = () => {
  const getKey = useCallback((location: Location, matches: UIMatch[]) => {
    return excludedPathsFromRestoration.includes(location.pathname)
      ? location.key
      : location.pathname;
  }, []);
  return (
    <Box sx={{ height: { xs: "100%" }}}>
      <Suspense fallback={<CircularProgress />}>
        <Outlet />
      </Suspense>
      <ScrollRestoration getKey={getKey} />
    </Box>
  );
};
