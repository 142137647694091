import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import COLORS from "constants/colors";

interface CountdownProps {
  date: number;
}

const Countdown: React.FC<CountdownProps> = ({ date }) => {
  const [timeLeft, setTimeLeft] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = useCallback(() => {
    const now = moment().valueOf();
    const difference = date - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft({ days, hours, minutes, seconds });
    } else {
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  }, [date]);

  useEffect(() => {
    calculateTimeLeft(); // needed for the first render to avoid to wait a second for calculation

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: { md: 4, xs: 2 },
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}
          className="fade-in"
        >
          {timeLeft.days}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          días
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h4"
          sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}
          className="fade-in"
        >
          {timeLeft.hours}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          horas
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h4"
          sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}
          className="fade-in"
        >
          {timeLeft.minutes}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          minutos
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h4"
          sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}
          className="fade-in pulse"
        >
          {timeLeft.seconds}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          segundos
        </Typography>
      </Box>
    </Box>
  );
};

export default Countdown;
