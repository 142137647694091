import { Promotion, EndpointGenerator, UserType } from "@mapsy/shared";
import { selectSessionState } from "features/session/session.slice";
import { useAxios } from "hooks/useAxios";
import moment from "moment";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useSelector } from "react-redux";

interface PromotionContextProps {
  currentClosestPromotion?: Promotion;
  showBanner: boolean;
  alreadyStarted: boolean;
  closeBanner: () => void;
}

const MAX_DAYS_BEFORE_PROMOTION = 20;
const now = moment().valueOf();

export const PromotionContext = createContext<PromotionContextProps>({
  showBanner: false,
  alreadyStarted: false,
  closeBanner: () => {},
});

export const PromotionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getData } = useAxios();
  const { profileInfo } = useSelector(selectSessionState);
  const [currentClosestPromotion, setCurrentClosestPromotion] =
    useState<Promotion>();
  const [showBanner, setShowBanner] = useState(false);
  const [alreadyStarted, setAlreadyStarted] = useState(false);

  const closeBanner = useCallback(() => {
    setShowBanner(false);
  }, []);

  const calculateDaysLeft = useCallback(
    (showSinceDaysBefore: number, startDate: number) => {
      const daysToPromotion = moment(startDate).diff(now, "days");

      if (moment(startDate).isBefore(now)) {
        setAlreadyStarted(true);
      }
      setShowBanner(daysToPromotion <= showSinceDaysBefore);
    },
    []
  );

  const getCurrentClosestPromotion = useCallback(async () => {
    const endpoint = EndpointGenerator.PromotionsAPI.currentPromotions(
      now,
      moment.tz.guess(),
      MAX_DAYS_BEFORE_PROMOTION
    );
    const promotions: Promotion[] = await getData(endpoint);
    if (!promotions?.length) {
      return;
    }
    const currentClosestPromotion = promotions[0];
    setCurrentClosestPromotion(currentClosestPromotion);
  }, []);

  useEffect(() => {
    getCurrentClosestPromotion();
  }, []);

  useEffect(() => {
    if (!currentClosestPromotion || profileInfo?.type === UserType.Therapist) {
      return;
    }

    const { bannerConfig, startDate, endDate } = currentClosestPromotion;

    if (endDate < moment().valueOf()) {
      return; // if the promotion is already done
    }

    calculateDaysLeft(bannerConfig.showSinceDaysBefore, startDate);
  }, [currentClosestPromotion, profileInfo]);

  return (
    <PromotionContext.Provider
      value={{
        currentClosestPromotion,
        showBanner,
        alreadyStarted,
        closeBanner,
      }}
    >
      {children}
    </PromotionContext.Provider>
  );
};

export const usePromotions = () => {
  const { ...rest } = useContext(PromotionContext);

  return rest;
};
