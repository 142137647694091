import "moment/locale/es";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import router from "./router";
import store from "./store";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers";

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"es"}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
