import { useCallback, useMemo } from "react";

import countryInfo from "../data/countries_info.json";
import { GradesInput } from "components/molecules/form/GradesInput";
import { ProfessionalExperienceInput } from "components/molecules/form/ProfessionalExperienceInput";
import { ProvidedServicesInput } from "components/molecules/form/ProvidedServicesInput";
import { WorkScheduleInput } from "components/molecules/form/WorkScheduleInput";
import { dayScheduleMenuIterable } from "utils/iterable";
import {
  Catalog,
  Currency,
  ErrorMsg,
  InputType,
  ProvidedServiceTypes,
  timezonesInfo,
} from "@mapsy/shared";
import { AutoCompletePropsOverride, Form } from "interfaces";
import {
  defaultMaxBirthDate,
  MAX_TOPICS_ID,
} from "constants/defaultUserValues";
import { useModalities } from "./catalogs/useModalities";
import { useTheories } from "./catalogs/useTheories";
import { useSchoolGradeLevels } from "./catalogs/useSchooGradesLevels";
import { useTopics } from "./useTopics";

export const useGetStartedForms = () => {
  const { modalitiesList } = useModalities();
  const { theoriesList } = useTheories();
  const { schoolGradeLevelsList } = useSchoolGradeLevels();
  const { topicsHash } = useTopics();

  const Common: Form = useMemo(
    () => [
      {
        propertyName: "firstName",
        label: "Primer nombre",
        inputType: InputType.Text,
        placeholder: "Primer nombre",
        gridSize: {
          md: 4,
          sm: 6,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "middleName",
        label: "Segundo nombre",
        inputType: InputType.Text,
        placeholder: "Segundo nombre",
        gridSize: {
          md: 4,
          sm: 6,
          xs: 12,
        },
        validation: {
          isRequired: false,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "lastName",
        label: "Apellidos",
        inputType: InputType.Text,
        placeholder: "Apellidos",
        gridSize: {
          md: 4,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "birthDate",
        label: "Fecha de nacimiento",
        helperText: "Fecha de nacimiento",
        inputType: InputType.Date,
        gridSize: {
          md: 6,
          sm: 6,
          xs: 12,
        },
        datePickerProps: {
          maxDate: defaultMaxBirthDate,
        },
      },
      {
        propertyName: "gender",
        label: "Sexo",
        inputType: InputType.Select,
        placeholder: "Sexo",
        helperText: "Sexo",
        menuItems: [
          {
            label: `Femenino`,
            value: 0,
          },
          {
            label: `Masculino`,
            value: 1,
          },
          {
            label: `Otro`,
            value: 2,
          },
        ],
        gridSize: {
          md: 6,
          sm: 6,
          xs: 12,
        },
        validation: {
          isRequired: true,
        },
      },
      {
        propertyName: "email",
        label: "Correo electrónico",
        placeholder: "Correo electrónico",
        inputType: InputType.Text,
        size: "small",
        disabled: true,
        gridSize: {
          md: 12,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 40,
        },
      },
      {
        propertyName: "phone.dial_code",
        label: "",
        inputType: InputType.Autocomplete,
        placeholder: "Lada",
        options: countryInfo
          .map(({ dial_code, es_name, name, code }) => ({
          label: `${es_name || name} (${dial_code})`,
          value: dial_code,
          }))
          .sort((a,b)=> a.label.localeCompare(b.label)),
        gridSize: {
          md: 2,
          sm: 3,
          xs: 12,
        },
        validation: {
          isRequired: true,
        },
      },
      {
        propertyName: "phone.number",
        label: "Número telefónico",
        inputType: InputType.Text,
        placeholder: "Número telefónico",
        type: "phone",
        gridSize: {
          md: 6,
          sm: 9,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 10,
          maxLength: 10,
          formatErrorMsg: ErrorMsg.PhoneFormatNotValid,
        },
      },
      {
        propertyName: "timezone",
        label: "Zona horaria",
        helperText: "Zona horaria",
        inputType: InputType.Autocomplete,
        placeholder: "Zona horaria",
        options: timezonesInfo.map(({ es_timezone, offset, timezone }) => ({
          label: `${es_timezone} (UTC${offset})`,
          value: timezone,
        })),
        gridSize: {
          md: 4,
          sm: 6,
          xs: 12,
        },
        validation: {
          isRequired: true,
        },
      },
    ],
    []
  );

  const ProfessionalExperienceForm: Form = useMemo(
    () => [
      {
        propertyName: "position",
        label: "Cargo Laboral",
        inputType: InputType.Text,
        placeholder: "Cargo Laboral",
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "institution",
        label: "Institución",
        inputType: InputType.Text,
        placeholder: "Institución",
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "startYear",
        label: "Año de inicio",
        inputType: InputType.Text,
        placeholder: "Año de inicio",
        helperText: "Año de inicio",
        textFieldProps: {
          type: "number",
          min: 1950,
          max: 2030,
        },
        validation: {
          isRequired: true,
          minLength: 4,
          maxLength: 4,
          min: 1950,
          max: 2030,
          lowerThan: "endYear",
        },
      },
      {
        propertyName: "endYear",
        label: "Año de fin",
        inputType: InputType.Text,
        placeholder: "Año de fin",
        helperText: "Año de fin",
        textFieldProps: {
          type: "number",
          min: 1950,
          max: 2030,
        },
        validation: {
          isRequired: true,
          minLength: 4,
          maxLength: 4,
          min: 1950,
          max: 2030,
        },
      },
    ],
    []
  );
  const SchoolGradesForm: Form = useMemo(
    () => [
      {
        propertyName: "level",
        label: "Nivel Educativo",
        inputType: InputType.Select,
        placeholder: "Nivel Educativo",
        menuItems: schoolGradeLevelsList,
        validation: {
          isRequired: true,
        },
      },
      {
        propertyName: "institution",
        label: "Institución",
        inputType: InputType.Text,
        placeholder: "Institución",
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "major",
        label: "Área o profesión",
        inputType: InputType.Text,
        placeholder: "Ej. Psicología",
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "year",
        label: "Año de obtención",
        inputType: InputType.Text,
        placeholder: "Año de Obtención",
        textFieldProps: {
          type: "number",
          min: 1950,
          max: 2030,
        },
        validation: {
          isRequired: true,
          minLength: 4,
          maxLength: 4,
          min: 1950,
          max: 2030,
        },
      },
    ],
    [schoolGradeLevelsList]
  );
  const EducationForm: Form = useMemo(
    () => [
      {
        propertyName: "theory",
        label: "Enfoque Terapéutico",
        inputType: InputType.Autocomplete,
        placeholder: "Enfoque Terapéutico",
        options: theoriesList,
        autocompleteProps: {
          size: "small",
        } as AutoCompletePropsOverride,
        gridSize: {
          md: 12,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
        },
      },
      {
        propertyName: "professionalId",
        label: "Cédula profesional",
        inputType: InputType.Text,
        placeholder: "Cedula Profesional",
        gridSize: {
          md: 12,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "schoolGrades",
        label: "",
        inputType: InputType.Projected,
        ChildElement: GradesInput,
        inputs: SchoolGradesForm,
        gridSize: {
          xs: 12,
        },
      },
    ],
    [theoriesList]
  );

  const ExperienceForm: Form = useMemo(
    () => [
      {
        propertyName: "professionalExperience",
        label: "",
        inputType: InputType.Projected,
        ChildElement: ProfessionalExperienceInput,
        inputs: ProfessionalExperienceForm,
        gridSize: {
          xs: 12,
        },
      },
      {
        propertyName: "topicsId",
        label: "",
        helperText: "Solo puedes elegir 5 áreas de atención.",
        autocompleteProps: {
          multiple: true,
        } as AutoCompletePropsOverride,
        inputType: InputType.Autocomplete,
        placeholder: "Areas de atención",
        options: Object.keys(topicsHash).map((id) => ({
          value: id,
          label: topicsHash[id].longNames.es_name,
        })),
        gridSize: {
          xs: 12,
        },
        validation: {
          isRequired: true,
        },
        maxSelectedOptions: MAX_TOPICS_ID,
      },
    ],
    [topicsHash]
  );

  const TopicsForm: Form = useMemo(
    () => [
      {
        propertyName: "id",
        label: "Áreas de atencion",
        inputType: InputType.Text,
        placeholder: "Áreas de atencion",
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
    ],
    []
  );

  const WorkScheduleForm: Form = useMemo(
    () => [
      {
        propertyName: "workable",
        label: "",
        inputType: InputType.Switch,
      },
      {
        propertyName: "startTime",
        label: "",
        inputType: InputType.Select,
        placeholder: "Hora",
        menuItems: [...dayScheduleMenuIterable],
        validation: {
          isRequired: false,
        },
      },
      {
        propertyName: "endTime",
        label: "",
        inputType: InputType.Select,
        placeholder: "Hora",
        menuItems: [...dayScheduleMenuIterable],
        validation: {
          isRequired: false,
        },
      },
    ],
    []
  );

  const ServicesForm: (country?: string) => Form = useCallback(
    (country) => [{
        propertyName: "serviceType",
        label: "",
        inputType: InputType.Select,
        menuItems: [
          {
            label: "Terapia Individual",
            value: ProvidedServiceTypes.Individual,
          },
          {
            label: "Terapia Pareja",
            value: ProvidedServiceTypes.Couple,
          },
          {
            label: "Terapia Familiar",
            value: ProvidedServiceTypes.Family,
          },
          {
            label: "Terapia Grupal",
            value: ProvidedServiceTypes.Groupal,
          },
        ],
        placeholder: "Tipo de terapia",
        validation: {
          isRequired: true,
        },
        gridSize: {
          md: 4,
        },
      },
      {
        propertyName: "price",
        label: "Costo",
        inputType: InputType.Text,
        placeholder: "Costo",
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 6,
          min: 10,
          max: 10000,
        },
        textFieldProps: {
          type: "number",
          min: 10,
          max: 10000,
        },
        gridSize: {
          md: 2,
        },
      },
      {
        propertyName: "currency",
        label: "Moneda",
        inputType: InputType.Select,
        menuItems: [
          {
            label: Currency.MXN,
            value: Currency.MXN,
          },
          {
            label: Currency.USD,
            value: Currency.USD,
          },
          {
            label: Currency.EUR,
            value: Currency.EUR,
          },
        ],
        disabled: country === "Mexico",
        placeholder: "Moneda",
        validation: {
          isRequired: true,
        },
        gridSize: {
          md: 2,
        },
      },
    ],
    []
  );

  const AddressForm: (communitiesMenuItems?: Catalog[], zipCodeRegex?: RegExp) => Form =
    useCallback(
      (communitiesMenuItems, zipCodeRegex) => [
        {
          propertyName: "country",
          label: "",
          inputType: InputType.Autocomplete,
          placeholder: "País",
          options: countryInfo
            .map(({ es_name, name }) => ({
            label: es_name || name,
            value: name,
            }))
            .sort((a,b)=> a.label.localeCompare(b.label)),
          validation: {
            isRequired: true,
            minLength: 2,
            maxLength: 50,
          },
          gridSize: {
            md: 3,
          },
        },
        {
          propertyName: "cp",
          label: "",
          inputType: InputType.Text,
          placeholder: "Código Postal",
          validation: {
            isRequired: true,
            minLength: 2,
            maxLength: 50,
            regex: zipCodeRegex
          },
          gridSize: {
            md: 2,
          },
        },
        {
          propertyName: "state",
          label: "",
          inputType: InputType.Text,
          placeholder: "Estado",
          helperText: "El estado se muestra con base en tu CP y tu país.",
          disabled: true,
          validation: {
            isRequired: true,
            minLength: 2,
            maxLength: 50,
          },
          gridSize: {
            md: 3,
          },
        },
        {
          propertyName: "city",
          label: "",
          inputType: InputType.Text,
          placeholder: "Municipio/Alcaldía",
          validation: {
            isRequired: true,
            minLength: 2,
            maxLength: 50,
          },
          gridSize: {
            md: 3,
          },
        },
        {
          propertyName: "community",
          label: "",
          inputType: InputType.Select,
          menuItems: communitiesMenuItems || [
            { label: "Ingresa tu CP y tu país.", value: "" },
          ],
          placeholder: "Colonia",
          helperText: "Las colonias se muestran con base en tu CP y tu país.",
          validation: {
            isRequired: true,
            minLength: 2,
            maxLength: 50,
          },
          gridSize: {
            md: 3,
          },
        },
        {
          propertyName: "street",
          label: "",
          inputType: InputType.Text,
          placeholder: "Calle",
          validation: {
            isRequired: true,
            minLength: 2,
            maxLength: 50,
          },
          gridSize: {
            md: 3,
          },
        },
        {
          propertyName: "externalNo",
          label: "",
          inputType: InputType.Text,
          placeholder: "No. exterior",
          validation: {
            isRequired: true,
            minLength: 1,
            maxLength: 50,
          },
          gridSize: {
            md: 2,
            xs: 6,
          },
        },
        {
          propertyName: "internalNo",
          label: "",
          inputType: InputType.Text,
          placeholder: "No. interior",
          validation: {
            isRequired: false,
            minLength: 1,
            maxLength: 50,
          },
          gridSize: {
            md: 2,
            xs: 6,
          },
        },
      ],
      []
    );

  const LocationForm: (
    communitiesMenuItems?: Catalog[],
    zipCodeRegex?: RegExp,
    country?: string
  ) => Form = useCallback(
    (communitiesMenuItems, zipCodeRegex, country) => [
      {
        propertyName: "modality",
        label: "Modalidad",
        inputType: InputType.Select,
        menuItems: modalitiesList,
        placeholder: "Modalidad",
        validation: {
          isRequired: true,
        },
        gridSize: {
          md: 10,
        },
      },
      ...AddressForm(communitiesMenuItems, zipCodeRegex),
      {
        propertyName: "providedServices",
        label: "",
        inputType: InputType.Projected,
        ChildElement: ProvidedServicesInput,
        inputs: ServicesForm(country),
      },
      {
        propertyName: "workSchedule",
        label: "",
        inputType: InputType.Projected,
        ChildElement: WorkScheduleInput,
        inputs: WorkScheduleForm,
      },
    ],
    [modalitiesList]
  );

  return {
    Common,
    EducationForm,
    SchoolGradesForm,
    ExperienceForm,
    ProfessionalExperienceForm,
    TopicsForm,
    WorkScheduleForm,
    ServicesForm,
    AddressForm,
    LocationForm,
  };
};
