import timezoneTranslations from "./timezoneTranlations";

const formatTimezonesEs = (timezone: string): string => {
  const esTz: string | undefined = timezoneTranslations[timezone];

  if (!esTz) {
    return timezone;
  }

  const arr = esTz.split("/"); // America/Cancún

  return arr[1] || arr[0];
};

export default formatTimezonesEs;
