import { useCallback, useEffect, useMemo, useState } from "react";

import countryInfo from "../data/countries_info.json";
import { useAxios } from "./useAxios";
import { useAppSelector } from "hooks";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { debounce } from "utils/debounce";
import {
  ErrorMsg,
  StateAndCitiesResponse,
  zipCodesByCountry,
  EndpointGenerator
} from "@mapsy/shared";

const DEBOUNCE_INTERVAL = 3000;
const OtherOption = { label: "Otro", value: "Otro" };
export const useGetStateAndCommunitiesFromZipCode = (
  countryName?: string,
  zipCode?: string,
  defaultCommunity?: string,
  defaultState?: string
) => {
  const { getData, errorMsg, isLoading } = useAxios();
  const { token } = useAppSelector(selectSessionState);

  const defaultCommunityMenuItem = useMemo(
    () =>
      defaultCommunity
        ? [{ label: defaultCommunity, value: defaultCommunity }]
        : [],
    [defaultCommunity]
  );
  const [communitiesMenuItems, setCommunitiesMenuItems] = useState<
    { label: string; value: string }[]
  >(defaultCommunityMenuItem);

  const [state, setState] = useState<string | undefined>(defaultState);
  const [zipCodeRegex, setZipCodeRegex] = useState<RegExp>();

  const fetchCitiesByZipCode = useCallback(
    async (
      zipCode: string,
      countryCode: string,
      token: string,
      signal: AbortSignal
    ) => {
      const endpoint = EndpointGenerator.ZipCodeAPI.getCitiesByZipCode(
        zipCode,
        countryCode
      );
      const response: StateAndCitiesResponse = await getData(endpoint, {
        signal,
        ...setToken(token),
      });
      if (!response) {
        return;
      }
      const { cities, state } = response;
      setCommunitiesMenuItems(() => {
        const menuItems = [];
        if (defaultCommunity) {
          menuItems.push({ label: defaultCommunity, value: defaultCommunity });
        }

        for (const city of cities) {
          if (defaultCommunity && city === defaultCommunity) {
            continue;
          }
          menuItems.push({ label: city, value: city });
        }

        return menuItems.concat(OtherOption);
      });
      setState(state);
    },
    [defaultCommunity]
  );

  useEffect(() => {
    if (errorMsg === ErrorMsg.NotFound) {
      setCommunitiesMenuItems([
        {
          label: "Ingresa un Código Postal válido para mostrar las colonias.",
          value: "",
        },
      ]);
      if (!defaultState) {
        setState("Estado No Encontrado");
      }
    }
  }, [errorMsg, defaultState]);

  const lookForStateAndCitiesByZipCode = useCallback(
    (countryName: string, zipCode: string, signal: AbortSignal) => {
      const foundCountry = countryInfo.find(({ name }) => countryName === name);

      if (foundCountry) {
        const { code: countryCode } = foundCountry;
        const zipCodeRegex =
          zipCodesByCountry[countryCode as keyof typeof zipCodesByCountry];
        setZipCodeRegex(zipCodeRegex);
        fetchCitiesByZipCode(zipCode, countryCode, token, signal);
      }
    },
    []
  );

  const debounceLookForStateAndCities = useMemo(
    () => debounce(DEBOUNCE_INTERVAL, lookForStateAndCitiesByZipCode),
    []
  );

  useEffect(() => {
    if (!countryName || !zipCode) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;

    debounceLookForStateAndCities(countryName, zipCode, signal);

    return () => {
      controller.abort();
    };
  }, [zipCode, countryName, token]);

  return { state, communitiesMenuItems, zipCodeRegex, isLoading };
};
