import { APINames } from "../../enum/shared.enum";
import BaseAPI from "./BaseEndpointAPI";

class FileAPI extends BaseAPI {
  constructor() {
    super(APINames.FILE);
  }

  filename(name: string) {
    return `${this.baseURL}/${name}`;
  }
}

const fileAPI = new FileAPI();
export default fileAPI;
