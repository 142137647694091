import {
  APINames,
  Appointment,
  FindAvailableApppointmentsParams,
  GetAppointmentsParams,
  Therapist,
} from "../..";
import BaseAPI from "./BaseEndpointAPI";

class AppointmentAPI extends BaseAPI {
  constructor() {
    super(APINames.APPOINTMENT);
  }

  byTherapist(
    query: GetAppointmentsParams<keyof Appointment>,
    therapistId: string
  ) {
    const queryString = new URLSearchParams(query as any).toString();
    return `${this.baseURL}/${therapistId}/therapist?${queryString}`;
  }

  byPatient(
    query: GetAppointmentsParams<keyof Appointment>,
    patientId: string
  ) {
    const queryString = new URLSearchParams(query as any).toString();
    return `${this.baseURL}/${patientId}/patient?${queryString}`;
  }

  availableTimeByDay(query: FindAvailableApppointmentsParams) {
    const queryString = new URLSearchParams(query as any).toString();
    return `${this.baseURL}/availableTimeByDay?${queryString}`;
  }
}

const api = new AppointmentAPI();

export default api;
