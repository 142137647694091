import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, TooltipProps } from "@mui/material";

interface Props extends Omit<TooltipProps, "children"> {}
export const InfoTooltip: React.FC<Props> = (props) => {
  return (
    <Tooltip {...props}>
      <InfoIcon fontSize="inherit"/>
    </Tooltip>
  );
};
