import { Box, SxProps, Theme, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

interface Item {
  title?: string;
  subtitle?: string;
}
interface Props {
  items: Item[];
  itemBottomMargin: number;
  sx?: SxProps<Theme>;
  boxStyle?: SxProps<Theme>;
  showItemNumber?: boolean;
}
export const BannerGrid: React.FC<Props> = ({
  items,
  itemBottomMargin = 2,
  sx = {},
  boxStyle = {},
  showItemNumber = false,
}) => {
  return (
    <Grid2
      container
      sx={{
        alignItems: "start",
        justifyContent: "center",
        columnGap: 3,
        flexWrap: { md: "nowrap", xs: "wrap" },
        ...sx,
      }}
    >
      {items.map((item: Item, index: number) => (
        <Box
          key={`banner-item-${item.title || "no-title"}-${index}`}
          sx={{
            textAlign: "center",
            width: "100%",
            mb: { xs: itemBottomMargin, md: 0 },
            ...boxStyle,
          }}
          component="div"
        >
          {showItemNumber && (
            <Typography variant="h2" sx={{ fontWeight: 500 }}>
              {index + 1}
            </Typography>
          )}
          <Typography variant="h3" sx={{ fontWeight: 500 }}>
            {item.title || ""}
          </Typography>
          <Typography variant="h4">{item.subtitle || ""}</Typography>
        </Box>
      ))}
    </Grid2>
  );
};
