import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import t1 from "assets/img/home_users/t1.webp";
import t2 from "assets/img/home_users/t2.webp";
import t3 from "assets/img/home_users/t3.webp";
import { CustomButton } from "../atoms/Button";
import { StylesEnum } from "@mapsy/shared";
import { Paragraphs } from "interfaces";
import { ExampleTherapistPicture } from "components/atoms/ExampleTherapistPicture";

interface Props {
  button: { label: string; href: string };
  paragraphs: Paragraphs;
  title: string;
}

export const Meet: React.FC<Props> = ({ button, paragraphs, title }) => {
  const nav = useNavigate();

  const handleClick = useCallback(() => {
    nav(button.href);
  }, [button.href]);

  return (
    <Container id="meet-out-team" maxWidth="lg" component="section" sx={{ my: {md: 16, xs: 10} }}>
      <Grid2 container justifyContent="center">
        <Grid2
          container
          xs={12}
          md={7}
          sx={{
            alignContent: "flex-start",
            flexDirection: "column",
            rowGap: "32px",
            mb: { xs: 4, md: 0 },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography
            width={{ xs: "100%", md: "75%" }}
            variant="h3"
            sx={{ fontWeight: 500 }}
          >
            {title}
          </Typography>
          <Box width={{ xs: "100%", md: "80%" }}>
            {paragraphs.map((paragraph, index) => (
              <Typography
                fontWeight={400}
                variant="h5"
                key={`paragraph-${index}`}
                {...paragraph}
              />
            ))}
          </Box>
          <CustomButton
            customStyle={StylesEnum.primary}
            onClick={handleClick}
            sx={{
              marginTop: 2,
              flexShrink: 1,
              maxWidth: "207px",
              alignSelf: { md: "flex-start", xs: "center" },
            }}
          >
            {button.label}
          </CustomButton>
        </Grid2>
        <Grid2
          container
          xs={12}
          md={5}
          sx={{
            columnGap: { lg: "25%", xs: "10%", sm: "25%", md: "10%" },
            rowGap: "1rem",
            justifyContent: "center",
            "& > img": {
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPositionX: "center",
              backgroundPositionY: "bottom",
              backgroundColor: "rgba(92, 180, 194, 0.8)",
            },
          }}
        >
          <ExampleTherapistPicture src={t2} alt="therapist-2" />
          <ExampleTherapistPicture src={t1} alt="therapist-1" />
          <ExampleTherapistPicture src={t3} alt="therapist-3" />
        </Grid2>
      </Grid2>
    </Container>
  );
};
