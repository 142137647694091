import React, { MouseEvent, useCallback, useMemo } from "react";
import { Button, ButtonProps, SxProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Zoom from "@mui/material/Zoom";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

import COLORS from "../../constants/colors";
import { Action, StylesEnum } from "@mapsy/shared";
import { Theme } from "@emotion/react";
import { useAnalytics } from "hooks/useAnalytics";
import { useLocation } from "react-router-dom";
import COMPONENTS from "constants/componentNames";

interface Props extends ButtonProps {
  customStyle: StylesEnum;
  isLoading?: boolean;
  hasSucceeded?: boolean;
  hasFailed?: boolean;
}

export const CustomButton: React.FC<Props> = ({
  customStyle,
  isLoading = false,
  disabled,
  hasSucceeded,
  hasFailed,
  sx = {},
  onClick,
  ...rest
}) => {
  const { pathname } = useLocation();
  const { createAnalytic } = useAnalytics();

  const sxStyles: { [key in StylesEnum]: SxProps<Theme> } = useMemo(
    () => ({
      [StylesEnum.primary]: {
        height: "fit-content",
        padding: { md: "12px 48px", xs: "10px 12px" },
        gap: "10",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        backgroundColor: COLORS.BLUE_2,
        color: { md: COLORS.PURE_WHITE, xs: COLORS.WHITE },
        fontFamily: "Poppins",
        fontWeight: "600",
        fontSize: { md: "1rem", xs: "0.8rem" },
        textTransform: "none",
        borderRadius: "14px",
        minWidth: { md: "140px" },
        ":hover": {
          backgroundColor: COLORS.GREY,
          color: COLORS.BLUE_1,
        },
        ":active": {
          backgroundColor: COLORS.BLUE_2,
          color: COLORS.BLUE_1,
        },
      },
      [StylesEnum.secondary]: {
        height: "fit-content",
        padding: { md: "10px 36px" },
        backgroundColor: COLORS.PURE_WHITE,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        color: "black",
        gap: 2,
        fontSize: { md: "1rem", xs: "0.8rem" },
        textTransform: "none",
        fontWeight: "400",
        borderRadius: "10px",
        minWidth: { md: "140px" },
        ":hover": {
          backgroundColor: COLORS.GREY,
          color: COLORS.BLUE_1,
        },
        ":active": {
          backgroundColor: COLORS.GREY,
          color: COLORS.BLUE_1,
        },
      },
    }),
    []
  );

  const isDisabledStyles: SxProps<Theme> = useMemo(
    () =>
      disabled ? { backgroundColor: COLORS.GREY, color: COLORS.BLUE_2 } : {},
    [disabled]
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      createAnalytic({
        action: Action.CLICK,
        componentName: COMPONENTS.CUSTOM_BUTTOM,
        data: {
          children: rest.children?.toString(),
          customStyle,
          pathname,
        },
      });
      onClick?.(e);
    },
    [createAnalytic, onClick]
  );

  if (hasSucceeded) {
    return (
      <Button
        {...rest}
        disabled={true}
        onClick={handleClick}
        sx={
          {
            ...sxStyles[customStyle],
            ...sx,
            backgroundColor: COLORS.BLUE_3,
          } as SxProps
        }
      >
        <Zoom in={true} style={{ transitionDelay: "250ms" }}>
          <CheckIcon color="success" />
        </Zoom>
      </Button>
    );
  }

  if (hasFailed) {
    return (
      <Button
        {...rest}
        disabled={true}
        onClick={handleClick}
        sx={
          {
            ...sxStyles[customStyle],
            ...sx,
            backgroundColor: COLORS.GREY,
          } as SxProps
        }
      >
        <Zoom in={true} style={{ transitionDelay: "250ms" }}>
          <ErrorIcon color="error" />
        </Zoom>
      </Button>
    );
  }

  if (isLoading) {
    return (
      <Button
        {...rest}
        disabled={true}
        onClick={handleClick}
        sx={
          {
            ...sxStyles[customStyle],
            ...sx,
            backgroundColor: COLORS.GREY,
            gap: 1.5,
          } as SxProps
        }
      >
        <CircularProgress size="1.5rem" thickness={4} color="secondary" />
        Cargando...
      </Button>
    );
  }

  return (
    <Button
      {...rest}
      disabled={disabled}
      onClick={handleClick}
      sx={
        {
          ...sxStyles[customStyle],
          ...isDisabledStyles,
          ...sx,
        } as SxProps
      }
    />
  );
};
