import React, { Ref, useCallback, useEffect, useRef } from "react";
import { Box, Typography, Zoom } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import COLORS from "constants/colors";
import { AlertType } from "enums/alert.enum";

interface Props {
  alertType?: AlertType;
  msg: string;
  open: boolean;
}

export const AlertInline: React.FC<Props> = ({
  alertType = AlertType.Success,
  msg,
  open,
}) => {
  if (!open) {
    return <></>;
  }

  if (alertType === AlertType.Success) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 2,
          backgroundColor: COLORS.BLUE_3,
          borderRadius: "8px",
          paddingY: 1,
          paddingX: 2,
          alignItems: "center",
          my: 1,
        }}
        maxWidth="sm"
      >
        <Zoom in={true} style={{ transitionDelay: "250ms" }}>
          <CheckIcon color="success" />
        </Zoom>
        <Typography sx={{ color: COLORS.BLUE_1 }}>
          {msg}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Zoom in={true} style={{ transitionDelay: "250ms" }}>
        <CheckIcon color="success" />
      </Zoom>
      <Typography variant="h5" sx={{ color: COLORS.BLUE_1 }}>
        {msg}
      </Typography>
    </Box>
  );
};
