import { useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CustomButton } from "components/atoms/Button";
import { startSession } from "features/session/session.slice";
import { useAppDispatch } from "hooks";
import { useAuth } from "hooks/useAuthAPI";
import { useQuery } from "hooks/useQuery";
import { TitledLayout } from "layouts/TitledLayout";
import { ErrorMsg, StylesEnum, UserType } from "@mapsy/shared";
import { GoogleRedirectionAction, RegistrationAction } from "@mapsy/shared";
import { GOOGLE_REDIRECTION_ACTION, homePageByUserType } from "./SignInAndUp";
import { CustomLink } from "components/atoms/Link";
import { SELECTED_APPOINTMENT } from "components/molecules/TherapistCard";
import { SelectedAppointmentState } from "interfaces";

const SIGNIN_ROUTE = "/users/signin";

const GoogleOAuthSuccessRedirect = () => {
  const query = useQuery();
  const { getGoogleProfile, errorMsg, isLoading } = useAuth();
  const [userAction, setUserAction] = useState<GoogleRedirectionAction>();
  const selectedAppointment: SelectedAppointmentState | null = useMemo(() => {
    try {
      const data = sessionStorage.getItem(SELECTED_APPOINTMENT);
      if (data) {
        return JSON.parse(data) as SelectedAppointmentState;
      }
      return null;
    } catch (e) {
      return null;
    }
  }, []);

  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const getSavedAction = useCallback(async () => {
    const googleRedirectionAction = localStorage.getItem(
      GOOGLE_REDIRECTION_ACTION
    );

    if (!googleRedirectionAction) {
      nav(SIGNIN_ROUTE);
      return;
    }

    const { action, userType }: GoogleRedirectionAction = JSON.parse(
      googleRedirectionAction
    );

    if (action === undefined || userType === undefined) {
      nav(SIGNIN_ROUTE);
      return;
    }

    setUserAction({ action, userType });
  }, []);

  useEffect(() => {
    getSavedAction();
  }, []);

  const handleChangeUserAction = useCallback(
    async (
      access_token: string,
      refresh_token: string,
      userAction: GoogleRedirectionAction
    ) => {
      const profileInfo = await getGoogleProfile({
        access_token,
        refresh_token,
        ...userAction,
      });

      if (!profileInfo?.login?.access_token) {
        return;
      }

      const { access_token: mapsyAccessToken } = profileInfo.login;
      dispatch(startSession({ access_token: mapsyAccessToken }));

      const { action, userType } = userAction;

      if (action === RegistrationAction.SignUp) {
        nav("/get-started");
        return;
      }

      if (selectedAppointment && userType === UserType.Patient) {
        nav(`/appointment/confirmation`);
        return;
      }

      nav(homePageByUserType[userType]);
    },
    [selectedAppointment]
  );

  useEffect(() => {
    const accessToken = query.get("accessToken");
    const refreshToken = query.get("refreshToken");
    if (accessToken && refreshToken && userAction) {
      localStorage.removeItem(GOOGLE_REDIRECTION_ACTION);
      handleChangeUserAction(accessToken, refreshToken, userAction);
    }
  }, [userAction]);

  if (errorMsg === ErrorMsg.GoogleAuthFailed) {
    return (
      <TitledLayout title={"¡Algo salió mal!"}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Grid item md={12}>
            <Typography sx={{ textAlign: "center" }}>{errorMsg}</Typography>
          </Grid>
          <CustomButton
            onClick={() => nav(SIGNIN_ROUTE)}
            customStyle={StylesEnum.primary}
            children={"Iniciar Sesión"}
            disableRipple={true}
            isLoading={isLoading}
            sx={{
              borderRadius: "14px",
            }}
          />
        </Grid>
      </TitledLayout>
    );
  }

  if (errorMsg === ErrorMsg.UserNotFoundToLogin && userAction) {
    return (
      <TitledLayout title={errorMsg}>
        <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
          <CustomButton
            onClick={() =>
              setUserAction((_userAction) => ({
                ...userAction,
                action: RegistrationAction.SignUp,
              }))
            }
            customStyle={StylesEnum.primary}
            children={"Registrarme"}
            isLoading={isLoading}
            disableRipple={true}
            sx={{
              borderRadius: "14px",
            }}
          />
        </Grid>
      </TitledLayout>
    );
  }

  if (errorMsg === ErrorMsg.ExistingUser && userAction) {
    return (
      <TitledLayout title={errorMsg}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Grid item md={12}>
            <Typography sx={{ textAlign: "center" }}>
              Puedes iniciar sesión ahora.
            </Typography>
          </Grid>
          <CustomButton
            onClick={() =>
              setUserAction((_userAction) => ({
                ...userAction,
                action: RegistrationAction.SignIn,
              }))
            }
            customStyle={StylesEnum.primary}
            children={"Iniciar Sesión"}
            disableRipple={true}
            isLoading={isLoading}
            sx={{
              borderRadius: "14px",
            }}
          />
        </Grid>
      </TitledLayout>
    );
  }

  return (
    <TitledLayout title="Espera a ser redireccionado...">
      <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Grid>
      <Grid item md={12}>
        <Typography sx={{ textAlign: "center" }}>
          Si este proceso tarda demasiado, haz{" "}
          <CustomLink to={SIGNIN_ROUTE} underline>
            click aquí
          </CustomLink>
        </Typography>
      </Grid>
    </TitledLayout>
  );
};

export default GoogleOAuthSuccessRedirect;
