import React, { FormEvent, useCallback, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import { capitalize, Grid, Typography } from "@mui/material";

import COLORS from "constants/colors";
import { ModalLayout } from "layouts/ModalLayout";
import { capitalizeName } from "utils/capitalize";
import {
  InputType,
  Modality,
  ModalitySpanish,
  ProvidedService,
  StylesEnum,
} from "@mapsy/shared";
import {
  Location,
  ParsedSelectedPreconfirmationTime,
  Therapist,
  SelectedPreconfirmationTime,
  HoursByDayHash,
  ProvidedServiceTypes,
  ProvidedServiceTypesSpanish,
} from "@mapsy/shared";
import { Form, FormInput } from "interfaces";
import { CustomButton } from "../atoms/Button";
import { InputField } from "../atoms/InputField";
import { defaultService } from "../molecules/AvailableTimeTable";
import { DATE_LONG_FORMAT } from "constants/defaultUserValues";

interface Props {
  isOpen: boolean;
  onClose: (args: ParsedSelectedPreconfirmationTime | undefined) => void;
  location: Location;
  therapist: Therapist;
  selectedTime: SelectedPreconfirmationTime;
  arrayOfNextDays: Moment[];
  hoursByDayHash: HoursByDayHash;
}

export const PreConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  location,
  therapist,
  selectedTime,
  arrayOfNextDays,
  hoursByDayHash,
}) => {
  const [localSelectedTime, setLocalSelectedTime] =
    useState<SelectedPreconfirmationTime>(selectedTime);
  const therapistName = useMemo(
    () =>
      capitalizeName([
        therapist.firstName,
        therapist.middleName,
        therapist.lastName,
      ]),
    [therapist]
  );

  const handleChangeTime = useCallback(
    (name: "day" | "hour", value: Moment) => {
      if (name === "day") {
        setLocalSelectedTime((_LocalSelectedTime) => ({
          ..._LocalSelectedTime,
          day: value,
          hour: moment(hoursByDayHash[value.valueOf()][0]),
        }));
        return;
      }

      setLocalSelectedTime((_LocalSelectedTime) => ({
        ..._LocalSelectedTime,
        hour: value,
      }));
    },
    [hoursByDayHash, setLocalSelectedTime]
  );

  const handleSelectService = useCallback(
    (serviceType: ProvidedServiceTypes) => {
      setLocalSelectedTime((_LocalSelectedTime) => ({
        ..._LocalSelectedTime,
        service:
          location?.providedServices?.find(
            (ser) => ser.serviceType === serviceType
          ) || defaultService,
      }));
    },
    [location, setLocalSelectedTime]
  );

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      const { hour, service } = localSelectedTime;
      onClose({ hour: hour.valueOf(), service });
    },
    [localSelectedTime, onClose]
  );

  const inputs: Form = useMemo(() => {
    const allInputs: any[] = [
      {
        propertyName: "name",
        label: "",
        inputType: InputType.Text,
        value: therapistName,
        disabled: true,
        gridSize: {
          md: 12,
          xs: 12,
        },
      },
      {
        propertyName: "modality",
        label: "",
        inputType: InputType.Text,
        value:
          (location.modality !== undefined &&
            ModalitySpanish[location.modality]) ||
          "No disponible",
        disabled: true,
        gridSize: {
          md: 12,
          xs: 12,
        },
      },
      {
        propertyName: "location",
        label: "",
        inputType: InputType.Text,
        value: location
          ? `${location.community}, ${location.city}, ${location.state}`
          : "Ubicación del consultorio no encontrada",
        helperText:
          "Una vez que agendes, te proporcionaremos la dirección completa por correo.",
        disabled: true,
        gridSize: {
          md: 12,
          xs: 12,
        },
      },
      {
        propertyName: "day",
        label: "",
        inputType: InputType.Select,
        debounce: false,
        value: localSelectedTime.day.valueOf(),
        menuItems: arrayOfNextDays
          .filter(
            (momentDay) => hoursByDayHash[momentDay.valueOf()]?.length > 0
          )
          .map((nextDay) => ({
            value: nextDay.valueOf(),
            label: capitalize(nextDay.format(DATE_LONG_FORMAT)),
          })),
        handleChange: (propertyName: string, value: any, c: any) =>
          handleChangeTime(propertyName as "day", moment(value)),
        validation: {
          isRequired: true,
        },
        gridSize: {
          md: 12,
          xs: 12,
        },
      },
      {
        propertyName: "hour",
        label: "",
        inputType: InputType.Select,
        debounce: false,
        value: localSelectedTime.hour.valueOf(),
        menuItems: hoursByDayHash[localSelectedTime.day.valueOf()]?.map(
          (hour) => ({
            value: hour,
            label: moment(hour).format("HH:mm"),
          })
        ),
        handleChange: (propertyName: string, value: any, c: any) =>
          handleChangeTime(propertyName as "hour", moment(value)),
        validation: {
          isRequired: true,
        },
        gridSize: {
          md: 12,
          xs: 12,
        },
      },
      {
        propertyName: "service",
        label: "",
        inputType: InputType.Select,
        debounce: false,
        value: localSelectedTime.service.serviceType,
        menuItems: location.providedServices.map((service) => ({
          label: `${ProvidedServiceTypesSpanish[(service as ProvidedService).serviceType]} - $${
            service.price
          } (${service.currency})`,
          value: service.serviceType,
        })),
        validation: {
          isRequired: true,
        },
        handleChange: (propertyName: string, value: any, c: any) =>
          handleSelectService(value),
        gridSize: {
          md: 12,
          xs: 12,
        },
      },
    ];

    if (location.modality === Modality.Presencial) {
      return allInputs;
    }

    // se remueve el input con la ubicación si es en línea
    allInputs.splice(2, 1);
    return allInputs;
  }, [
    localSelectedTime,
    arrayOfNextDays,
    hoursByDayHash,
    therapistName,
    location,
    handleChangeTime,
    handleSelectService,
  ]);

  return (
    <ModalLayout isOpen={isOpen} onClose={() => onClose(undefined)}>
      <form action="post" onSubmit={handleSubmit}>
        <Grid
          container
          sx={{ justifyContent: "center", rowGap: { md: 3, xs: 1.5 } }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                color: COLORS.BLUE_1,
                textAlign: "center",
              }}
            >
              Datos de tu cita
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{
                color: COLORS.BLUE_1,
                textAlign: "center",
              }}
            >
              Confirma la modalidad, el costo y la moneda.
            </Typography>
            {
              <Typography
                variant="body2"
                sx={{
                  color: COLORS.BLUE_1,
                  textAlign: "center",
                }}
              >
                Si deseas otra modalidad, puedes{" "}
                {therapist.locations.length > 1 &&
                  "consultar otros consultorios del terapeuta o"}{" "}
                elegir otro terapeuta.
              </Typography>
            }
          </Grid>
          {inputs.map(({ gridSize, ...rest }: FormInput, i: number) => (
            <Grid
              {...gridSize}
              item
              key={`preconfir-input-${rest.propertyName}-${i}`}
            >
              <InputField
                backgroundMode="transparent"
                {...rest}
                sx={{
                  ".MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: COLORS.BLUE_1,
                  },
                }}
              />
            </Grid>
          ))}
          <Grid
            item
            md={12}
            lg={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
              gap: 2,
            }}
          >
            <CustomButton
              customStyle={StylesEnum.secondary}
              children={"Regresar"}
              disableRipple={true}
              sx={{
                padding: "10px 12px",
                borderRadius: "10px",
              }}
              onClick={() => onClose(undefined)}
              type="button"
            />
            <CustomButton
              customStyle={StylesEnum.primary}
              children={"Continuar"}
              disableRipple={true}
              sx={{
                padding: "10px 12px",
                borderRadius: "10px",
              }}
              type="submit"
            />
          </Grid>
        </Grid>
      </form>
    </ModalLayout>
  );
};
