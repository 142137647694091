declare global {
  interface Window {
    env: any;
  }
}
type EnvType = {
  REACT_APP_LOGGER: "all" | "info" | "warn" | "error";
  REACT_APP_MAX_LIMIT_THERAPISTS: number;
  REACT_APP_SOCKET: string;
  REACT_APP_SHOW_GOOGLE_BUTTON: "true" | "false" | boolean; // this should be string as it's how env vars from file .env are read, but when it's set from object on file env.js, it can be a boolean
};

export const env: EnvType = { ...process.env, ...window["env"] };
