import { useCallback, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";

import { ErrorMsg } from "@mapsy/shared";
import { ErrorMsgConfig } from "@mapsy/shared";

const defaultMessagesByStatus: ErrorMsgConfig = {
  401: ErrorMsg.Authentication,
  403: ErrorMsg.Forbidden,
  404: ErrorMsg.NotFound,
  409: ErrorMsg.ErrorGeneric,
  500: ErrorMsg.InternalServer,
  504: ErrorMsg.InternalServer,
};

export const useAxios = ({ loadingDefault = false } = {}) => {
  const [isLoading, setIsLoading] = useState(loadingDefault);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const request = useCallback(
    async (
      method: "get" | "post" | "patch" | "delete",
      url: string,
      data?: any,
      config?: AxiosRequestConfig<any>,
      errorConfig?: ErrorMsgConfig
    ) => {
      resetErrors()
      setIsLoading(true);
      try {
        const response = await axios({
          method,
          url,
          data,
          ...config,
        });
        setErrorMsg(null);
        if (config?.signal?.aborted) {
          return null;
        }
        return response.data;
      } catch (e: any) {
        handleError(e, errorConfig);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const getData = useCallback(
    (
      url: string,
      config?: AxiosRequestConfig<any>,
      errorConfig?: ErrorMsgConfig
    ) => request("get", url, undefined, config, errorConfig),
    [request]
  );

  const postData = useCallback(
    async (
      url: string,
      data?: any,
      config?: AxiosRequestConfig<any>,
      errorConfig?: ErrorMsgConfig
    ) => request("post", url, data, config, errorConfig),
    []
  );

  const patchData = useCallback(
    async (
      url: string,
      data: any,
      config?: AxiosRequestConfig<any>,
      errorConfig?: ErrorMsgConfig
    ) => request("patch", url, data, config, errorConfig),
    []
  );

  const deleteData = useCallback(
    async (
      url: string,
      config?: AxiosRequestConfig<any>,
      errorConfig?: ErrorMsgConfig
    ) => request("delete", url, undefined, config, errorConfig),
    []
  );

  const handleError = useCallback((e: any, errorConfig?: ErrorMsgConfig) => {
    const status = e.response?.status || e.status;

    if (axios.isCancel(e)) {
      return;
    }

    if (errorConfig?.[status]) {
      setErrorMsg(errorConfig[status]);
      return;
    }

    if (defaultMessagesByStatus[status]) {
      setErrorMsg(defaultMessagesByStatus[status]);
      return;
    }
    setErrorMsg(e.message || ErrorMsg.Default);
  }, []);

  const resetErrors = useCallback(() => {
    setErrorMsg(null);
  }, []);

  return {
    isLoading,
    errorMsg,
    getData,
    postData,
    patchData,
    deleteData,
    resetErrors,
  };
};
