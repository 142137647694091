import React, { ChangeEvent, useCallback, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { InputField } from "components/atoms/InputField";
import COLORS from "constants/colors";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { capitalizeName } from "utils/capitalize";
import { WorkSchedule, WeekDaysSpanish, WorkDay } from "@mapsy/shared";
import { FormInput, ProjectedProps } from "interfaces";
import _ from "lodash";
import moment from "moment";
import { defaultWorkSchedule } from "constants/defaultUserValues";

const sxGrid = {
  display: "grid",
  gridTemplateColumns: {
    md: "repeat(3, 190px)",
    sm: "120px repeat(2, 120px)",
  },
  '@media (min-width: 0) and (max-width: 425px)': {
    gridTemplateColumns: "1fr",
    gap: 2
  },
  '@media (min-width: 425px) and (max-width: 600px)': {
    gridTemplateColumns: "70px 100px 100px",
  },
  gap: 4,
}
const ScheduleHeader = () => {
  return (<Box
    id="schedule-header"
    sx={sxGrid}
  >
    <Typography
      variant="body2"
      sx={{
        gridColumn: "2 / span 1",
      }}
    >
      De:
    </Typography>
    <Typography
      variant="body2"
      sx={{
        gridColumn: "3 / span 1",
      }}
    >
      A:
    </Typography>
  </Box>);
}
export const WorkScheduleInput: React.FC<ProjectedProps<WorkSchedule>> = ({
  handleChange,
  entity,
}) => {
  const { WorkScheduleForm } = useGetStartedForms();

  const isSmallDevice = useMediaQuery('(max-width:425px)');
  const isMediumScreen = useMediaQuery('(min-width:425px) and (max-width:600px)');
  const [schedule, setSchedule] = useState<WorkSchedule>(
    entity || defaultWorkSchedule
  );

  const debounceHandleChange = useCallback(_.debounce(handleChange, 250), []);

  const propertyChanged = (
    day: string,
    propertyName: string,
    value: any,
    c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const subEnt = { [propertyName]: value };

    if (["endTime", "startTime"].includes(propertyName)) {
      subEnt[propertyName] = moment(value, "HH").local().format();
    }

    setSchedule((prev_entity: WorkSchedule) => {
      const updatedSchedule = {
        ...prev_entity,
        [day]: {
          ...prev_entity[day as keyof WorkSchedule],
          ...subEnt,
        },
      };
      // debounced function to prevent excess of re rendering
      debounceHandleChange("workSchedule", updatedSchedule);
      return updatedSchedule;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Typography
        sx={{
          color: COLORS.BLUE_1,
          textAlign: "center",
          mb: 2,
        }}
      >
        Horarios de trabajo
      </Typography>
      <Box
        id="schedule-container"
        sx={{
          display: "grid",
          gap: 2,
          justifyItems: "center",
          '@media (min-width: 0) and (max-width: 425px)': {
            justifyItems: "normal",
          },
        }}
      >
        { !isSmallDevice ? <ScheduleHeader /> : <></> }
        {Object.entries(WeekDaysSpanish).map(([day, value]) => (
          <Box
            className="schedule-row"
            id={`schedule-day-${day}`}
            key={day}
            sx={{
              position: "relative",
              ...sxGrid,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: isSmallDevice ? 2 : "auto"
              }}
            >
              {isMediumScreen ? (
                <Typography variant="body1" sx={{
                  overflow: "hidden", textOverflow: "ellipsis"
                }}>
                  {capitalizeName(value.slice(0, 2))}
                </Typography>
              ) : (
                <Typography variant="body1">{capitalizeName(value)}</Typography>
              )}
              <InputField
                backgroundMode="transparent"
                handleChange={(...props) => propertyChanged(day, ...props)}
                value={
                  (schedule[day as keyof WorkSchedule].workable as boolean) ||
                  false
                }
                {...WorkScheduleForm[0]}
              />
            </Box>

            {WorkScheduleForm.slice(1).map(
              ({ propertyName, ...input }: FormInput, i: number) => {
                const daySchedule: WorkDay =
                  schedule[day as keyof WorkSchedule];

                const value = moment(
                  daySchedule[propertyName as keyof WorkDay] as string
                ).get("hour");

                return (
                  <> 
                    {isSmallDevice && <Typography variant="body2">{propertyName === "startTime" ? `De:` : `A:`} </Typography>}
                    <InputField
                      disabled={!daySchedule.workable}
                      key={`${propertyName + i}-${day}`}
                      backgroundMode="transparent"
                      handleChange={(...props) => propertyChanged(day, ...props)}
                      name={`${day}-${propertyName}`}
                      propertyName={propertyName}
                      value={value || ""}
                      {...input}
                    />
                  </>
                  
                );
              }
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
