import { ReactNode } from "react";
import { Typography, Grid, SxProps, Theme } from "@mui/material";
import COLORS from "constants/colors";

interface Props {
  title?: string;
  subtitle?: string;
  children: ReactNode;
  containerSx?: SxProps<Theme>;
}

/**
 * Wrapper container using Grid from material with maxWidth as "md" by default
 * @returns React Node using Grid component as parent
 */
export const TitledLayout: React.FC<Props> = ({
  title,
  subtitle,
  children,
  containerSx,
}) => {
  return (
    <Grid
      id="titled-layout"
      container
      maxWidth={"lg"}
      sx={{ justifyContent: "center", ...containerSx }}
      gap={4}
    >
      {title && (
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{
              color: COLORS.BLUE_1,
              textAlign: "center",
              maxWidth: "sm",
              margin: "auto",
            }}
          >
            {title}
          </Typography>
        </Grid>
      )}
      {subtitle && (
        <Grid item xs={12}>
          <Typography
            sx={{
              color: COLORS.BLUE_1,
              textAlign: "center",
            }}
          >
            {subtitle}
          </Typography>
        </Grid>
      )}
      {children}
    </Grid>
  );
};
