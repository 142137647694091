import { StylesEnum } from "@mapsy/shared";
import { Box, Typography } from "@mui/material";
import { CustomButton } from "components/atoms/Button";
import { CustomLink } from "components/atoms/Link";
import COLORS from "constants/colors";
import { GetStartedProps } from "interfaces";

export const PreTherapists: React.FC<GetStartedProps> = ({
  changePage,
  disableSubmit,
}) => {
  return (
    <Box
      sx={{
        height: { xs: "100%", md: "400px" },
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        maxWidth: "680px",
      }}
    >
      <Typography sx={{ color: COLORS.BLUE_1, fontSize: {xs: "1.225rem", md: "1.2rem"} }}>
        Para continuar, necesitamos algunos detalles sobre tu práctica
        profesional.
        <br />
        Si deseas agregar o modificar tu información, ¡no te preocupes!
        <br />
        Podrás agregar esta información fácilmente en la configuración de tu
        perfil una vez que hayas completado tu registro inicial.
      </Typography>
      <CustomButton
        customStyle={StylesEnum.primary}
        children={"Continuar"}
        disableRipple={true}
        disabled={disableSubmit}
        sx={{
          borderRadius:{ xs: "10px", md: "14px" },
          maxWidth: "207px",
          alignSelf: "center",
          marginTop: 8,
          fontSize: { md: "0.95rem", xs: "1.095rem" },
          minWidth: { xs: "125px", md: "140px" },
        }}
        onClick={() => changePage({}, false)}
      />
      <CustomLink
        to="/therapist/my_profile"
        style={{
          color: "rgb(0, 0, 0, 0.5)",
          marginTop: "1rem",
        }}
      >
        Omitir
      </CustomLink>
    </Box>
  );
};
