import { LoadingEnum, OrderEnum } from "@mapsy/shared";
import { selectReasonState, getTopicList } from "features/reason/reasonSice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";

export const useTopics = () => {
  const dispatch = useAppDispatch();
  const { topicsLoading, topicsHash } = useAppSelector(selectReasonState);

  useEffect(() => {
    if (topicsLoading !== LoadingEnum.idle) {
      return;
    }

    dispatch(
      getTopicList({ order: OrderEnum.ASC, orderBy: "longNames.es_name" })
    );
  }, [topicsLoading]);

  return {
    topicsHash,
    topicsLoading,
  };
};
