import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Zoom,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { AccountStatus, Action, COLORS, UserType } from "@mapsy/shared";
import { useAnalytics } from "hooks/useAnalytics";
import { CustomLink } from "components/atoms/Link";
import { useAuth } from "hooks/useAuthAPI";
import { useAppSelector } from "hooks";
import { selectSessionState } from "features/session/session.slice";
import COMPONENTS from "constants/componentNames";
import { AlertType } from "enums/alert.enum";
import { AlertModal } from "components/atoms/AlertModal";

const MAX_SECONDS_TO_WAIT = 120;

interface AccountStatusListItem {
  propertyName: keyof AccountStatus;
  label: string;
  buttonLabel: string;
  onClick: () => any;
}

interface Props {
  userType: UserType;
  accountStatus: AccountStatus;
}

const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box
      sx={{
        padding: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid ${COLORS.BLUE_1}`,
        boxShadow:
          "0px 2px 1px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.25)",
        borderRadius: "24px",
        gap: { md: 4, xs: 2 },
        textAlign: "center",
      }}
    >
      {children}
    </Box>
  );
};

export const AccountStatusSection: React.FC<Props> = ({
  userType,
  accountStatus,
}) => {
  const nav = useNavigate();
  const { createAnalytic } = useAnalytics();
  const { profileInfo } = useAppSelector(selectSessionState);
  const { requestValidationEmail, errorMsg, isLoading } = useAuth();
  const [validationEmailSent, setValidationEmailSent] = useState(false);
  const [secondsToEnableValidationEmail, setSecondsToEnableValidationEmail] =
    useState(MAX_SECONDS_TO_WAIT);
  const [openModal, setOpenModal] = useState(false);
  const isAccountStatusValid = useMemo(() => {
    const propToValidate: Partial<keyof AccountStatus>[] = [
      "registrationComplete",
      "emailVerified",
    ];
    for (const prop of propToValidate) {
      const value = accountStatus[prop];
      if (!value) {
        return false;
      }
    }
    return true;
  }, [accountStatus]);

  const handleCompleteRegistration = useCallback(() => {
    createAnalytic({
      action: Action.CLICK,
      componentName: COMPONENTS.ACCOUNT_STATUS_SECTION,
      data: {
        completeRegistration: true,
      },
    });
    nav("/get-started");
  }, []);

  const handleValidatePhone = useCallback(() => {
    createAnalytic({
      action: Action.CLICK,
      componentName: COMPONENTS.ACCOUNT_STATUS_SECTION,
      data: {
        validatePhone: true,
      },
    });
  }, []);

  const handleValidateEmail = useCallback(async () => {
    if (!profileInfo?.type || !profileInfo.email) {
      return;
    }
    createAnalytic({
      action: Action.CLICK,
      componentName: COMPONENTS.ACCOUNT_STATUS_SECTION,
      data: {
        validateEmail: true,
      },
    });
    const request = await requestValidationEmail({
      email: profileInfo.email,
      userType: profileInfo.type,
    });
    if (request) {
      setValidationEmailSent(true);
      setOpenModal(true);
      setSecondsToEnableValidationEmail(MAX_SECONDS_TO_WAIT);
    }
  }, [profileInfo]);

  useEffect(() => {
    if (!validationEmailSent) {
      return;
    }

    const timer = setInterval(() => {
      setSecondsToEnableValidationEmail((sec) => {
        if (!sec) {
          clearInterval(timer);
          setValidationEmailSent(false);
          return MAX_SECONDS_TO_WAIT;
        }

        return sec - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [validationEmailSent]);

  const list: AccountStatusListItem[] = useMemo(() => {
    const allItems: AccountStatusListItem[] = [
      {
        propertyName: "registrationComplete",
        label: "Información completa",
        onClick: handleCompleteRegistration,
        buttonLabel: "Completar",
      },
      {
        propertyName: "emailVerified",
        label: "Email validado",
        onClick: handleValidateEmail,
        buttonLabel: "Validar email",
      },
      // {
      //   propertyName: "phoneVerified",
      //   label: "Número telefónico validado",
      //   onClick: handleValidatePhone,
      //   buttonLabel: "Validar teléfono",
      // },
    ];

    return allItems;
  }, []);

  return (
    <Wrapper>
      <Accordion
        sx={{
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          m: 0,
          "&.MuiPaper-root": {
            width: "100%",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            ".MuiAccordionSummary-content": {
              my: 0,
            },
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            {isAccountStatusValid ? (
              <>
                <Typography sx={{ fontWeight: 500 }}>
                  ¡Tu perfil está completo!
                </Typography>
                <CheckIcon color="success" fontSize="small" />
                {userType === UserType.Patient && (
                  <Typography>
                    <CustomLink to={"/reason"} underline>
                      ¿Buscas terapeuta?
                    </CustomLink>
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography sx={{ fontWeight: 500 }}>
                  Aún faltan algunas cosas para comenzar
                </Typography>
                <ErrorIcon color="error" />
              </>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ py: 0 }}>
          <Grid container>
            {!isAccountStatusValid && (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  {userType === UserType.Therapist
                    ? "Aparecerás en las búsquedas una vez que completes tu perfil."
                    : "Solo podrás agendar sesiones hasta que completes tu perfil."}
                </Typography>
              </Grid>
            )}
            {list.map(({ onClick, propertyName, label, buttonLabel }) => {
              const value = _.get(accountStatus, propertyName, false);
              const disabled =
                propertyName === "emailVerified" && validationEmailSent;
              return (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    width: "100%",
                  }}
                  key={`account-status-item-${propertyName}`}
                >
                  <Box
                    sx={{
                      display: "grid",
                      width: "100%",
                      maxWidth: "300px",
                      gridTemplateColumns: "3fr 45px",
                      justifyItems: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{label}</Typography>
                    <Zoom in={true} style={{ transitionDelay: "250ms" }}>
                      {value ? (
                        <Tooltip title="Completo">
                          <IconButton disabled>
                            <CheckIcon color="success" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title={buttonLabel}>
                          <IconButton onClick={onClick} disabled={disabled}>
                            <ErrorIcon color="error" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Zoom>
                  </Box>
                </Grid>
              );
            })}
            {validationEmailSent && (
              <Grid item xs={12}>
                <Typography variant="caption" sx={{ width: "fit-content" }}>
                  Revisa tu correo ahora. Para solicitar otro correo de
                  validación, espera: {secondsToEnableValidationEmail} segundos.
                </Typography>
                <Typography variant="caption" sx={{ width: "fit-content" }}>
                  Si ya validaste tu email, recarga la página.
                </Typography>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <AlertModal
        title="Email enviado"
        body="Enviamos un correo a tu correo registrado."
        type={AlertType.Success}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </Wrapper>
  );
};
