import _ from "lodash";
import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ProfessionalExperience, SchoolGrade } from "@mapsy/shared";

import { InputField } from "components/atoms/InputField";
import COLORS from "constants/colors";
import { ArrayProps } from "features/therapist/therapist.slice";
import { Form } from "interfaces";

interface Props {
  subtitle?: string;
  arrayProp: ArrayProps;
  index: number;
  inputs: Form;
  data: SchoolGrade | ProfessionalExperience | Location;
  onPropertyChanged: (propertyName: string, value: any, context: any) => void;
  separatorBorderAfterInputs?: boolean;
  actions?: {
    onDelete: (arrayProp: ArrayProps, i: number) => void;
    onAdd: () => void;
  };
}

export const NestedInArrayProfileSection: React.FC<Props> = ({
  subtitle,
  arrayProp,
  index,
  inputs,
  data,
  onPropertyChanged,
  separatorBorderAfterInputs,
  actions,
}) => {
  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: 2,
        }}
      >
        {subtitle && <Typography variant="h5">{subtitle}</Typography>}
        {actions && (
          <>
            {index === 0 ? (
              <IconButton
                disableRipple
                aria-label="add"
                size="medium"
                sx={{
                  backgroundColor: COLORS.BLUE_1,
                  color: "white",
                  ":hover": {
                    backgroundColor: COLORS.BLUE_2,
                    color: COLORS.BLUE_1,
                  },
                }}
                onClick={() => actions.onAdd()}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <IconButton
                disableRipple
                aria-label="remove"
                size="medium"
                sx={{
                  backgroundColor: COLORS.BLUE_1,
                  color: "white",
                  ":hover": {
                    backgroundColor: COLORS.BLUE_2,
                    color: COLORS.BLUE_1,
                  },
                }}
                onClick={() => actions.onDelete(arrayProp, index)}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </>
        )}
      </Grid>

      {inputs.map(({ gridSize = { sm: 12 }, propertyName, ...rest }, i) => (
        <Grid
          xs={12}
          id={`row-input-container-${propertyName}-${i}`}
          item
          key={`row-input-container-${propertyName}-${i}`}
          sx={{ px: 1, my: 1.5 }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5">{rest.label}:</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <InputField
                backgroundMode="transparent"
                propertyName={propertyName}
                name={`${arrayProp}.${index}.${propertyName}`}
                value={_.get(
                  data as unknown as Record<string, string | number>,
                  propertyName,
                  ""
                )}
                handleChange={onPropertyChanged}
                {...rest}
                label=""
                helperText=""
              />
            </Grid>
          </Grid>
        </Grid>
      ))}

      {separatorBorderAfterInputs && (
        <Grid
          item
          sx={{
            borderBottom: `solid 3px ${COLORS.BLUE_1}`,
            my: 2,
          }}
          md={6}
          xs={12}
        ></Grid>
      )}
    </>
  );
};
