import {
  ErrorMsg,
  ErrorsObj,
  InputType,
  StylesEnum,
  UserType,
  EndpointGenerator,
  TokenType,
} from "@mapsy/shared";
import { Container, Grid, Typography } from "@mui/material";
import { AlertModal } from "components/atoms/AlertModal";
import { CustomButton } from "components/atoms/Button";
import { InputField } from "components/atoms/InputField";
import COLORS from "constants/colors";
import { AlertType } from "enums/alert.enum";
import { selectSessionState } from "features/session/session.slice";
import { useAuth } from "hooks/useAuthAPI";
import { useAxios } from "hooks/useAxios";
import { useQuery } from "hooks/useQuery";
import { Form, FormInput } from "interfaces";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { areInputsValid } from "utils/areInputsValid";
import { hashLongUserType } from "./SignInAndUp";

interface Props {
  shouldChangePwd?: boolean;
}
export const ResetPassword: React.FC<Props> = ({ shouldChangePwd = false }) => {
  const query = useQuery();
  const email = query.get("email");
  const userType = query.get("userType");
  const tokenType = query.get("tokenType");

  const nav = useNavigate();
  const { token: resetToken } = useParams(); //Token used to reset password from email
  const { errorMsg, isLoading, updatePassword, resetErrors } = useAuth();
  const {
    postData,
    errorMsg: resetPwdErrorMsg,
    isLoading: isResetPwdLoading,
  } = useAxios();
  const { token, profileInfo } = useSelector(selectSessionState);
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [errors, setErrors] = useState<ErrorsObj>();
  const [succededChange, setSuccededChange] = useState(false);

  const handleChange = useCallback(
    (
      propertyName: string,
      value: any,
      c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setPasswords((_passwords) => ({ ..._passwords, [propertyName]: value }));
    },
    []
  );

  const inputs: Form = useMemo(() => {
    const changePwdInputs: Form = [
      {
        propertyName: "currentPassword",
        label: "Contraseña actual",
        placeholder: "Escribe tu contraseña actual",
        inputType: InputType.Text,
        textFieldProps: {
          type: "password",
          size: "small",
        },
        value: passwords.currentPassword,
        validation: {
          isRequired: true,
          minLength: 8,
          maxLength: 16,
        },
      },
      {
        propertyName: "newPassword",
        label: "Contraseña nueva",
        placeholder: "Escribe tu nueva contraseña",
        inputType: InputType.Text,
        value: passwords.newPassword,
        textFieldProps: {
          type: "password",
          size: "small",
        },
        validation: {
          isRequired: true,
          minLength: 8,
          maxLength: 16,
        },
      },
      {
        propertyName: "confirmNewPassword",
        label: "Repetir Contraseña nueva",
        placeholder: "Repite tu nueva contraseña",
        inputType: InputType.Text,
        value: passwords.confirmNewPassword,
        textFieldProps: {
          type: "password",
          size: "small",
        },
        validation: {
          isRequired: true,
          minLength: 8,
          maxLength: 16,
        },
      },
    ];

    if (shouldChangePwd) {
      return changePwdInputs;
    }

    return changePwdInputs.slice(1);
  }, [passwords, shouldChangePwd, handleChange]);

  const handleChangePwd = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setErrors(undefined);
      resetErrors();
      if (
        !profileInfo?.id ||
        !profileInfo.email ||
        profileInfo.type === UserType.None ||
        profileInfo.type === UserType.Admin
      ) {
        return;
      }

      if (!areInputsValid({ inputs, values: passwords, setErrors })) {
        return;
      }

      if (passwords.currentPassword === passwords.newPassword) {
        setErrors((_errors) => ({
          ..._errors,
          newPassword: ErrorMsg.PasswordsMustBeDifferent,
        }));
        return;
      }

      if (passwords.newPassword !== passwords.confirmNewPassword) {
        setErrors((_errors) => ({
          ..._errors,
          newPassword: ErrorMsg.NewPasswordsAreDifferent,
          confirmNewPassword: ErrorMsg.NewPasswordsAreDifferent,
        }));
        return;
      }

      const { id, email, type } = profileInfo;

      const response = await updatePassword({ id, email, ...passwords }, type);
      response && setSuccededChange(true);
    },
    [token, profileInfo, passwords]
  );

  const handleResetPwd = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setErrors(undefined);
      resetErrors();

      if (!areInputsValid({ inputs, values: passwords, setErrors })) {
        return;
      }

      if (passwords.newPassword !== passwords.confirmNewPassword) {
        setErrors((_errors) => ({
          ..._errors,
          newPassword: ErrorMsg.NewPasswordsAreDifferent,
          confirmNewPassword: ErrorMsg.NewPasswordsAreDifferent,
        }));
        return;
      }

      if (
        !email ||
        !UserType[userType] ||
        !resetToken ||
        !TokenType[tokenType]
      ) {
        return;
      }

      const endpoint = EndpointGenerator.TokenAPI.validateToken(
        resetToken,
        email,
        userType as unknown as UserType,
        tokenType
      );
      const response = await postData(endpoint, {
        password: passwords.newPassword,
      });
      response && setSuccededChange(true);
    },
    [email, userType, resetToken, passwords, tokenType]
  );

  return (
    <Container
      sx={{ display: "flex", justifyContent: "center", py: 5 }}
      maxWidth="lg"
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ mb: 1 }} variant="h2">
            {shouldChangePwd ? "Cambiar contraseña" : "Recuperar contraseña"}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            borderBottom: `solid 3px ${COLORS.BLUE_1}`,
            my: 2,
          }}
          md={6}
          xs={12}
        ></Grid>
        <Grid item xs={12}>
          <Typography sx={{ mb: 2 }} variant="h4">
            La contraseña debe tener al menos 8 caracteres
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={{ my: 2 }}>
          <form
            action=""
            method="post"
            onSubmit={(e) =>
              shouldChangePwd ? handleChangePwd(e) : handleResetPwd(e)
            }
          >
            <Grid container gap={4}>
              {inputs.map(({ propertyName, ...rest }: FormInput, i: number) => (
                <Grid item key={`input-${propertyName}-${i}`} xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h4">{rest.label}:</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InputField
                        backgroundMode="transparent"
                        propertyName={propertyName}
                        {...rest}
                        label=""
                        handleChange={handleChange}
                        helperText={errors && errors[propertyName]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              {(errorMsg || resetPwdErrorMsg) && (
                <Grid item xs={12} md={12}>
                  <Typography>{errorMsg || resetPwdErrorMsg}</Typography>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                {succededChange && (
                  <Typography>Contraseña modificada exitosamente</Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  justifyContent: "end",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row-reverse",
                  gap: 3,
                }}
              >
                <CustomButton
                  customStyle={StylesEnum.primary}
                  children={
                    shouldChangePwd
                      ? "Cambiar contraseña"
                      : "Reestablecer contraseña"
                  }
                  disableRipple={true}
                  disabled={succededChange}
                  isLoading={isLoading || isResetPwdLoading}
                  sx={{
                    borderRadius: "14px",
                  }}
                  type="submit"
                />
                {shouldChangePwd && (
                  <CustomButton
                    customStyle={StylesEnum.secondary}
                    children={"Volver"}
                    disableRipple={true}
                    isLoading={isLoading}
                    onClick={() => nav(-1)}
                    sx={{
                      borderRadius: "14px",
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <AlertModal
        title="Actualización exitosa"
        body={`${shouldChangePwd ? "Cambio" : "Reestablecimiento"} de contraseña realizado correctamente.`}
        type={AlertType.Success}
        isOpen={succededChange}
        onClose={() => {
          setSuccededChange(false);
          shouldChangePwd
            ? nav(-1)
            : nav(
                `/users/signin?type=${userType ? hashLongUserType[userType as unknown as keyof typeof hashLongUserType] : ""}`
              );
        }}
      />
    </Container>
  );
};
