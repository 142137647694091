import moment from "moment";
import momentTz from "moment-timezone";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ProvidedServiceTypesSpanish,
  Modality,
  Appointment,
  Location,
  StylesEnum,
  ModalitySpanish,
  formatTimezonesEs,
} from "@mapsy/shared";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import defaultUserPic from "assets/img/user-pic.webp";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { TitledLayout } from "layouts/TitledLayout";
import { useAppSelector } from "hooks";
import { useAxios } from "hooks/useAxios";
import COLORS from "constants/colors";
import { EndpointGenerator } from "@mapsy/shared";
import { CustomButton } from "components/atoms/Button";
import { DATE_LONG_FORMAT } from "constants/defaultUserValues";
import { ProfilePic } from "components/atoms/ProfilePic";
import { usePromotions } from "providers/PromotionsProvider";
import { CustomLink } from "components/atoms/Link";

const Wrapper: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Container maxWidth="md">
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 3,
        mt: { md: "3rem", xs: "1rem" },
      }}
    >
      {children}
    </Box>
  </Container>
);

export const ConfirmedAppointment = () => {
  const nav = useNavigate();
  const { appointmentId } = useParams();
  const { getData, errorMsg, isLoading } = useAxios();
  const { token } = useAppSelector(selectSessionState);
  const [appointment, setAppointment] = useState<Appointment>();
  const { currentClosestPromotion } = usePromotions();

  const fetchAppointmentById = useCallback(
    async (id: string, token: string) => {
      const endpoint = EndpointGenerator.AppointmentAPI.urlById(id);
      const data = await getData(endpoint, setToken(token));
      if (data) {
        setAppointment(data);
      }
    },
    [token]
  );

  useEffect(() => {
    if (!token) {
      return;
    }
    if (!appointmentId) {
      nav("/reason");
      return;
    }
    fetchAppointmentById(appointmentId, token);
  }, [appointmentId, token]);

  const location = useMemo(() => {
    if (!appointment) {
      return;
    }
    const { therapist } = appointment;
    return therapist?.locations?.find(
      ({ _id }) => _id === appointment.locationId
    );
  }, [appointment]);

  const therapist = useMemo(() => {
    if (!appointment) {
      return;
    }
    const { therapist } = appointment;
    return therapist;
  }, [appointment]);

  const linkedPromotion = useMemo(() => {
    if (!currentClosestPromotion || !appointment) {
      return;
    }

    if (
      !appointment.isBlocking &&
      currentClosestPromotion._id === appointment.linkedPromotion?._id
    ) {
      return currentClosestPromotion;
    }
  }, [appointment, currentClosestPromotion]);

  const info: {
    subtitle: string;
    data: (app?: Appointment, location?: Location) => ReactNode;
  }[] = useMemo(
    () => [
      {
        subtitle: "Especialista",
        data(app) {
          if (!app) {
            return "Cita no disponible";
          }
          const { therapist } = app;

          return `${therapist?.firstName} ${therapist?.lastName}`;
        },
      },
      {
        subtitle: "Terapia",
        data(app) {
          if (!app) {
            return "Cita no disponible";
          }
          const { providedService: service } = app;
          if (!service || !service?.serviceType === undefined) {
            return "Servicio no encontrado";
          }

          return ProvidedServiceTypesSpanish[service.serviceType];
        },
      },
      {
        subtitle: "Fecha",
        data(app) {
          if (!app) {
            return "Cita no disponible";
          }
          const { date } = app;
          return moment(date).format(DATE_LONG_FORMAT);
        },
      },
      {
        subtitle: "Hora",
        data(app) {
          if (!app) {
            return "Cita no disponible";
          }
          const { date } = app;
          const formattedTzPatient =
            "(Hora " +
            formatTimezonesEs(app.patient?.timezone || momentTz.tz.guess()) +
            ")";

          return `${moment(date).format("HH:mm")} hrs ${app.patient?.timezone ? formattedTzPatient : ""}`;
        },
      },
      {
        subtitle: "Modalidad y precio",
        data(app, location) {
          if (!app) {
            return "Cita no disponible";
          }
          const { providedService: service } = app;

          if (!service) {
            return "Servicio no encontrado";
          }

          if (!location) {
            return "Consultorio no encontrado";
          }
          return `${ModalitySpanish[location.modality]} - $${service.price} ${service.currency.toLowerCase()}`;
        },
      },
    ],
    []
  );

  if (!appointmentId) {
    return (
      <Wrapper>
        <Typography
          variant="h2"
          sx={{
            color: COLORS.TEXT_RED,
            textAlign: "center",
          }}
        >
          La información de la cita no puede mostrarse.
        </Typography>
      </Wrapper>
    );
  }

  if (isLoading) {
    return (
      <Wrapper>
        <CircularProgress size={64} />
      </Wrapper>
    );
  }

  if (errorMsg) {
    return (
      <Wrapper>
        <Typography
          variant="h2"
          sx={{
            color: COLORS.TEXT_RED,
            textAlign: "center",
          }}
        >
          {errorMsg}
        </Typography>
      </Wrapper>
    );
  }

  if (!appointment) {
    return (
      <Wrapper>
        <Typography
          variant="h2"
          sx={{
            color: COLORS.BLUE_1,
            textAlign: "center",
          }}
        >
          Cita no disponible. Es probable que se haya eliminado.
        </Typography>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TitledLayout
        containerSx={{
          justifyContent: "center",
          maxWidth: "sm",
          mb: 4,
        }}
      >
        <Grid item xs={12}>
          {therapist?.accountStatus && (
            <ProfilePic
              _id={appointment.therapistId}
              accountStatus={therapist.accountStatus}
            />
          )}
        </Grid>
        <Grid item>
          <Typography
            variant="h2"
            sx={{
              color: COLORS.BLUE_1,
              textAlign: "center",
            }}
          >
            ¡Tu cita ha sido agendada!
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              color: "black",
              textAlign: "center",
            }}
          >
            El especialista se pondrá en contacto contigo por correo para
            brindarte mayores detalles de tu cita y del pago.
          </Typography>
        </Grid>
        {info.map(({ subtitle, data }, i) => (
          <Grid item key={`${subtitle}-${i}`} xs={12}>
            <Typography sx={{ fontWeight: 600, color: COLORS.BLUE_1 }}>
              {subtitle}:
              <Typography component="span" sx={{ ml: 1 }}>
                {data(appointment, location)}
              </Typography>
            </Typography>
          </Grid>
        ))}
        {location?.modality !== Modality.Online && (
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 600, color: COLORS.BLUE_1 }}>
              Ubicación:
              <Typography component="span" sx={{ ml: 1 }}>
                {location?.street}, {location?.externalNo},{" "}
                {location?.community},{location?.city}
              </Typography>
            </Typography>
          </Grid>
        )}
        {linkedPromotion && (
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
              }}
            >
              Esta cita aplica para la promoción:{" "}
              <Typography
                component="span"
                variant="body2"
                sx={{
                  fontWeight: 600,
                }}
              >
                {linkedPromotion.name}
              </Typography>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
              }}
            >
              Para más información consulta los{" "}
              <CustomLink
                to={linkedPromotion.promoTermsOfUsePath}
                underline
                target="_blank"
              >
                términos y condiciones de la promoción
              </CustomLink>
              .
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomButton
            customStyle={StylesEnum.primary}
            children={"Aceptar"}
            onClick={() => nav("/")}
            disableRipple={true}
            sx={{
              borderRadius: "14px",
            }}
            type="button"
          />
        </Grid>
      </TitledLayout>
    </Wrapper>
  );
};
