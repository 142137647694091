import { APINames } from "../../enum/shared.enum";
import BaseAPI from "./BaseEndpointAPI";

class ZipCodeAPI extends BaseAPI {
  constructor() {
    super(APINames.ZIP_CODES);
  }

  getCitiesByZipCode(zipCode: string, countryCode: string) {
    return this.addParams({ zipCode, countryCode });
  }
}

const api = new ZipCodeAPI();

export default api;
