import moment, { Moment } from "moment";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import {
  Box,
  LinearProgress,
  Paper,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import COLORS from "constants/colors";
import { capitalizeName } from "utils/capitalize";
import { Action, Currency, Location } from "@mapsy/shared";
import {
  Therapist,
  ProvidedService,
  ProvidedServiceTypes,
  HoursByDayHash,
  SelectedPreconfirmationTime,
} from "@mapsy/shared";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { theme } from "theme";

interface Props {
  location: Location;
  therapist: Therapist;
  hoursByDayHash: HoursByDayHash;
  arrayOfNextDays: Moment[];
  setSelectedPreconfirmationTime: Dispatch<
    SetStateAction<SelectedPreconfirmationTime | undefined>
  >;
  onChangeDays: (operation: "add" | "subtract") => void;
  isLoading: boolean;
}

//@TODO Remove this: This is not ideal, but old therapists has different schemas.
export const defaultService: Partial<ProvidedService> = {
  _id: "000",
  currency: Currency.MXN,
  price: 400,
  serviceType: ProvidedServiceTypes.Individual,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: COLORS.BLUE_1,
    borderColor: COLORS.BLUE_1,
    borderWidth: 2,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: "0.8rem", xs: "0.6rem" },
  },
}));

const DEFAULT_MAX_AVAILABLE_TIMES_BY_DAY = 8;

export const AvailableTimeTable: React.FC<Props> = ({
  location,
  therapist,
  hoursByDayHash,
  arrayOfNextDays,
  setSelectedPreconfirmationTime,
  onChangeDays,
  isLoading,
}) => {
  const { createAnalytic } = useAnalytics();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const maxAvailableTimes = useMemo(() => {
    let max = DEFAULT_MAX_AVAILABLE_TIMES_BY_DAY;
    for (const day in hoursByDayHash) {
      if (Object.prototype.hasOwnProperty.call(hoursByDayHash, day)) {
        const timesByDay = hoursByDayHash[day];

        if (timesByDay.length > max) {
          max = timesByDay.length;
        }
      }
    }
    return max;
  }, [hoursByDayHash]);

  const isTomorrowOnView = useMemo(
    () => arrayOfNextDays?.[0]?.diff(moment(), "hour") < 24,
    [arrayOfNextDays]
  );

  const handleSelectedTime = useCallback(
    (selectedTime: SelectedPreconfirmationTime) => {
      setSelectedPreconfirmationTime(selectedTime);

      const { hour, service } = selectedTime;
      createAnalytic({
        action: Action.CLICK,
        componentName: COMPONENTS.AVAILABLE_TIME_TABLE,
        data: {
          therapistId: therapist._id,
          locationId: location._id,
          hour: hour.valueOf(),
          service,
        },
      });
    },
    [therapist, location, setSelectedPreconfirmationTime, createAnalytic]
  );

  return (
    <>
      <Typography
        variant="caption"
        sx={{ textAlign: "center", mb: { md: 2, xs: 1 } }}
        component="p"
      >
        Para agendar, elige el horario que mejor te acomode.
      </Typography>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ mt: { md: 3, xs: 1.5 }, cursor: "pointer" }}>
          <ArrowBackIosIcon
            sx={{ color: isTomorrowOnView ? COLORS.GREY : COLORS.BLUE_1 }}
            onClick={() => {
              if (isTomorrowOnView) {
                return;
              }
              onChangeDays("subtract");
            }}
          />
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            width: "100%",
          }}
        >
          {isLoading && <LinearProgress />}
          <Table aria-label="simple table" className="available-time-table">
            <TableHead>
              <TableRow>
                {arrayOfNextDays.map((day) => {
                  return (
                    <StyledTableCell
                      key={`table-head-row-${day.valueOf()}`}
                      sx={{
                        padding: { md: 2, xs: 0 },
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography
                          sx={{ fontWeight: 500, mb: { md: 1, xs: 0 } }}
                          variant="body2"
                        >
                          {
                            capitalizeName(
                              day.format("dddd") === "miércoles"
                                ? "x"
                                : day.format("ddd")
                            )[0]
                          }
                        </Typography>
                      </Box>
                      <Typography
                        fontWeight={500}
                        variant="caption"
                        sx={{ whiteSpace: { md: "wrap", xs: "nowrap" } }}
                      >
                        {`${day.format("D")} ${day.format(isMobile ? "MMM" : "MMMM")}`}
                      </Typography>
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: maxAvailableTimes }).map((_, i) => (
                <TableRow key={`table-body-row-${i}`}>
                  {arrayOfNextDays.map((momentDay, j) => {
                    const day = momentDay.valueOf();
                    const currentTime =
                      hoursByDayHash[day as unknown as keyof HoursByDayHash]?.[
                        i
                      ];
                    return (
                      <TableCell
                        key={`table-body-cell-${i}-weekday-${day}-${j}`}
                        onClick={() =>
                          currentTime &&
                          handleSelectedTime({
                            day: moment(day),
                            hour: moment(currentTime),
                            service:
                              location.providedServices[0] || defaultService,
                          })
                        }
                        sx={{ width: { md: "auto", xs: "50px" } }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            height: "100%",
                            borderRadius: "5px",
                            padding: {
                              md: "0.6rem 0.2rem",
                              sm: "0.3rem 0.1rem",
                            },
                          }}
                          className={
                            currentTime ? "time-cell-content" : undefined
                          }
                        >
                          <Typography variant="caption">
                            {currentTime
                              ? moment(currentTime).format("HH:mm")
                              : "-"}
                          </Typography>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: { md: 3, xs: 1 }, cursor: "pointer" }}>
          <ArrowForwardIosIcon
            sx={{ color: COLORS.BLUE_1, fontSize: { md: "24px", xs: "16px" } }}
            onClick={() => onChangeDays("add")}
          />
        </Box>
      </Box>
    </>
  );
};
