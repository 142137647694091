import { Currency, CurrencySpanish } from "@mapsy/shared";
import { Tooltip, Typography, TypographyOwnProps } from "@mui/material";
import React from "react";

interface Props {
  currency: Currency;
  typographyProps?: TypographyOwnProps;
}

export const CurrencyWithTooltip: React.FC<Props> = ({
  currency,
  typographyProps,
}) => {
  return (
    <Tooltip title={CurrencySpanish[currency]} arrow>
      <Typography component="span" variant="caption" {...typographyProps}>
        {currency}
      </Typography>
    </Tooltip>
  );
};
