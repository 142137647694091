import { COLORS, LoadingEnum } from "@mapsy/shared";
import { Box, CircularProgress } from "@mui/material";
import { useTheories } from "hooks/catalogs/useTheories";
import React from "react";

interface Props {
  theory?: string;
  showTitle?: boolean;
}
export const TherapistTheory: React.FC<Props> = ({ theory, showTitle }) => {
  const { theoriesList, theoriesLoading } = useTheories();
  return (
    <>
      {showTitle && (
        <Box component={"span"} sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}>
          Áreas de atención:{" "}
        </Box>
      )}
      {theoriesLoading === LoadingEnum.pending ? (
        <CircularProgress />
      ) : (
        <>
          {theoriesList.find(({ value }) => value === theory)?.label ||
            "Teoría no disponible"}
        </>
      )}
    </>
  );
};
