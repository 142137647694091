import { Box, Container, Grid, Typography } from "@mui/material";

import ale from "assets/img/us/ale.webp";
import lalo from "assets/img/us/lalo.webp";
import eli from "assets/img/us/eli.webp";
import beto from "assets/img/us/beto.webp";
import isma from "assets/img/us/isma.webp";
import adri from "assets/img/us/adri.webp";
import jon from "assets/img/us/jon.webp";
import wen from "assets/img/us/wen.webp";

import logo from "assets/img/logotipo.webp";
import COLORS from "constants/colors";
import { MisionVisionValues } from "components/molecules/MisionVisionValues";

const foundersCards: {
  src: any;
  name: string;
  role: string;
  resume: string;
}[] = [
  {
    src: ale,
    name: "Alejandro Trejo",
    role: "Director Ejecutivo",
    resume:
      "Doctor en psicología por la Universidad Nacional Autónoma de México. Profesional en desarrollo web.",
  },
  {
    src: lalo,
    name: "Eduardo Velazquez",
    role: "Director de Tecnologías de la Información",
    resume:
      "Ingeniero en Software por la Universidad Autónoma de Querétaro. Experto en desarrollo web y DevOps.",
  },
  {
    src: eli,
    name: "Elisa Cruz",
    role: "Directora de operaciones",
    resume:
      "Doctora en psicología por la Universidad Nacional Autónoma de México.",
  },
  {
    src: beto,
    name: "Luis Alberto Serrano",
    role: "Analista de Datos",
    resume:
      "Doctor en psicología por la Universidad Nacional Autónoma de México. Autor y coautor de artículos de difusión de la psicología.",
  },
  {
    src: isma,
    name: "Ismael Barberena",
    role: "Director Digital y de Comunicaciones",
    resume:
      "Licenciado en psicología por la Universidad Nacional Autónoma de México.",
  },
  {
    src: adri,
    name: "Adriana Palma",
    role: "Analista de Control de Calidad",
    resume:
      "Licenciada en psicología por la Universidad Nacional Autónoma de México. Concluyó una maestría en Educación Especial.",
  },
];

const acknowledges: {
  src: any;
  name: string;
}[] = [
  {
    src: wen,
    name: "Wendy Concha",
  },
  {
    src: jon,
    name: "Jonathan Zavala",
  },
];

export const AboutUs = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: { md: 12, xs: 4 }, mb: { md: 8, xs: 3 } }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              borderBottom: `solid 3px ${COLORS.BLUE_1}`,
              my: 2,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              Quiénes somos
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container component={"section"}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} md={8}>
          <Typography sx={{ lineHeight: "30px" }}>
            Como psicólogo, cualquiera ha escuchado la pregunta:
          </Typography>
          <Typography sx={{ fontWeight: 500, lineHeight: "30px" }}>
            “¿Me recomiendas un psicólogo?”
          </Typography>
          <Typography sx={{ my: 1, lineHeight: "30px" }}>
            Desafortunadamente, nuestras listas de recomendaciones son
            limitadas, y muchos de nuestros conocidos ya no pueden recibir más
            usuarios. Pero sabemos que hay más terapeutas de calidad y usuarios
            que necesitan encontrarlos.
          </Typography>
          <Typography sx={{ my: 1, lineHeight: "30px" }}>
            Entonces, ¿cómo conectar personas que buscan servicios terapéuticos
            de calidad con los terapeutas que los proveen? Bajo esa premisa,
            nace{" "}
            <Typography component={"span"} sx={{ fontWeight: 600 }}>
              Mapsy
            </Typography>
            .
          </Typography>
          <Typography sx={{ my: 2, lineHeight: "30px" }}>
            En Mapsy, creemos que es necesario aprovechar las herramientas
            tecnológicas disponibles hoy en día para facilitar este proceso. Por
            ello, hemos creado una aplicación web auxiliada con nuestro propio
            modelo de Inteligencia Artificial, entrenado por terapeutas, para
            ayudarte a encontrar atención psicológica de calidad.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            p: { md: 6, xs: 3 },
            lineHeight: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            component={"img"}
            src={logo}
            alt="Mapsy Logo"
            sx={{
              width: "100%",
              maxWidth: { md: "360px", xs: "120px" },
              objectFit: "contain",
            }}
          />
        </Grid>
      </Grid>
      <MisionVisionValues backgroundStyle="transparent" />
      <Grid
        container
        sx={{ my: { md: 10, xs: 4 }, rowGap: { md: 6, xs: 3 } }}
        component={"section"}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{ color: COLORS.BLUE_1, fontWeight: 600 }}
            variant="h3"
          >
            Equipo fundador
          </Typography>
        </Grid>
        {foundersCards.map(({ name, resume, role, src }, i) => (
          <Grid
            item
            key={`founders-cards-${name}-i`}
            sx={{
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              border: "1px solid rgba(125, 126, 126, 0.1)",
              py: 2,
              px: { md: 4, xs: 2 },
              borderRadius: "20px",
            }}
            xs={12}
          >
            <Grid
              container
              sx={{ flexDirection: i % 2 ? "row" : "row-reverse", rowGap: 2 }}
            >
              <Grid item xs={12} md={9}>
                <Typography
                  sx={{
                    color: COLORS.BLUE_1,
                    fontWeight: 600,
                  }}
                  variant="h3"
                >
                  {name}
                </Typography>
                <Typography variant="h5" sx={{ my: 1, fontWeight: 500 }}>
                  {role}
                </Typography>
                <Typography>{resume}</Typography>
              </Grid>
              <Grid item xs={12} md={3} sx={{ py: 1 }}>
                <Box
                  sx={{
                    margin: "0 auto",
                    width: "200px",
                    height: "200px",
                    backgroundImage: `url(${src})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPositionX: "center",
                    backgroundPositionY: "bottom",
                    backgroundColor: "rgba(92, 180, 194, 0.8)",
                    clipPath:
                      "polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        sx={{ my: { md: 10, xs: 4 }, rowGap: { md: 6, xs: 3 } }}
        component={"section"}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{ color: COLORS.BLUE_1, fontWeight: 600 }}
            variant="h3"
          >
            Agradecimientos especiales a
          </Typography>
        </Grid>
        {acknowledges.map(({ src, name }) => (
          <Grid item xs={12} md={6} key={`acknowledges-${name}`}>
            <Grid
              container
              sx={{
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: 4,
              }}
            >
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  backgroundImage: `url(${src})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPositionX: "center",
                  backgroundPositionY: "bottom",
                  backgroundColor: "rgba(92, 180, 194, 0.8)",
                  clipPath: "circle(50% at 50% 50%);",
                }}
              ></Box>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    color: COLORS.BLUE_1,
                  }}
                >
                  {name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography sx={{ textAlign: "center" }} variant="h5">
            Michelle Muñoz, Carmen Estrada y Ximena Ramírez por sus valiosas
            contribuciones al proyecto.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
