import { APINames, UserType } from "../..";
import BaseAPI from "./BaseEndpointAPI";

class AuthAPI extends BaseAPI {
  constructor() {
    super(APINames.AUTH);
  }

  get login() {
    return `${this.baseURL}/login`;
  }

  get googleLogin() {
    return `${this.baseURL}/google`;
  }

  get googleLoginProfile() {
    return `${this.baseURL}/google-profile`;
  }

  get profile() {
    return `${this.baseURL}/profile`;
  }

  get logout() {
    return `${this.baseURL}/logout`;
  }

  resetPassword(email: string, userType: UserType) {
    return `${this.baseURL}/forgot-password/${email}?userType=${userType}`;
  }

  validationEmail(email: string, userType: UserType){
    return `${this.baseURL}/validation-email/${email}?userType=${userType}`;
  }
}

const authAPI = new AuthAPI();
export default authAPI;
