import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { IconButton, Badge, Popover, Box } from "@mui/material";
import { Notifications } from "@mui/icons-material";

import {
  GetAllPagedResult,
  MapsyNotificationPayload,
  EndpointGenerator,
} from "@mapsy/shared";
import COLORS from "constants/colors";
import { selectSessionState } from "features/session/session.slice";
import { useAppSelector } from "hooks";
import { useAxios } from "hooks/useAxios";
import { useSubscribePublicNotification } from "hooks/useSubscribeNotification";
import { setToken } from "utils/setToken";
import { timeAgo } from "utils/time";

interface Props extends Partial<MapsyNotificationPayload> {
  ago?: string;
  onSeenNotification?: (_id: string) => void;
  emptyState?: boolean;
}

const NotificationWrapper: React.FC<Props> = ({
  _id,
  title,
  body,
  ago,
  seen,
  onSeenNotification,
  emptyState = false,
}) => (
  <Box
    className="notification"
    onMouseEnter={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!seen && _id) {
        onSeenNotification?.(_id);
      }
    }}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!seen && _id) {
        onSeenNotification?.(_id);
      }
    }}
    sx={{
      p: 1.5,
      cursor: "pointer",
      backgroundColor: `rgba(151, 217, 219, ${seen ? 0.5 : 0.7})`,
      color: COLORS.BLUE_1,
      textAlign: "center",
      border: "solid 1px rgba(151, 217, 219, 0.8)",
      ":hover": {
        backgroundColor: COLORS.GREY,
      },
    }}
  >
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: ago ? "5fr 1fr" : "1fr",
        alignContent: "center",
        columnGap: "15px",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          flexDirection: "column",
          flexBasis: "100%",
          display: "flex",
          alignContent: "flex-start",
          alignItems: "flex-start",
          justifyContent: "end",
          textAlign: "left",
          "& .title": {
            fontWeight: 600,
          },
        }}
      >
        {emptyState ? (
          <span className="title">No tienes notificaciones</span>
        ) : (
          <>
            <span className="title">{title || "Sin título"}</span>
            <span className="body">{body || ""}</span>
          </>
        )}
      </Box>
      <Box>
        <span>{ago}</span>
      </Box>
    </Box>
  </Box>
);

export const NotificationsBell: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { notifications, setNotifications } = useSubscribePublicNotification();
  const [formattedNotifications, setFormattedNotifications] = useState<
    Array<MapsyNotificationPayload & { ago: string }>
  >([]);
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const { getData, patchData } = useAxios();

  const formatNotifications = useCallback(
    (notifications: Record<string, MapsyNotificationPayload>) => {
      return Object.values(notifications)
        .sort((a, b) => {
          const dA = moment(a.createdAt);
          const bA = moment(b.createdAt);

          return bA.diff(dA);
        })
        .map((n) => {
          return {
            ago: timeAgo(n.createdAt || moment().utc().toISOString()),
            ...n,
          };
        });
    },
    []
  );

  useEffect(() => {
    const formattedNots = formatNotifications(notifications);
    if (!formattedNots) {
      return;
    }
    setFormattedNotifications(formattedNots);
  }, [notifications]);

  const fetchRatings = useCallback(
    async (signal: AbortSignal, token: string, id: string) => {
      const endpoint = EndpointGenerator.NotificationAPI.getNotificationByUser(
        id,
        1,
        5
      );

      const response: GetAllPagedResult<MapsyNotificationPayload> =
        await getData(endpoint, {
          signal,
          ...setToken(token),
        });

      if (!response?.results?.length) {
        return;
      }

      const { results } = response;

      setNotifications((_prev) => {
        const newNots: Record<string, MapsyNotificationPayload> = {};

        results.forEach(({ _id, ...rest }) => {
          newNots[_id || "unidentified"] = { _id, ...rest };
        });

        return { ..._prev, ...newNots };
      });
    },
    []
  );

  useEffect(() => {
    if (!token || !profileInfo) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;
    fetchRatings(signal, token, profileInfo.id);

    return () => {
      controller.abort();
    };
  }, [token, profileInfo?.id]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSeenNotification = useCallback(
    (id: string) => {
      if (!token || !profileInfo?.id) {
        return;
      }
      if (notifications[id]?.seen) {
        return;
      }

      const endpoint = EndpointGenerator.NotificationAPI.seenById(
        id,
        profileInfo.id
      );

      const response = patchData(endpoint, {}, setToken(token));

      if (!response) {
        return;
      }

      setNotifications((prev) => {
        const not = { ...prev };
        not[id].seen = true;
        return not;
      });
    },
    [notifications, token, profileInfo?.id]
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        sx={{ color: COLORS.PURE_WHITE }}
        size="large"
        disableRipple
        onClick={handleClick}
      >
        <Badge
          badgeContent={formattedNotifications.filter((n) => !n.seen).length}
          color="error"
        >
          <Notifications color="inherit" />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          fontSize: { md: "0.85rem", xs: "0.6rem" },
          ".MuiPopover-paper": {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        {formattedNotifications.length ? (
          formattedNotifications.map(
            (not: MapsyNotificationPayload | any, i) => (
              <NotificationWrapper
                {...not}
                onSeenNotification={handleSeenNotification}
                key={`notification-${i}`}
              />
            )
          )
        ) : (
          <NotificationWrapper emptyState />
        )}
      </Popover>
    </>
  );
};
