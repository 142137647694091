import { createTheme, Shadows } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontSize: 16,
    fontWeightRegular: 400,
  },
});

theme.typography.h1 = {
  fontSize: "2.2rem",
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.6rem",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.8rem",
  },
};

theme.typography.h2 = {
  fontSize: "2rem",
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.2rem",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.6rem",
  },
};

theme.typography.h3 = {
  fontSize: "1.8rem",
  fontWeight: 400,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.3rem",
  },
};

theme.typography.h4 = {
  fontSize: "1.52rem",
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.6rem",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.2rem",
  },
};

theme.typography.h5 = {
  fontSize: "1.4rem",
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.1rem",
  },
};

theme.typography.body1 = {
  fontSize: "1.2rem",
  fontWeight: 400,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
};

theme.typography.body2 = {
  fontSize: "1rem",
  fontWeight: 400,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.8rem",
  },
};

theme.typography.caption = {
  fontSize: "0.8rem",
  fontWeight: 400,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.6rem",
  },
};
