import { FAQItem } from "interfaces";

export const FAQGenericItems: FAQItem[] = [
  {
    question: "¿Qué es Mapsy y cómo funciona?",
    answer: (
      <>
        <p>
          Para pacientes, Mapsy es un sitio web que utiliza Inteligencia
          Artificial para ayudarte a encontrar a tu terapeuta ideal. Mapsy
          recomienda terapeutas que se ajusten a tus necesidades y preferencias
          a partir de tu motivo de búsqueda. Puedes agendar citas directamente
          desde la plataforma.
        </p>
        <p>
          Para terapeutas, Mapsy es un sitio web que te permitirá aumentar el
          alcance de tus servicios psicológicos. Relacionando tus áreas de
          especialidad con los motivos de consulta de los posibles pacientes. Los
          pacientes podrán ver tu disponibilidad a través de las actualización
          de la agenda de tu perfil.
        </p>
      </>
    ),
  },
];

export const FAQPatientItems: FAQItem[] = [
  {
    question: "¿Qué tipo de terapeutas puedo encontrar en Mapsy?",
    answer:
      "En Mapsy contamos con terapeutas especializados en una amplia variedad de áreas como ansiedad, depresión, terapia de pareja, manejo del estrés, entre otras. Todos los terapeutas registrados cuentan con una certificación profesional y están verificados para brindar un servicio de calidad. Podrás encontrar psicólogos que brindan consulta en línea y/o presencial.",
  },
  {
    question: "¿Cuánto cuesta una sesión de terapia en Mapsy?",
    answer:
      "El costo de cada sesión depende del terapeuta que elijas. Los precios se muestran en el perfil de cada terapeuta para que puedas comparar y elegir la mejor opción para ti.",
  },
  {
    question: "¿Cómo puedo pagar las sesiones?",
    answer:
      "La modalidad de pago se acuerda directamente con el terapeuta una vez que te contacte. Recuerda que al agendar una cita, al terapeuta le enviaremos tus datos para que te escriba, si no te escribe, tú también tendrás sus datos para que puedas contactarlo.",
  },
  {
    question: "¿Puedo cambiar o cancelar una cita?",
    answer:
      "Cada terapeuta tiene sus propias políticas de cancelación, por lo que te recomendamos revisar los detalles directamente con tu terapeuta.",
  },
  {
    question: "¿Puedo dejar una reseña del terapeuta después de mi sesión?",
    answer:
      " Sí, después de cada sesión recibirás un correo al cual podrás dar click para dejar una reseña. También puedes ir al menú “Mis citas”, buscar tu cita, y verás un link para agregar tu opinión al terapeuta. Las reseñas son por terapeuta, no por sesión.",
  },
  {
    question: "¿Cómo sé a qué psicólogo elegir?",
    answer:
      "En Mapsy contamos con una amplia variedad de psicólogos que pueden acompañarte en tu proceso terapéutico. Por medio de la búsqueda con Inteligencia Artificial verás un listado de terapeutas expertos en la problemática que tu describas. Además, puedes ordenar los resultados de la búsqueda de acuerdo al precio, modalidad de consulta y el estado (ciudad) en que se ubican los terapeutas.",
  },
];

export const FAQTherapistItems: FAQItem[] = [
  {
    question: "¿Qué necesito para registrarme como terapeuta en Mapsy?",
    answer:
      "Para unirte a Mapsy, debes proporcionar tus credenciales profesionales, como tu título académico y número de cédula, y cumplir con el proceso de verificación de identidad. Esto asegura que todos los terapeutas en Mapsy estén capacitados y verificados.",
  },
  {
    question: "¿Puedo cambiar o cancelar una cita?",
    answer:
      "Sí, puedes cambiar o cancelar tu cita desde tu agenda en Mapsy, dando click en el evento que quieras cancelar. Te sugerimos especificar tus políticas de cancelación con los usuarios.",
  },
  {
    question: "¿Cómo funciona la agenda de Mapsy?",
    answer:
      "A través de la agenda los usuarios pueden ver tus horarios disponibles. Mantén actualizada tu agenda para mantener un contacto efectivo con los pacientes. Desde la agenda puedes añadir eventos y cancelarlos.",
  },
  {
    question: "¿Puedo establecer mis propios horarios y tarifas?",
    answer:
      "Sí, Mapsy te permite fijar tus horarios de disponibilidad y definir tus propias tarifas. Puedes ajustar ambos aspectos en tu perfil para adaptarlos a tus necesidades y preferencias.",
  },
  {
    question: "¿Cómo me pagarán por mis sesiones?",
    answer:
      "En tu perfil podrás establecer los medios de pago y divisa que aceptas. Deberás acordar con tu paciente la forma de pago. Actualmente, estamos trabajando en aceptar pagos a través de la app. Te haremos saber cuando esa funcionalidad esté disponible.",
  },
  {
    question:
      "¿Qué sucede si un paciente necesita cancelar o reprogramar una sesión?",
    answer:
      "Como terapeuta, tú estableces tu política de cancelación. Te sugerimos exponerla con los usuarios al agendar una cita. Si un paciente quiere cancelar su cita deberá contactarte directamente.",
  },
  {
    question: "¿Cómo puedo comunicarme con los pacientes?",
    answer:
      "Recibirás los datos de contacto de los pacientes por correo una vez que agenden alguna sesión.",
  },
];
