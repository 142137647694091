import { COLORS, LoadingEnum } from "@mapsy/shared";
import { Box, CircularProgress } from "@mui/material";
import { selectReasonState } from "features/reason/reasonSice";
import { useAppSelector } from "hooks";
import React from "react";

interface Props {
  topicsId: string[];
  showTitle?: boolean;
}
export const TherapistTopics: React.FC<Props> = ({ topicsId, showTitle }) => {
  const { topicsHash, topicsLoading } = useAppSelector(selectReasonState);

  if (topicsLoading === LoadingEnum.pending) {
    return <CircularProgress sx={{ width: "20px" }} />;
  }

  return (
    <>
      {showTitle && (
        <Box component={"span"} sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}>
          Áreas de atención:{" "}
        </Box>
      )}
      <>
        {topicsId
          .map(
            (id, i) =>
              topicsHash[id]?.longNames.es_name ||
              topicsHash[id]?.name ||
              "No name"
          )
          .join(", ")}
      </>
    </>
  );
};
