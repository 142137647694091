import React, { ReactNode } from "react";
import { COLORS } from "@mapsy/shared";
import { Typography, List, ListItem, Grid, Box } from "@mui/material";
import bullseye from "assets/svg/bullseye.svg";
import bulb from "assets/svg/bulb.svg";
import people from "assets/svg/people_hugging.svg";

const cards: { icon: any; title: string; body: ReactNode }[] = [
  {
    icon: bullseye,
    title: "Misión",
    body: (
      <>
        <Typography sx={{ mb: 1 }} variant="body2">
          Para los terapeutas, ofrecemos herramientas que automatizan las tareas
          diarias en el proceso terapéutico.
        </Typography>
        <Typography variant="body2">
          Para los usuarios, facilitamos el acceso a atención psicológica de
          calidad a través de inteligencia artificial.
        </Typography>
      </>
    ),
  },
  {
    icon: bulb,
    title: "Visión",
    body: (
      <>
        <Typography variant="body2">
          Ser la plataforma líder en el mundo para la conexión personalizada con
          profesionales de la salud mental, coadyuvando significativamente en el
          bienestar psicológico de las personas a través de soluciones
          tecnológicas.
        </Typography>
      </>
    ),
  },
  {
    icon: people,
    title: "Valores",
    body: (
      <List
        sx={{
          pt: 0,
          "& li": {
            listStyle: "disc",
            listStylePosition: "inside !important",
            display: "list-item",
            pb: 0.25,
          },
        }}
      >
        <ListItem sx={{ fontSize: { md: "1rem", xs: "0.8rem" } }}>
          Empatía
        </ListItem>
        <ListItem sx={{ fontSize: { md: "1rem", xs: "0.8rem" } }}>
          Inovación
        </ListItem>
        <ListItem sx={{ fontSize: { md: "1rem", xs: "0.8rem" } }}>
          Confindencialidad
        </ListItem>
        <ListItem sx={{ fontSize: { md: "1rem", xs: "0.8rem" } }}>
          Accesibilidad
        </ListItem>
      </List>
    ),
  },
];

interface Props {
  backgroundStyle: "blue" | "transparent";
}

export const MisionVisionValues: React.FC<Props> = ({ backgroundStyle }) => {
  return (
    <Grid
      container
      sx={{
        mx: { md: 0, xs: 3 },
        my: { md: 6, xs: 3 },
        rowGap: { md: 4, xs: 2 },
        backgroundColor:
          backgroundStyle === "blue" ? COLORS.BLUE_2 : backgroundStyle,
        p: { md: 4, xs: 2 },
        borderRadius: { md: 0, xs: "20px" },
        width: "auto",
      }}
      component={"section"}
    >
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography
          sx={{
            color:
              backgroundStyle === "blue" ? COLORS.PURE_WHITE : COLORS.BLUE_1,
            fontWeight: 500,
          }}
          variant="h4"
        >
          Unidos por un propósito
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: { md: 3, xs: 2 },
          }}
        >
          {cards.map(({ icon, title, body }, i) => (
            <Box
              key={`card-${title}-${i}`}
              sx={{
                background: "white",
                border: "1px solid #DFDFDF",
                borderRadius: "8px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                px: { md: 4, xs: 1 },
                py: { md: 4, xs: 2 },
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 7fr",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={icon} alt={`icon-for-${title}`} />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      color: COLORS.BLUE_1,
                      fontWeight: 500,
                    }}
                    variant="body1"
                  >
                    {title}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: { md: 3, xs: 2 } }}>{body}</Box>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
