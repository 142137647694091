const COMPONENTS = Object.freeze({
  CUSTOM_BUTTOM: "CUSTOM_BUTTOM",
  CUSTOM_LINK: "CUSTOM_LINK",
  NAVBAR: "NAVBAR",
  NEW_APPOINTMENT_MODAL: "NEW_APPOINTMENT_MODAL",
  AVAILABLE_TIME_TABLE: "AVAILABLE_TIME_TABLE",
  LEGAL_DOCUMENT_FACTORY: "LEGAL_DOCUMENT_FACTORY",
  MY_PROFILE_PATIENT: "MY_PROFILE_PATIENT",
  MY_PROFILE_THERAPIST: "MY_PROFILE_THERAPIST",
  APPOINTMENT_CONFIRMATION: "APPOINTMENT_CONFIRMATION",
  MY_CALENDAR: "MY_CALENDAR",
  CONSULTING_REASON: "CONSULTING_REASON",
  SIGN_IN_AND_UP: "SIGN_IN_AND_UP",
  ANALYTICS_VISIT_PROVIDER: "ANALYTICS_VISIT_PROVIDER",
  THERAPIST_CARD: "THERAPIST_CARD",
  CONTACT: "CONTACT",
  MY_APPOINTMENTS_PATIENT: "MY_APPOINTMENTS_PATIENT",
  PROMOTION_BANNER: "PROMOTION_BANNER",
  ACCOUNT_STATUS_SECTION: "ACCOUNT_STATUS_SECTION",
  ERROR_BOUNDARY: "ERROR_BOUNDARY",
  FAQ_BUTTON: "FAQ_BUTTON"
});

export default COMPONENTS;
