import CommentAPI from "./CommentAPI";
import AuthAPI from "./authAPI";
import IAAPI from "./IAAPI";
import TherapistAPI from "./TherapistAPI";
import PatientAPI from "./PatientsAPI";
import TopicAPI from "./TopicAPI";
import CatalogsAPI from "./CatalogsAPI";
import AdminAPI from "./AdminAPI";
import AppointmentAPI from "./AppointmentAPI";
import TokenAPI from "./TokenAPI";
import AnalyticsAPI from "./AnalyticsAPI";
import EmailAPI from "./EmailAPI";
import FileAPI from "./FileAPI";
import NotificationAPI from "./NotificationAPI";
import ZipCodeAPI from "./ZipCodeAPI";
import PromotionsAPI from "./PromotionsAPI";

export const apis = {
  AdminAPI,
  AnalyticsAPI,
  AppointmentAPI,
  AuthAPI,
  CatalogsAPI,
  CommentAPI,
  EmailAPI,
  IAAPI,
  PatientAPI,
  TokenAPI,
  TherapistAPI,
  TopicAPI,
  FileAPI,
  NotificationAPI,
  ZipCodeAPI,
  PromotionsAPI
};
