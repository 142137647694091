import React, { useCallback } from "react";
import Countdown from "components/atoms/Countdown";
import { Action, Promotion, StylesEnum } from "@mapsy/shared";
import { alpha, Box, Typography } from "@mui/material";
import COLORS from "constants/colors";
import { ModalLayout } from "layouts/ModalLayout";
import { CustomButton } from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { CustomLink } from "components/atoms/Link";
import { usePromotions } from "providers/PromotionsProvider";

interface Props {
  promotion: Promotion;
}

const PromotionBanner: React.FC<Props> = ({ promotion }) => {
  const nav = useNavigate();
  const { createAnalytic } = useAnalytics();
  const { showBanner, alreadyStarted, closeBanner } = usePromotions();
  const {
    bannerConfig: { title, text },
    name,
    startDate,
    endDate,
    promoTermsOfUsePath,
  } = promotion;

  const handleClickAllTherapists = useCallback(() => {
    closeBanner();
    nav("/all-therapists");
  }, []);

  const handleClickLookForIA = useCallback(() => {
    closeBanner();
    nav("/reason");
  }, []);

  const handleCloseModal = useCallback(() => {
    closeBanner();
    createAnalytic({
      action: Action.CLOSE,
      componentName: COMPONENTS.PROMOTION_BANNER,
      data: {
        promotion: { title, name, startDate, endDate },
      },
    });
  }, []);

  return (
    <ModalLayout
      isOpen={showBanner}
      onClose={handleCloseModal}
      maxWidthContainer="sm"
    >
      <Box
        sx={{
          my: 1,
          padding: { md: "1rem", xs: "0.8rem" },
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1.5,
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 600, fontStyle: "italic" }}>
          🎉 ¡Promoción! 🎉
        </Typography>
        <Box
          sx={{
            backgroundColor: alpha(COLORS.YELLOW, 0.75),
            textAlign: "center",
            color: COLORS.PURE_WHITE,
            borderRadius: "8px",
            p: { md: 2, xs: 1 },
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {text}
        </Typography>
        {alreadyStarted ? (
          <>
            <Typography>Solo quedan:</Typography>
            <Countdown date={endDate} />
          </>
        ) : (
          <>
            <Typography>Faltan:</Typography>
            <Countdown date={startDate} />
          </>
        )}
        <CustomButton
          customStyle={StylesEnum.primary}
          onClick={handleClickAllTherapists}
          sx={{ mt: 4, mb: 2 }}
        >
          Ver todos los terapeutas
        </CustomButton>

        <CustomButton
          customStyle={StylesEnum.secondary}
          onClick={handleClickLookForIA}
          sx={{ mb: 2 }}
        >
          Buscar por Inteligencia Artificial
        </CustomButton>
        <Box>
          <Typography variant="body2">
            Consulta los{" "}
            <CustomLink to={promoTermsOfUsePath} underline variant="body2">
              términos y condiciones de la promoción
            </CustomLink>
          </Typography>
        </Box>
      </Box>
    </ModalLayout>
  );
};

export default PromotionBanner;
