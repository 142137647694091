import { APINames } from "../../enum/shared.enum";
import BaseAPI from "./BaseEndpointAPI";

class AdminAPI extends BaseAPI {
  constructor() {
    super(APINames.ADMIN);
  }
}

const api = new AdminAPI();

export default api;
