import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "hooks/useQuery";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { TitledLayout } from "layouts/TitledLayout";
import { useAuth } from "hooks/useAuthAPI";
import { EmailValidationParams, TokenType, UserType } from "@mapsy/shared";
import { CustomLink } from "components/atoms/Link";

export const ValidateEmail = () => {
  const query = useQuery();
  const email = query.get("email");
  const userType = query.get("userType");
  const tokenType = query.get("tokenType");
  const { emailValidation, errorMsg } = useAuth();
  const { token } = useParams(); //Token used to validate email
  const [hasSuccedeed, setHasSuccedeed] = useState(false);

  const validateEmail = useCallback(async (params: EmailValidationParams) => {
    const validation = await emailValidation(params);
    if (validation) {
      setHasSuccedeed(true);
    }
  }, []);

  useEffect(() => {
    if (!email || !userType || !token || !TokenType[tokenType]) {
      return;
    }

    validateEmail({ email, token, userType, tokenType });
  }, [email, userType, token, tokenType]);

  if (!email || !UserType[userType] || !token) {
    return (
      <TitledLayout title="¡Parece que la URL está rota!">
        <Grid item md={12}>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            Para validar tu correo, deberás ir la sección de "Mi perfil" y luego
            solicitar una nueva validación. Luego, da click en el correo que
            recibas.
          </Typography>
        </Grid>
      </TitledLayout>
    );
  }

  if (errorMsg) {
    return (
      <TitledLayout title="No pudimos verificar tu correo">
        <Grid
          item
          md={8}
          sx={{ display: "flex", gap: 2, flexDirection: "column" }}
        >
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {errorMsg}
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Si necesitas otro token, lo puedes solicitar en la sección 'Mi
            perfil'.
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Si tienes alguna duda o problema, contacta a soporte al correo{" "}
            <CustomLink to="mailto:support@mapsy.com.mx" underline>
              support@mapsy.com.mx
            </CustomLink>{" "}
            o en la sección de{" "}
            <CustomLink to="/contact" underline target="_blank">
              contacto
            </CustomLink>
          </Typography>
        </Grid>
      </TitledLayout>
    );
  }

  if (hasSuccedeed) {
    return (
      <TitledLayout title="¡Validación exitosa!">
        <Grid item md={12}>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            Tu correo ha sido validado exitosamente.
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Puedes cerrar esta página ahora.
          </Typography>
        </Grid>
      </TitledLayout>
    );
  }

  return (
    <TitledLayout title="Espera a ser redireccionado...">
      <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Estamos validando tu correo...
        </Typography>
      </Grid>
    </TitledLayout>
  );
};
