import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import { Navbar } from "components/organisms/Navbar";
import { Footer } from "components/organisms/Footer";
import PromotionBanner from "components/molecules/PromotionBanner";
import { usePromotions } from "providers/PromotionsProvider";

export const PublicLayout = () => {
  const { currentClosestPromotion } = usePromotions();

  return (
    <Box sx={{ width: "100%", height: { xs: "100%"} }}>
      {currentClosestPromotion && (
        <PromotionBanner promotion={currentClosestPromotion} />
      )}
      <Navbar />
      <Outlet />
      <Footer />
    </Box>
  );
};
