import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import COLORS from "constants/colors";
import { useAxios } from "hooks/useAxios";
import { GetAllPagedResult, OrderEnum } from "@mapsy/shared";
import { TherapistComment, EndpointGenerator } from "@mapsy/shared";
import { Stars } from "components/atoms/Stars";

interface CommentsProps {
  therapistId: string;
}

const PAGE_SIZE = 5; // 5 rating at a time

const Comments: React.FC<CommentsProps> = ({ therapistId }) => {
  const {
    isLoading: loading,
    errorMsg,
    getData,
  } = useAxios({ loadingDefault: true });
  const [comments, setComments] = useState<Record<number, TherapistComment[]>>(
    {}
  );

  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchRatings = useCallback(
    async (signal: AbortSignal, page: number) => {
      const endpoint = EndpointGenerator.CommentAPI.byTherapist(therapistId, {
        page,
        limit: PAGE_SIZE,
        order: OrderEnum.ASC,
        orderBy: "createdAt",
      });

      const response: GetAllPagedResult<TherapistComment> = await getData(
        endpoint,
        {
          signal,
        }
      );

      if (!response?.results?.length) {
        return;
      }

      const { results, totalPages } = response;

      setComments((prevComments) => ({
        ...prevComments,
        [page]: results,
      }));
      setTotalPages(totalPages);
    },
    [page]
  );

  useEffect(() => setError(!!errorMsg), [errorMsg]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetchRatings(signal, page);

    return () => {
      controller.abort();
    };
  }, [page]);

  const title = (
    <Typography variant="subtitle2" sx={{ color: COLORS.BLUE_1 }}>
      Opiniones
    </Typography>
  );

  const SeeMore = () => (
    <Box sx={{ mt: 2 }}>
      {loading ? (
        <CircularProgress sx={{ mt: 2 }} size="2rem" thickness={4} />
      ) : (
        <Typography
          onClick={() => setPage(page + 1)}
          sx={{
            color: COLORS.BLUE_1,
            fontWeight: 600,
            textDecoration: "underline",
          }}
        >
          Ver más opiniones...
        </Typography>
      )}
    </Box>
  );

  if (loading && page === 1) {
    return (
      <Box sx={{ mt: 4 }}>
        {title}
        <CircularProgress sx={{ mt: 2 }} size="2rem" thickness={4} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4 }}>
        {title}
        <Typography variant="body2" sx={{ mt: 2 }}>
          No se pudo encontrar las valoraciones
        </Typography>
      </Box>
    );
  }

  if (!Object.keys(comments).length) {
    return (
      <Box sx={{ mt: 4 }}>
        {title}
        <Typography variant="body2" sx={{ mt: 2 }}>
          Sin valoraciones
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      {title}
      <Box
        sx={{
          height: "280px",
          overflowY: "scroll",
          scrollbarGutter: 0,
          mt: 2,
          width: "50%",
        }}
        className="transparent-scroll"
      >
        {Object.values(comments)
          .flat()
          .map(({ _id, who, comment, score }, idx) => (
            <Paper
              key={_id}
              square={false}
              elevation={3}
              sx={{
                mt: idx === 0 ? 0 : 2,
                p: 2,
                maxHeight: "115px",
                backgroundColor: "transparent",
                border: `1px solid ${COLORS.BLUE_1}`,
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: COLORS.BLUE_1 }}>
                    {who}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Stars stars={score} sxBox={{ justifyContent: "flex-end" }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">{comment}</Typography>
                </Grid>
              </Grid>
            </Paper>
          ))}
        {page < totalPages && <SeeMore />}
      </Box>
    </Box>
  );
};

export default Comments;
