import { useCallback } from "react";
import { useSelector } from "react-redux";

import { useAxios } from "./useAxios";
import { EndpointGenerator } from "@mapsy/shared";
import { selectSessionState } from "features/session/session.slice";
import { CreateAnalytics } from "interfaces/analytics.interface";
import { Navigator } from "interfaces/networkInformation.interface";
import { AxiosRequestConfig } from "axios";

export const useAnalytics = () => {
  const { postData, patchData } = useAxios();
  const { profileInfo, sourceInfo } = useSelector(selectSessionState);

  const createAnalytic = useCallback(
    async (
      { action, componentName, data, user = profileInfo }: CreateAnalytics,
      config?: AxiosRequestConfig
    ): Promise<{ _id: string } | null> => {
      const endpoint = EndpointGenerator.AnalyticsAPI.baseURL;
      const { userAgent, language } = navigator;
      const { connection } = navigator as Navigator;

      const source = {
        ...sourceInfo,
        userAgent,
        language,
        size: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        connection: connection?.effectiveType,
      };

      try {
        const id = await postData(
          endpoint,
          {
            action,
            componentName,
            data,
            source,
            user,
          },
          config
        );

        return id;
      } catch (e: any) {
        return null;
      }
    },
    [profileInfo, sourceInfo]
  );

  const updateAnalytic = useCallback(
    async (
      id: string,
      data: Partial<CreateAnalytics | { [x: string]: any }>
    ): Promise<string | null> => {
      try {
        const endpoint = EndpointGenerator.AnalyticsAPI.urlById(id);
        const response = await patchData(endpoint, data);
        return response;
      } catch (e: any) {
        return null;
      }
    },
    []
  );

  return {
    createAnalytic,
    updateAnalytic,
  };
};
