import { Box, BoxProps } from "@mui/material";
import React from "react";

interface Props extends BoxProps {
  src: string;
  alt: string;
}
export const ExampleTherapistPicture: React.FC<Props> = (props) => {
  return (
    <Box
      component="img"
      sx={{
        width: { md: "162px", xs: "100px" },
        height: { md: "230px", xs: "160px" },
        borderRadius: { md: "60px", xs: "20px" },
        objectFit: "cover",
      }}
      {...props}
    ></Box>
  );
};
