import _ from "lodash";
import { isMoment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, CircularProgress, Typography } from "@mui/material";

import { Action, Therapist, UserType } from "@mapsy/shared";

import { selectSessionState } from "features/session/session.slice";
import { useAuth } from "hooks/useAuthAPI";
import { useAppDispatch } from "hooks";
import {
  addNewLocation,
  ArrayProps,
  changeBasicInfo,
  NestedPropsInArray,
  NestedPropsInObject,
  ObjectProps,
  pushNewProfessionalExperience,
  pushNewSchoolGrade,
  removeIndexFromPropArray,
  removeLocation,
  selectTherapistState,
  setTherapist,
  updateNestedProp,
  updatePropInNestedArray,
  updateTopics,
} from "features/therapist/therapist.slice";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { MyProfileLayout } from "layouts/MyProfileLayout";
import { ProfileSection } from "components/molecules/profile/ProfileSection";
import { NestedInArrayProfileSection } from "components/molecules/profile/NestedProfileSection";
import { Entity, HandleChangeType, HandleSubmitParams } from "interfaces";
import { SubmitOnChangeProvider } from "providers/SubmitOnChangeProvider";
import { InputField } from "components/atoms/InputField";
import { LocationSection } from "components/molecules/profile/LocationSection";
import { useAnalytics } from "hooks/useAnalytics";
import COLORS from "constants/colors";
import COMPONENTS from "constants/componentNames";
import { useValidation } from "providers/FormProvider";
import { CustomLink } from "components/atoms/Link";
import { useTopics } from "hooks/useTopics";

interface Props {
  showLocations?: boolean;
}
export const MyProfileTherapist: React.FC<Props> = ({ showLocations }) => {
  const dispatch = useAppDispatch();
  const { profileInfo } = useSelector(selectSessionState);
  const { therapist } = useSelector(selectTherapistState);
  const { createAnalytic } = useAnalytics();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const { getUserInfo, isLoading, updateUserInfo, errorMsg } = useAuth();
  const {
    Common,
    EducationForm,
    SchoolGradesForm,
    ProfessionalExperienceForm,
    ExperienceForm,
  } = useGetStartedForms();
  const contactInputs = useMemo(
    () =>
      Common.filter(({ propertyName }) => propertyName !== "phone.dial_code"),
    [Common]
  );
  const formationInputs = useMemo(
    () => EducationForm.slice(0, 2),
    [EducationForm]
  );
  const topicsInput = useMemo(() => ExperienceForm.slice(1), [ExperienceForm]);
  const { setShowErrors, formValid, validateEntity } = useValidation();
  const [updatedFields, setUpdatedFields] = useState<Partial<Therapist>>();
  const [updatedProp, setUpdatedProp] = useState<keyof Therapist>();
  useTopics();

  const submitParams: HandleSubmitParams = useMemo(
    () => ({
      values: updatedFields as unknown as Entity,
    }),
    [updatedFields]
  );

  const fetchUserInfo = useCallback(async () => {
    if (!profileInfo?.id) {
      return;
    }

    const data = await getUserInfo(profileInfo.id, profileInfo.type);
    dispatch(setTherapist(data));
  }, [profileInfo]);

  useEffect(() => {
    if (!therapist) {
      return;
    }
    setHasSucceeded(false);
  }, [therapist]);

  useEffect(() => {
    if (!therapist || !updatedProp) {
      return;
    }
    updateOnlyProp(updatedProp);
  }, [therapist, updatedProp]);

  const updateOnlyProp = useCallback(
    (prop: keyof Therapist) => {
      const updatedField = therapist?.[prop];

      if (updatedField === undefined) {
        return;
      }

      setUpdatedFields((_prevUpdatedFields) => ({
        ..._prevUpdatedFields,
        [prop]: updatedField,
      }));
    },
    [therapist]
  );

  const handleSubmit = useCallback(
    async ({ values }: HandleSubmitParams) => {
      if (!profileInfo?.id || !values) {
        return;
      }

      if (!formValid) {
        setShowErrors(true);
        return;
      }

      setIsUpdateLoading(true);
      setHasSucceeded(false);

      const id = await updateUserInfo(
        { _id: profileInfo?.id, fieldsToUpdate: values },
        UserType.Therapist
      );
      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.MY_PROFILE_THERAPIST,
        data: { values },
      });
      if (id) {
        await fetchUserInfo();
        setIsUpdateLoading(false);
        setHasSucceeded(true);
        setUpdatedFields(undefined);
        setUpdatedProp(undefined);
      }
    },
    [profileInfo, formValid]
  );

  const handleChangeBasicInfo = useCallback(
    (propertyName: string, value: any, context: any) => {
      let _propertyName = propertyName;
      if (propertyName === "birthDate" && isMoment(value)) {
        value = value.valueOf();
      }

      if (new RegExp("^phone.*$").test(propertyName)) {
        const props = propertyName.split(".");
        const [prop, nestedProp] = props;
        _propertyName = prop;
        dispatch(
          updateNestedProp({
            propertyName: prop as ObjectProps,
            nestedProp: nestedProp as NestedPropsInObject,
            value,
          })
        );
      } else {
        dispatch(
          changeBasicInfo({
            propertyName: propertyName as keyof Therapist,
            value,
          })
        );
      }

      setUpdatedProp(_propertyName as keyof Therapist);
    },
    []
  );

  const handleChangeNestedInArrayInfo = useCallback(
    (
      propertyName: ArrayProps,
      index: number,
      nestedProp: NestedPropsInArray,
      value: any
    ) => {
      dispatch(
        updatePropInNestedArray({
          propertyName,
          index,
          nestedProp,
          value,
        })
      );

      setUpdatedProp(propertyName as keyof Therapist);
    },
    []
  );

  const handleChangeTopics: HandleChangeType = useCallback(
    (_, options: string[]) => {
      dispatch(updateTopics({ topicsId: options }));

      setUpdatedProp("topicsId");
    },
    []
  );

  const handleDeleteIndexOfArray = useCallback(
    (propertyName: ArrayProps, index: number) => {
      dispatch(removeIndexFromPropArray({ propertyName, index }));

      setUpdatedProp(propertyName as keyof Therapist);
    },
    []
  );

  if (isLoading && !therapist) {
    return (
      <MyProfileLayout>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Grid>
      </MyProfileLayout>
    );
  }

  if (!therapist) {
    return (
      <MyProfileLayout>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography>Datos de terapeuta no disponibles.</Typography>
        </Grid>
      </MyProfileLayout>
    );
  }

  if (showLocations) {
    return (
      <SubmitOnChangeProvider
        stateToObserve={updatedFields}
        helperToSubmitOnChange={handleSubmit}
        submitParams={submitParams}
      >
        <MyProfileLayout
          isForm
          isBackButtonLoading={isUpdateLoading}
          hasSucceeded={hasSucceeded}
          setHasSucceeded={setHasSucceeded}
          errorMsg={errorMsg}
        >
          {therapist.locations.length ? (
            therapist.locations.map((location, i) => (
              <LocationSection
                key={`location-section-${i}`}
                index={i}
                location={location}
                locationForm={{
                  onBasicPropertyChange: (pName, value) =>
                    handleChangeNestedInArrayInfo(
                      "locations",
                      i,
                      pName as NestedPropsInArray,
                      value
                    ),
                }}
                onAddLocation={() => {
                  dispatch(addNewLocation());
                  setUpdatedProp("locations");
                }}
                onRemoveLocation={(index) => {
                  dispatch(removeLocation({ index }));
                  setUpdatedProp("locations");
                }}
              />
            ))
          ) : (
            <>
              <Grid
                item
                sx={{
                  py: 1,
                  borderBottom: `solid 3px ${COLORS.BLUE_1}`,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mb: 1,
                }}
                xs={12}
                md={10}
              >
                <Typography variant="h4">
                  Tus consultorios aparecerán aquí
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  py: 1,
                }}
                xs={12}
              >
                <Typography sx={{ mb: 1 }}>
                  Aún no consultorios añadidos. Para añadirlos,{" "}
                  <CustomLink to="/get-started">completa tu perfil</CustomLink>
                </Typography>
              </Grid>
            </>
          )}
        </MyProfileLayout>
      </SubmitOnChangeProvider>
    );
  }

  return (
    <SubmitOnChangeProvider
      stateToObserve={updatedFields}
      helperToSubmitOnChange={handleSubmit}
      submitParams={submitParams}
    >
      <MyProfileLayout
        isForm
        isBackButtonLoading={isUpdateLoading}
        hasSucceeded={hasSucceeded}
        setHasSucceeded={setHasSucceeded}
        errorMsg={errorMsg}
      >
        <ProfileSection
          title="Mi perfil"
          inputs={contactInputs}
          userData={therapist as Therapist}
          onPropertyChanged={handleChangeBasicInfo}
          includeProfilePic
          includePasswordChange
          includeAccountStatus
          userType={UserType.Therapist}
          separatorBorderAfterInputs
        />

        <ProfileSection
          inputs={formationInputs}
          userData={therapist as Therapist}
          userType={UserType.Therapist}
          onPropertyChanged={handleChangeBasicInfo}
          separatorBorderAfterInputs
        />

        <Grid
          item
          sx={{
            py: 1,
          }}
          xs={12}
        >
          <Typography variant="h4" sx={{ mb: 1, fontWeight: 500 }}>
            Grados académicos
          </Typography>
        </Grid>

        {therapist.schoolGrades.length ? (
          therapist.schoolGrades.map((schoolGrade, i) => (
            <NestedInArrayProfileSection
              key={`nested-schoolgrade-${i}`}
              index={i}
              arrayProp="schoolGrades"
              subtitle={`Grado académico ${i + 1}`}
              inputs={SchoolGradesForm}
              data={schoolGrade}
              onPropertyChanged={(pName, value, _) =>
                handleChangeNestedInArrayInfo(
                  "schoolGrades",
                  i,
                  pName as NestedPropsInArray,
                  value
                )
              }
              actions={{
                onDelete: handleDeleteIndexOfArray,
                onAdd: () => {
                  dispatch(pushNewSchoolGrade());
                  setUpdatedProp("schoolGrades");
                },
              }}
              separatorBorderAfterInputs
            />
          ))
        ) : (
          <Grid
            item
            sx={{
              py: 1,
            }}
            xs={12}
          >
            <Typography sx={{ mb: 1 }}>
              Aún no tienes grados académicos añadidos. Para añadirlos,{" "}
              <CustomLink to="/get-started" underline>
                completa tu perfil
              </CustomLink>
            </Typography>
          </Grid>
        )}

        <Grid
          item
          sx={{
            py: 1,
          }}
          xs={12}
        >
          <Typography variant="h4" sx={{ mb: 1, fontWeight: 500 }}>
            Experiencia Profesional
          </Typography>
        </Grid>

        {therapist.professionalExperience.length ? (
          therapist.professionalExperience.map((experience, i) => (
            <NestedInArrayProfileSection
              key={`nested-professionalExperience-${i}`}
              index={i}
              arrayProp="professionalExperience"
              subtitle={`Experiencia profesional ${i + 1}`}
              inputs={ProfessionalExperienceForm}
              data={experience}
              onPropertyChanged={(pName, value, _) =>
                handleChangeNestedInArrayInfo(
                  "professionalExperience",
                  i,
                  pName as NestedPropsInArray,
                  value
                )
              }
              actions={{
                onDelete: handleDeleteIndexOfArray,
                onAdd: () => {
                  dispatch(pushNewProfessionalExperience());
                  setUpdatedProp("professionalExperience");
                },
              }}
              separatorBorderAfterInputs
            />
          ))
        ) : (
          <Grid
            item
            sx={{
              py: 1,
            }}
            xs={12}
          >
            <Typography sx={{ mb: 1 }}>
              Aún no tienes experiencia profesional añadida. Para añadirla,{" "}
              <CustomLink to="/get-started" underline>
                completa tu perfil
              </CustomLink>
            </Typography>
          </Grid>
        )}

        <Grid
          item
          sx={{
            py: 1,
          }}
          xs={12}
        >
          <Typography variant="h4" sx={{ mb: 1, fontWeight: 500 }}>
            Áreas de atención
          </Typography>
          <Typography variant="caption" sx={{ mb: 1 }}>
            Recuerda que solo puedes elegir hasta 5 áreas de atención
          </Typography>
        </Grid>

        {topicsInput.map(
          ({ gridSize = { sm: 12 }, propertyName, ...rest }, i) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              {...gridSize}
              key={`grid-item-topics-${i}`}
            >
              <InputField
                backgroundMode="transparent"
                propertyName={propertyName}
                value={therapist.topicsId}
                handleChange={handleChangeTopics}
                {...rest}
                label=""
              />
            </Grid>
          )
        )}
      </MyProfileLayout>
    </SubmitOnChangeProvider>
  );
};
