import { APINames } from "../../enum/shared.enum";
import BaseAPI from "./BaseEndpointAPI";

class TopicAPI extends BaseAPI {
  constructor() {
    super(APINames.TOPIC);
  }
}

const topicAPI = new TopicAPI();
export default topicAPI;
