import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  Link,
  NavigateOptions,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import google from "assets/svg/google.svg";
import COLORS from "constants/colors";

import { CustomButton } from "components/atoms/Button";
import { InputField } from "components/atoms/InputField";
import { SelectUserType } from "components/molecules/SelectUserType";
import {
  selectSessionState,
  startSession,
} from "features/session/session.slice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useAuth } from "hooks/useAuthAPI";
import { useAxios } from "hooks/useAxios";
import { useQuery } from "hooks/useQuery";
import {
  UserType,
  InputType,
  StylesEnum,
  GoogleRedirectionAction,
  RegistrationAction,
  Action,
  ErrorMsg,
  EndpointGenerator,
} from "@mapsy/shared";
import { Form, FormInput } from "interfaces";
import { useAnalytics } from "hooks/useAnalytics";
import { defaultPhone } from "constants/defaultUserValues";
import COMPONENTS from "constants/componentNames";
import { ValidationProvider } from "providers/FormProvider";
import { CustomLink } from "components/atoms/Link";
import { env } from "env";

interface Props {
  isSignUp?: boolean;
}

export const GOOGLE_REDIRECTION_ACTION = "users-google-redirect";
const SHOW_GOOGLE_BUTTON =
  typeof env.REACT_APP_SHOW_GOOGLE_BUTTON === "boolean"
    ? env.REACT_APP_SHOW_GOOGLE_BUTTON === true
    : env.REACT_APP_SHOW_GOOGLE_BUTTON === "true";

export const hashUserType: { [key: string]: UserType } = {
  therapist: UserType.Therapist,
  patient: UserType.Patient,
};

export const hashLongUserType: Record<UserType, string> = {
  [UserType.Patient]: "patient",
  [UserType.Therapist]: "therapist",
  [UserType.Admin]: "admin",
  [UserType.None]: "none",
};

export const homePageByUserType: Record<UserType, string> = {
  [UserType.Patient]: "/reason",
  [UserType.Admin]: "/",
  [UserType.Therapist]: "/therapist/my_calendar",
  [UserType.None]: "/",
};

const defaultValues = {
  email: "",
  password: "",
};

export const SignInAndUp: React.FC<Props> = ({ isSignUp }) => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { state } = useLocation();
  const { createAnalytic } = useAnalytics();
  const { isLoggedIn, profileInfo } = useAppSelector(selectSessionState);
  const [userType, setUserType] = useState<UserType | null>(null);
  const [userTypeModal, setSelectUserType] = useState(true);
  const [buttonTitle, setButtonTitle] = useState("Iniciar Sesión");
  const [agree, setAgree] = useState(false);
  const {
    isLoading: submitLoading,
    errorMsg: registrationErrorMsg,
    postData: createAccountPOST,
  } = useAxios({
    loadingDefault: false,
  });
  const { postLogin, errorMsg, isLoading, openGoogleEndpoint } = useAuth();
  const configHash = useMemo(() => {
    const redirectTo = query.getRedirectToWithParams();

    return {
      signup: {
        title: "Regístrate",
        alternativeLinkLabel: "Iniciar Sesión",
        alternativeLinkTo: `/users/signin${redirectTo ? `?redirectTo=${redirectTo}` : ""}`,
      },
      signin: {
        title: "Iniciar Sesión",
        alternativeLinkLabel: "Regístrate",
        alternativeLinkTo: `/users/signup${redirectTo ? `?redirectTo=${redirectTo}` : ""}`,
      },
    }[isSignUp ? "signup" : "signin"];
  }, [isSignUp, query]);
  const [values, setValues] = useState<{ email: string; password: string }>(
    defaultValues
  );

  useEffect(() => {
    if (isLoggedIn && profileInfo?.type && UserType[profileInfo.type]) {
      const redirectTo = query.getRedirectToWithParams();
      const navPath = redirectTo
        ? redirectTo
        : homePageByUserType[profileInfo.type];

      nav(navPath);
    }
  }, [isLoggedIn, profileInfo]);

  useEffect(() => {
    if (userType !== null) {
      setSelectUserType(false);
      return;
    }

    if (!query.has("type")) {
      setSelectUserType(true);
      return;
    }

    const type = query.get("type");

    if (!type || !hashUserType[type]) {
      setSelectUserType(true);
      return;
    }

    setUserType(hashUserType[type]);
  }, [query, userType]);

  useEffect(() => {
    setButtonTitle(isSignUp ? "Crear Cuenta" : "Iniciar Sesión");
    setAgree(isSignUp ? false : true);
  }, [isSignUp]);

  useEffect(() => {
    if (!userType) {
      return;
    }

    const googleRedirectionAction: GoogleRedirectionAction = {
      userType,
      action: isSignUp ? RegistrationAction.SignUp : RegistrationAction.SignIn,
    };

    localStorage.setItem(
      GOOGLE_REDIRECTION_ACTION,
      JSON.stringify(googleRedirectionAction)
    );
  }, [userType, isSignUp]);

  const createAccount = useCallback(
    async (credentials: { email: string; password: string }) => {
      if (!userType) {
        return;
      }

      const endpoint =
        userType === UserType.Therapist
          ? EndpointGenerator.TherapistAPI.baseURL
          : EndpointGenerator.PatientAPI.baseURL;

      const response = await createAccountPOST(
        endpoint,
        {
          ...credentials,
          phone: defaultPhone,
        },
        undefined,
        { 409: ErrorMsg.TryAnotherEmail }
      );

      return response?._id;
    },
    [userType]
  );

  const login = useCallback(
    async (credentials: { email: string; password: string }, path: string) => {
      if (!userType) {
        return;
      }

      const response = await postLogin({
        ...credentials,
        type: userType,
      });

      if (response?.access_token) {
        dispatch(startSession(response));
        nav(path);
        return true;
      }
    },
    [userType]
  );

  const handleChangeMode = useCallback(
    (link: string) => {
      createAnalytic({
        action: Action.CLICK,
        componentName: COMPONENTS.SIGN_IN_AND_UP,
        data: { isSignUp, state },
      });
      nav(link, { state });
    },
    [state, isSignUp]
  );

  const handleChange = useCallback(
    (
      propertyName: string,
      value: any,
      c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValues({ ...values, [propertyName]: value });
    },
    [values]
  );

  const inputs: Form = useMemo(
    () => [
      {
        propertyName: "email",
        label: "",
        placeholder: "Escribe tu correo electrónico",
        inputType: InputType.Text,
        value: values.email,
        handleChange,
        textFieldProps: {
          size: "small",
        },
        gridSize: {
          md: 12,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 40,
        },
      },
      {
        propertyName: "password",
        label: "",
        placeholder: "Escribe tu contraseña",
        inputType: InputType.Text,
        value: values.password,
        textFieldProps: {
          type: "password",
          size: "small",
        },
        handleChange,
        gridSize: {
          md: 12,
          sm: 12,
          xs: 12,
        },
        validation: {
          isRequired: true,
          minLength: 8,
          maxLength: 16,
        },
      },
    ],
    [values, handleChange]
  );

  const handleSubmit = useCallback(async () => {
    if (!userType) {
      setSelectUserType(true);
      return;
    }

    const redirectTo = query.getRedirectToWithParams();

    if (isSignUp) {
      const acctId = await createAccount(values);

      if (!acctId) {
        return;
      }

      localStorage.removeItem(GOOGLE_REDIRECTION_ACTION);
      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.SIGN_IN_AND_UP,
        data: { userType, isSignUp, acctId },
      });
      const navPath = redirectTo
        ? `/get-started?redirectTo=${redirectTo}`
        : `/get-started`;

      login(values, navPath);
      return;
    }

    const navPath = redirectTo ? redirectTo : homePageByUserType[userType];

    localStorage.removeItem(GOOGLE_REDIRECTION_ACTION);

    createAnalytic({
      action: Action.SUBMIT,
      componentName: COMPONENTS.SIGN_IN_AND_UP,
      data: { userType, isSignUp: false, state, navPath },
    });

    return login(values, navPath);
  }, [userType, isSignUp, state, query, values, login]);

  if (userTypeModal) {
    return <SelectUserType setUserType={setUserType} isWelcome />;
  }

  return (
    <>
      <Container
        maxWidth="xs"
        sx={{
          backgroundColor: COLORS.BLUE_1,
          color: COLORS.PURE_WHITE,
          padding: { md: "2rem 1.8rem", xs: "1.5rem 1rem" },
          borderRadius: "20px",
          width: {
            md: "450px",
            xs: "100%",
          },
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box sx={{ mb: { md: 6, xs: 3 } }}>
          <ValidationProvider handleSubmit={handleSubmit}>
            <Box sx={{ textAlign: "center", mb: 4 }}>
              <Typography variant="h3">{configHash.title}</Typography>
            </Box>
            <Grid container gap={4} direction={"column"}>
              {inputs.map(({ gridSize, ...rest }: FormInput, i: number) => (
                <Grid
                  {...gridSize}
                  item
                  key={`input-${rest.propertyName}-${i}`}
                >
                  <InputField backgroundMode="white" {...rest} />
                </Grid>
              ))}
              {isSignUp && (
                <Grid xs={12} item>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          "& > a.legal-link": {
                            color: COLORS.BLUE_MAPSY,
                          },
                        }}
                        component="span"
                        variant="body2"
                      >
                        Acepto{" "}
                        <CustomLink
                          className="legal-link"
                          to="/terms-and-conditions"
                          target="_blank"
                          underline
                        >
                          términos y condiciones
                        </CustomLink>{" "}
                        y el{" "}
                        <CustomLink
                          className="legal-link"
                          to="/privacy-policy"
                          target="_blank"
                          underline
                        >
                          aviso de privacidad
                        </CustomLink>
                      </Typography>
                    }
                  />
                </Grid>
              )}
              <Grid
                item
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  customStyle={StylesEnum.primary}
                  children={buttonTitle}
                  disableRipple={true}
                  isLoading={isLoading || submitLoading}
                  disabled={!agree || !values.email || !values.password}
                  sx={{
                    padding: "10px 12px",
                    borderRadius: "10px",
                  }}
                  type="submit"
                />
              </Grid>
              {!isSignUp && (
                <Grid item xs={12} md={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    <CustomLink
                      to={`/users/forgot-password?type=${userType ? hashLongUserType[userType as keyof typeof hashLongUserType] : "none"}`}
                      style={{ color: COLORS.WHITE }}
                      underline
                    >
                      Olvidé mi contraseña
                    </CustomLink>
                  </Typography>
                </Grid>
              )}
              {(errorMsg || registrationErrorMsg) && (
                <Typography sx={{ color: COLORS.PURE_WHITE }} variant="body2">
                  {errorMsg || registrationErrorMsg}
                </Typography>
              )}
              <Grid item sm={12} md={12} lg={12}>
                <Divider
                  variant="middle"
                  sx={{
                    "&::before, &::after": {
                      borderColor: "white",
                    },
                  }}
                >
                  o
                </Divider>
              </Grid>
              {SHOW_GOOGLE_BUTTON && (
                <Grid
                  item
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton
                    customStyle={StylesEnum.secondary}
                    disableRipple={true}
                    disabled={!agree}
                    onClick={() => openGoogleEndpoint()}
                  >
                    <img src={google} alt="google-icon" width={27} />
                    {configHash.title} con Google
                  </CustomButton>
                </Grid>
              )}
              <Grid item md={12} lg={12}>
                <Typography
                  component="p"
                  variant="caption"
                  sx={{
                    textAlign: "center",
                    color: COLORS.WHITE,
                    cursor: "pointer",
                    textDecoration: "underline",
                    userSelect: "none",
                  }}
                  onClick={() => {
                    setUserType(null);
                    setValues(defaultValues);
                    nav(isSignUp ? "/users/signup" : "/users/signin");
                  }}
                >
                  Cambiar tipo de usuario
                </Typography>
              </Grid>
            </Grid>
          </ValidationProvider>
        </Box>
      </Container>
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "center", mt: 2 }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: 600,
            color: COLORS.BLUE_1,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleChangeMode(configHash.alternativeLinkTo)}
        >
          {configHash.alternativeLinkLabel}
        </Typography>
      </Box>
    </>
  );
};
