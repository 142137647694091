import _ from "lodash";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { Entity, GetStartedProps } from "interfaces";
import { UserForm } from "../form/UserForm";
import { defaultLocation } from "constants/defaultUserValues";
import { useGetStateAndCommunitiesFromZipCode } from "hooks/useGetStateAndCommunitiesFromZipCode";
import { useValidation } from "providers/FormProvider";
import { Currency } from "@mapsy/shared";

export const Location: React.FC<GetStartedProps> = ({
  userInfo: { locations },
  changePage,
  showBackButton,
}) => {
  const { LocationForm } = useGetStartedForms();
  const [locationInfo, setLocationInfo] = useState<Entity>(
    locations?.length ? locations[0] : defaultLocation(1)
  );
  const { state, communitiesMenuItems, isLoading, zipCodeRegex } =
    useGetStateAndCommunitiesFromZipCode(
      locationInfo.country as string,
      locationInfo.cp as string,
      locationInfo.community as string,
      locationInfo.state as string
    );
  const inputs = useMemo(
    () =>
      LocationForm(
        communitiesMenuItems,
        zipCodeRegex,
        locationInfo.country as string
      ),
    [communitiesMenuItems, zipCodeRegex, locationInfo.country]
  );
  const { validate } = useValidation();

  const propertyChanged = useCallback(
    (
      propertyName: string,
      value: any,
      c: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null
    ) => {
      setLocationInfo((prevLocationInfo: Entity) => ({
        ...prevLocationInfo,
        [propertyName]: value,
      }));
    },
    []
  );

  useEffect(() => {
    if (!state) {
      return;
    }
    setLocationInfo((prevLocationInfo: Entity) => ({
      ...prevLocationInfo,
      state,
    }));
  }, [state]);

  useEffect(() => {
    //Validate state field as it can't be modified by user
    const stateValidation = inputs[3].validation;
    if (locationInfo.state && stateValidation) {
      validate(
        { state: locationInfo.state },
        "state",
        "state",
        stateValidation
      );
    }
  }, [locationInfo.state, inputs]);

  useEffect(() => {
    if (
      locationInfo.country === "Mexico" &&
      locationInfo.providedServices &&
      Array.isArray(locationInfo.providedServices) &&
      locationInfo.providedServices.some(
        ({ currency }) => currency !== Currency.MXN
      )
    ) {
      const newServices = locationInfo.providedServices.map((srv) => ({
        ...srv,
        currency: Currency.MXN,
      }));
      propertyChanged("providedServices", newServices, null);
    }
  }, [locationInfo.country]);

  return (
    <UserForm
      handleSubmit={() =>
        changePage({ locations: [_.omit(locationInfo, "_id")] }, false)
      }
      handleBack={() =>
        changePage({ locations: [_.omit(locationInfo, "_id")] }, true)
      }
      inputs={inputs}
      entity={locationInfo}
      isLoading={isLoading}
      onPropertyChanged={propertyChanged}
      showBackButton={showBackButton}
    />
  );
};
