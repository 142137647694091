import { Container, Grid, Typography } from "@mui/material";
import { CustomLink } from "components/atoms/Link";
import COLORS from "constants/colors";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const nav = useNavigate();
  return (
    <Container
      sx={{ display: "flex", justifyContent: "center", py: 5 }}
      maxWidth="lg"
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ mb: 1 }} variant="h2">
            No se encontró la página que buscabas
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            borderBottom: `solid 3px ${COLORS.BLUE_1}`,
            my: 2,
          }}
          md={6}
          xs={12}
        ></Grid>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            <CustomLink to="/" underline>
              Volver al inicio
            </CustomLink>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
