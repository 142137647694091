import { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { CustomButton } from "components/atoms/Button";
import { LayoutWithLogo } from "layouts/LayoutWithLogo";
import { TitledLayout } from "layouts/TitledLayout";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";

import { Action, StylesEnum } from "@mapsy/shared";
import { CustomLink } from "components/atoms/Link";

export const ErrorBoundary = () => {
  const { createAnalytic } = useAnalytics();
  const { pathname } = useLocation();
  const nav = useNavigate();
  const [errorAnalyticId, setErrorAnalyticId] = useState<string>();
  const error: any = useRouteError();

  const handleError = useCallback(async (error: Error) => {
    const analytic = await createAnalytic({
      action: Action.ERROR,
      componentName: COMPONENTS.ERROR_BOUNDARY,
      data: {
        ...error,
        name: error.name,
        msg: error.message,
        stack: error.stack,
        cause: error.cause,
        pathname
      },
    });
    if (analytic) {
      setErrorAnalyticId(analytic._id);
    }
  }, [pathname]);

  const goHome = useCallback(() => {
    createAnalytic({
      action: Action.CLICK,
      componentName: COMPONENTS.ERROR_BOUNDARY,
    });
    nav("/");
  }, []);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return (
    <LayoutWithLogo>
      <TitledLayout
        title="¡Lo sentimos! Encontramos un error inesperado"
        containerSx={{ justifyContent: "center" }}
      >
        {errorAnalyticId && (
          <Grid
            item
            xs={12}
            sx={{
              textAlign: "center",
              display: "flex",
              gap: 2,
              flexDirection: "column",
            }}
          >
            <Typography>
              Reporta el error a soporte técnico con el siguiente Id:
            </Typography>
            <Typography sx={{ fontWeight: 500 }}>{errorAnalyticId}</Typography>
            <Typography>
              al correo{" "}
              <CustomLink
                to={`mailto:support@mapsy.com.mx?subject=Tengo este error en mapsy: ${errorAnalyticId}&body=Hola, mi nombre es `}
                target="_blank"
                underline
              >
                support@mapsy.com.mx
              </CustomLink>{" "}
              o en la sección de{" "}
              <CustomLink to="/contact" underline target="_blank">
                contacto
              </CustomLink>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <CustomButton onClick={goHome} customStyle={StylesEnum.primary}>
            Ir al inicio
          </CustomButton>
        </Grid>
      </TitledLayout>
    </LayoutWithLogo>
  );
};
