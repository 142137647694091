import moment from "moment";
import { Therapist, WeekDaysEnum, WorkSchedule } from "@mapsy/shared";

export const getUnavailableLocationsLength = (
  numberOfWeekday: number,
  hour: number,
  therapist?: Therapist,
  memo: Record<string, number> = {},
) => {
  if (!therapist?.locations) { //prevent bug validating therapist.locations is not undefined (i.e. incomplete profile)
    return;
  }

  const weekday = WeekDaysEnum[numberOfWeekday];

  const length = therapist?.locations.filter(
    (loc) =>
      hour <
        moment(loc.workSchedule[weekday as keyof WorkSchedule].startTime).get(
          "h"
        ) ||
      hour >
        moment(loc.workSchedule[weekday as keyof WorkSchedule].endTime).get("h")
  ).length;

  if (memo[`${numberOfWeekday}-${hour}`] === undefined) {
    memo[`${numberOfWeekday}-${hour}`] = length;
  }

  return length;
};
