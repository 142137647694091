import _ from "lodash";
import { useRef, useCallback, useEffect, useMemo } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { Currency, Location } from "@mapsy/shared";
import COLORS from "constants/colors";
import { InputField } from "components/atoms/InputField";
import { useGetStartedForms } from "hooks/useGetStartedForms";
import { useGetStateAndCommunitiesFromZipCode } from "hooks/useGetStateAndCommunitiesFromZipCode";
import { Form } from "interfaces";

interface Props {
  index: number;
  location: Location;
  locationForm: {
    onBasicPropertyChange: (
      propertyName: string,
      value: any,
      context: any
    ) => void;
  };
  onAddLocation: () => void;
  onRemoveLocation: (locationIx: number) => void;
}
export const LocationSection: React.FC<Props> = ({
  location,
  index,
  locationForm,
  onAddLocation,
  onRemoveLocation,
}) => {
  const { onBasicPropertyChange } = locationForm;
  const { state, communitiesMenuItems, isLoading, zipCodeRegex } =
    useGetStateAndCommunitiesFromZipCode(
      location.country,
      location.cp,
      location.community,
      location.state
    );
  const { LocationForm } = useGetStartedForms();
  const inputs = useMemo(
    () => LocationForm(communitiesMenuItems, zipCodeRegex, location.country),
    [communitiesMenuItems, zipCodeRegex, location.country]
  );
  const inputsForValidation = useMemo<Form>(() => [], []);

  const gridRef = useRef<HTMLDivElement | null>(null);

  const executeScroll = useCallback(
    () =>
      gridRef.current &&
      window.scrollTo({
        behavior: "smooth",
        top: gridRef.current.offsetTop - 40,
      }),
    [gridRef]
  );

  const handleChange = useCallback(
    (propertyName: string, value: any, context?: any) => {
      onBasicPropertyChange(propertyName, value, context);
    },
    []
  );

  useEffect(() => {
    executeScroll();
  }, []);

  useEffect(() => {
    if (!state || state === location.state) {
      return;
    }
    handleChange("state", state, null);
  }, [state]);

  useEffect(() => {
    if (
      location.country === "Mexico" &&
      location.providedServices &&
      location.providedServices.some(
        ({ currency }) => currency !== Currency.MXN
      )
    ) {
      const newServices = location.providedServices.map((srv) => ({
        ...srv,
        currency: Currency.MXN,
      }));
      handleChange("providedServices", newServices, null);
    }
  }, [location.country]);

  return (
    <Grid item xs={12} md={12} ref={gridRef}>
      <Grid container sx={{ justifyContent: "center", rowGap: 4 }}>
        <Grid
          item
          sx={{
            py: 1,
            borderBottom: `solid 3px ${COLORS.BLUE_1}`,
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 1,
          }}
          xs={12}
          md={10}
        >
          <Typography variant="h4">Consultorio {index + 1}</Typography>
          {index === 0 ? (
            <IconButton
              disableRipple
              aria-label="add"
              size="medium"
              sx={{
                backgroundColor: COLORS.BLUE_1,
                color: "white",
                ":hover": {
                  backgroundColor: COLORS.BLUE_2,
                  color: COLORS.BLUE_1,
                },
              }}
              onClick={() => onAddLocation()}
            >
              <AddIcon />
            </IconButton>
          ) : (
            <IconButton
              disableRipple
              aria-label="remove"
              size="medium"
              sx={{
                backgroundColor: COLORS.BLUE_1,
                color: "white",
                ":hover": {
                  backgroundColor: COLORS.BLUE_2,
                  color: COLORS.BLUE_1,
                },
              }}
              onClick={() => onRemoveLocation(index)}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </Grid>

        {inputs.map(({ gridSize, propertyName, label, ...rest }, i) => {
          const name = `locations.${index}.${propertyName}`;
          inputsForValidation.push({
            gridSize,
            propertyName,
            label,
            name,
            ...rest,
          } as any);

          return (
            <Grid
              item
              xs={12}
              md={10}
              sx={{ px: 1, my: 2 }}
              key={`row-input-${index}-${propertyName}-${i}`}
              {...gridSize}
            >
              <Typography
                sx={{
                  color: COLORS.BLUE_1,
                  textAlign: "left",
                }}
                variant="caption"
              >
                {label}
              </Typography>
              <InputField
                backgroundMode="transparent"
                propertyName={propertyName}
                name={name}
                disabled={rest.disabled || isLoading}
                value={_.get(
                  location as unknown as Record<string, string | number>,
                  propertyName,
                  ""
                )}
                handleChange={handleChange}
                {...rest}
                label=""
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
