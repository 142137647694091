import styled from "@emotion/styled";
import { ListItem, ListItemIcon, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import COLORS from "../../constants/colors";

const StyledListItem = styled(ListItem)({
  // Add your custom styles here
  color: "#000", // Example color
  paddingLeft: 0,
  "& .MuiSvgIcon-root": {
    color: COLORS.BLUE_1,
    fontSize: "2.2rem",
  },
});

export const CheckIconItem = ({ text }: { text: string }) => (
  <StyledListItem>
    <ListItemIcon>
      <CheckCircleOutlineIcon />
    </ListItemIcon>
    <Typography variant="h5">{text}</Typography>
  </StyledListItem>
);
