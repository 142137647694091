import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Container } from "@mui/material";

import { Education } from "components/molecules/sign-up/Education";
import { Experience } from "components/molecules/sign-up/Experience";
import { PersonalInfo } from "components/molecules/sign-up/PersonalInfo";
import { useAppDispatch } from "hooks";
import { TitledLayout } from "layouts/TitledLayout";
import { Entity, PossibleUsers, Steps } from "interfaces";
import {
  UserType,
  LoadingEnum,
  GetStartedPages,
} from "@mapsy/shared";
import { Location } from "components/molecules/sign-up/Location";
import { PreTherapists } from "components/molecules/form/PreTherapists";
import { ValidationProvider } from "providers/FormProvider";
import COLORS from "constants/colors";
import { selectSessionState } from "features/session/session.slice";
import { GetStartedHeader } from "components/atoms/GetStartedHeader";
import { defaultTherapist, defaultPatient } from "constants/defaultUserValues";
import { Final } from "components/molecules/sign-up/Final";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { useAuth } from "hooks/useAuthAPI";

export const GetStarted = () => {
  const {
    profileInfo,
    isLoggedIn,
    isLoading: sessionLoading,
  } = useSelector(selectSessionState);
  const { getUserInfo, isLoading: userInfoLoading } = useAuth();
  const [userType, setUserType] = useState(UserType.None);
  const [currentPage, setCurrentPage] = useState<GetStartedPages>(
    GetStartedPages.PERSONAL_INFO
  );
  const [userInfo, setUserInfo] = useState<PossibleUsers & { _id: string }>({
    email: profileInfo?.email || "",
    _id: profileInfo?.id || "",
  });
  const [succededRegistration, setSuccededRegistration] = useState(false);
  useBeforeUnload({
    when: !succededRegistration,
  });

  const fetchUserInfo = useCallback(
    async (id: string, userType: UserType) => {
      const data = await getUserInfo(id, userType);
      setUserInfo((prev_user: PossibleUsers) => ({ ...prev_user, ...data }));
    },
    [profileInfo, setUserInfo]
  );

  useEffect(() => {
    if (!isLoggedIn || !profileInfo) {
      return;
    }

    setUserType(profileInfo.type);

    if (profileInfo.type === UserType.Therapist) {
      setCurrentPage(GetStartedPages.PRE);
    }

    const defaultValues =
      profileInfo?.type === UserType.Therapist
        ? defaultTherapist
        : defaultPatient;

    setUserInfo((prev_user: PossibleUsers) => ({
      ...prev_user,
      ...defaultValues,
      email: profileInfo.email,
      _id: profileInfo.id,
    }));

    fetchUserInfo(profileInfo.id, profileInfo.type);
  }, [profileInfo, isLoggedIn]);

  const changePage = useCallback(
    (entity: Entity, goBack = false) => {
      setUserInfo((prev: PossibleUsers & { _id: string }) =>
        _.merge({}, prev, entity)
      );
      setCurrentPage((p: number) => {
        let _page = p;
        if (goBack) {
          _page -= 1;
        } else {
          _page += 1;
        }

        if (_page < GetStartedPages.PRE || _page > GetStartedPages.FINAL) {
          return p;
        }

        if (userType === UserType.Patient) {
          if (goBack) {
            return GetStartedPages.PERSONAL_INFO;
          }
          return GetStartedPages.FINAL;
        }

        return _page;
      });
    },
    [userType]
  );

  const steps: Steps = {
    [GetStartedPages.PRE]: {
      title: "",
      component: (
        <PreTherapists
          changePage={() => changePage({})}
          userType={userType}
          disableSubmit={!isLoggedIn}
          userInfo={userInfo as any}
        />
      ),
    },
    [GetStartedPages.PERSONAL_INFO]: {
      title: "Datos Personales",
      component: (
        <PersonalInfo
          changePage={changePage}
          userType={userType}
          userInfo={userInfo as any}
        />
      ),
    },
    [GetStartedPages.EDUCATION]: {
      title: "Formación",
      component: (
        <Education
          changePage={changePage}
          userType={userType}
          userInfo={userInfo as any}
        />
      ),
    },
    [GetStartedPages.EXPERIENCE]: {
      title: "Experiencia",
      component: (
        <Experience
          changePage={changePage}
          userType={userType}
          userInfo={userInfo as any}
        />
      ),
    },
    [GetStartedPages.LOCATION]: {
      title: "Consultorio",
      subtitle:
        "Si das terapia presencial y en línea, puedes registrar tu consultorio en línea ahora, y después ir a Mis consultorios para añadir otro con modalidad presencial. No compartiremos la calle ni el número de ninguno de tus consultorios hasta que alguien agende contigo y el consultorio sea presencial.",
      component: (
        <Location
          changePage={changePage}
          userType={userType}
          userInfo={userInfo}
        />
      ),
    },
    [GetStartedPages.FINAL]: {
      title: "Estamos creando tu cuenta",
      component: (
        <Final
          changePage={changePage}
          userType={userType}
          userInfo={userInfo}
          onSucceededRegistration={() => setSuccededRegistration(true)}
        />
      ),
    },
  };

  return (
    <Container id="get-started-container" sx={{ backgroundColor: COLORS.WHITE, maxHeight: "100vh", height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        id="get-started"
        sx={{
          display: "flex",
          alignItems: { md: "center" },
          alignSelf: { xs: currentPage === GetStartedPages.PRE ? "center" : "flex-start", md: "flex-start" },
          flexDirection: {
            xs: "column",
          },
          gap: 1,
          mt: { md: "3rem", xs: "1rem" },
          width: "100%"
        }}
      >
        {!userInfo.email ||
        !userInfo._id ||
        sessionLoading === LoadingEnum.pending ||
        userInfoLoading ? (
          <CircularProgress />
        ) : (
          <>
            <GetStartedHeader
              userType={userType}
              changePage={changePage}
              page={currentPage}
              pagesWithoutArrow={[GetStartedPages.PRE, GetStartedPages.FINAL]}
            />
            <Box
              id="get-started-body"
              sx={{ p: { md: 4, xs: 2 } }}>
              <TitledLayout
                title={steps[currentPage].title}
                subtitle={steps[currentPage].subtitle}
              >
                <ValidationProvider>
                  {steps[currentPage].component}
                </ValidationProvider>
              </TitledLayout>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};
