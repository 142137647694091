import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { capitalize, Grid, Typography } from "@mui/material";

import { CustomButton } from "components/atoms/Button";
import COLORS from "constants/colors";
import { selectReasonState } from "features/reason/reasonSice";
import { useAppSelector } from "hooks";
import { TitledLayout } from "layouts/TitledLayout";
import {
  Action,
  AppointmentStatus,
  CreateAppointment,
  ErrorMsg,
  Modality,
  ModalitySpanish,
  StylesEnum,
  UserType,
} from "@mapsy/shared";
import {
  ProvidedService,
  ProvidedServiceTypesSpanish,
  EndpointGenerator,
} from "@mapsy/shared";
import { selectSessionState } from "features/session/session.slice";
import { useAxios } from "hooks/useAxios";
import { setToken } from "utils/setToken";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { DATE_LONG_FORMAT } from "constants/defaultUserValues";
import { CustomLink } from "components/atoms/Link";
import { DEFAULT_APPOINTMENT_DURATION } from "components/atoms/NewAppointmentModal";
import { usePromotions } from "providers/PromotionsProvider";
import { SelectedAppointmentState } from "interfaces";
import { SELECTED_APPOINTMENT } from "components/molecules/TherapistCard";

export const AppointmentConfirmation = () => {
  const nav = useNavigate();
  const { createAnalytic } = useAnalytics();
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const { postData, errorMsg, isLoading } = useAxios();
  const [isInvalidUser, setIsInvalidUser] = useState(false);
  const { currentClosestPromotion, alreadyStarted } = usePromotions();
  const selectedAppointment: SelectedAppointmentState | null = useMemo(() => {
    try {
      const data = sessionStorage.getItem(SELECTED_APPOINTMENT);
      if (data) {
        return JSON.parse(data) as SelectedAppointmentState;
      }
      return null;
    } catch (e) {
      return null;
    }
  }, []);

  useEffect(() => {
    if (
      !selectedAppointment?.location ||
      !selectedAppointment.hour ||
      !selectedAppointment.therapist ||
      selectedAppointment.service === undefined
    ) {
      if (
        selectedAppointment?.askForConsultingReason &&
        !selectedAppointment.reason
      ) {
        nav("/reason");
        return;
      }
      nav("/all-therapists");
    }
  }, [selectedAppointment]);

  useEffect(() => {
    if (profileInfo?.type !== UserType.Patient) {
      setIsInvalidUser(true);
      return;
    }
    setIsInvalidUser(false);
  }, [profileInfo]);

  const info: { subtitle: string; data: string }[] = useMemo(() => {
    if (
      !selectedAppointment?.location ||
      !selectedAppointment.hour ||
      !selectedAppointment.therapist ||
      selectedAppointment.service === undefined
    ) {
      return [];
    }

    const { location, hour, service, therapist } = selectedAppointment;

    return [
      {
        subtitle: "Especialista",
        data: `${therapist?.firstName} ${therapist?.lastName}`,
      },
      {
        subtitle: "Terapia",
        data: ProvidedServiceTypesSpanish[service.serviceType!],
      },
      {
        subtitle: "Fecha",
        data: capitalize(moment(hour).format(DATE_LONG_FORMAT)),
      },
      {
        subtitle: "Hora",
        data: `${moment(hour).format("HH:mm")} hrs`,
      },
      {
        subtitle: "Modalidad y precio",
        data: location
          ? `${ModalitySpanish[location.modality]} - $${service.price} ${service.currency?.toLocaleLowerCase()}.*`
          : "Consultorio no encontrado",
      },
    ];
  }, [selectedAppointment]);

  const handleContinue = useCallback(async () => {
    if (
      isInvalidUser ||
      errorMsg ||
      !selectedAppointment?.location ||
      !selectedAppointment.hour ||
      !selectedAppointment.therapist ||
      selectedAppointment.service === undefined
    ) {
      return;
    }

    const patientId = profileInfo?.id;

    if (!patientId) {
      nav("/users/signin?type=patient&redirectTo=/appointment/confirmation");
      return;
    }

    const { reason, askForConsultingReason } = selectedAppointment;

    if (!token || (!reason && askForConsultingReason)) {
      nav("/reason");
      return;
    }

    const { location, hour, service, therapist } = selectedAppointment;

    const createAppointment: CreateAppointment = {
      patientId,
      therapistId: therapist._id,
      locationId: location._id,
      date: hour,
      duration: DEFAULT_APPOINTMENT_DURATION,
      providedService: service as ProvidedService,
      consultingReason: reason,
      appointmentStatus: AppointmentStatus.Pending,
    };

    const response: { _id: string } = await postData(
      EndpointGenerator.AppointmentAPI.baseURL,
      createAppointment,
      setToken(token),
      {
        409: ErrorMsg.AppointmentDuplicated,
        406: ErrorMsg.PatientRegistrationIncomplete,
      }
    );

    createAnalytic({
      action: Action.SUBMIT,
      componentName: COMPONENTS.APPOINTMENT_CONFIRMATION,
      data: createAppointment,
    });

    if (response?._id) {
      nav(`/patient/confirmed-appointment/${response._id}`);
      sessionStorage.removeItem(SELECTED_APPOINTMENT);
    }
  }, [isInvalidUser, profileInfo, selectedAppointment, token, errorMsg]);

  const handleEditAppointment = useCallback(() => {
    if (!selectedAppointment?.therapist) {
      return;
    }
    const { therapist } = selectedAppointment;

    if (!therapist?._id) {
      return;
    }

    createAnalytic({
      action: Action.UNDO,
      componentName: COMPONENTS.APPOINTMENT_CONFIRMATION,
      data: { therapistId: therapist._id },
    });

    nav(`/therapists/${therapist?._id}`);
  }, [selectedAppointment]);

  if (!selectedAppointment?.location) {
    return (
      <TitledLayout
        title="Para confirmar una cita, busca un terapeuta y selecciona un horario"
        containerSx={{ alignItems: "center", maxWidth: "sm" }}
      >
        <Grid
          item
          md={12}
          lg={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            mt: 3,
            gap: 2,
          }}
        >
          <CustomLink underline to={"/reason"}>
            Buscar terapeuta
          </CustomLink>
        </Grid>
      </TitledLayout>
    );
  }

  const { location } = selectedAppointment;

  return (
    <TitledLayout
      title="Confirma Los Datos de Tu Cita"
      containerSx={{ alignItems: "center", maxWidth: "sm" }}
    >
      {info.map(({ subtitle, data }, i) => (
        <Grid item key={`${subtitle}-${i}`} xs={12}>
          <Typography sx={{ fontWeight: 600 }}>
            {subtitle}:
            <Typography component="span" sx={{ ml: 1 }}>
              {data}
            </Typography>
          </Typography>
        </Grid>
      ))}
      {location.modality !== Modality.Online && (
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>
            Ubicación:
            <Typography component="span" sx={{ ml: 1 }}>
              {location
                ? `${location.community}, ${location.city}, ${location.state}`
                : "Ubicación del consultorio no encontrada"}
            </Typography>
          </Typography>
        </Grid>
      )}
      {currentClosestPromotion && alreadyStarted && (
        <Grid item xs={12}>
          <Typography variant="caption">
            Promoción vigente:{" "}
            <Typography variant="caption" sx={{ fontWeight: 600 }}>
              {currentClosestPromotion.name}
            </Typography>
            . Algunas promociones solo aplican para la primera cita. Revisa los{" "}
            <CustomLink
              to={currentClosestPromotion.promoTermsOfUsePath}
              underline
              variant="caption"
            >
              términos y condiciones de la promoción
            </CustomLink>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="caption">
          *Recuerda que el pago lo debes realizar directamente al terapeuta.{" "}
        </Typography>
        <Typography variant="caption">
          Tu terapeuta deberá proporcionarte la información de pago cuando te
          contacte.
        </Typography>
      </Grid>
      <Grid
        item
        md={12}
        lg={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          mt: 3,
          gap: 2,
        }}
      >
        {isInvalidUser && (
          <Typography style={{ color: COLORS.TEXT_RED, fontWeight: 600 }}>
            Sólo los pacientes pueden agendar citas
          </Typography>
        )}
        <CustomButton
          customStyle={StylesEnum.primary}
          children={"Continuar"}
          onClick={() => handleContinue()}
          disableRipple={true}
          isLoading={isLoading}
          disabled={isInvalidUser || Boolean(errorMsg)}
          sx={{
            borderRadius: "14px",
          }}
          type="button"
        />
        {errorMsg && (
          <Typography style={{ color: COLORS.TEXT_RED, fontWeight: 600 }}>
            {errorMsg}
          </Typography>
        )}
        {errorMsg === ErrorMsg.PatientRegistrationIncomplete && (
          <CustomLink to="/patient/my_profile" underline>
            Ir a mi perfil
          </CustomLink>
        )}
        <Typography
          variant="caption"
          sx={{
            fontWeight: 400,
            color: COLORS.BLUE_1,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={handleEditAppointment}
        >
          Editar los datos de mi cita
        </Typography>
      </Grid>
    </TitledLayout>
  );
};
