import _ from "lodash";
import moment, { Moment } from "moment";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Typography } from "@mui/material";

import {
  Action,
  AppointmentStatus,
  CreateAppointment,
  ErrorsObj,
  InputType,
  StylesEnum,
  UserType,
  EndpointGenerator,
  Appointment,
  ErrorMsg,
} from "@mapsy/shared";

import COLORS from "constants/colors";

import { CustomButton } from "./Button";
import { ModalLayout } from "layouts/ModalLayout";
import { selectSessionState } from "features/session/session.slice";
import { setToken } from "utils/setToken";
import { useAppSelector } from "hooks";
import { useAxios } from "hooks/useAxios";
import { Entity, Form } from "interfaces";
import { InputField } from "./InputField";
import { areInputsValid } from "utils/areInputsValid";
import { useAnalytics } from "hooks/useAnalytics";
import COMPONENTS from "constants/componentNames";
import { DATE_LONG_FORMAT } from "constants/defaultUserValues";
import { START_WORK_HOUR } from "pages/MyCalendar";
import { MapsySwitch } from "./MapsySwitch";
import { InfoTooltip } from "./InfoTooltip";

export const DEFAULT_APPOINTMENT_DURATION = 60;

interface Props {
  newAppointmentDate: Moment;
  onSavedAppointment: (newAppointmentWeek: number) => void;
  onClose: () => void;
  appointmentToEdit?: Appointment;
}

interface NewAppointmentValues {
  title: string;
  day: number;
  startTime: number;
  endTime: number;
}

const AVAILABLE_HOURS_LENGTH = 15;

export const NewAppointmentModal: React.FC<Props> = ({
  newAppointmentDate,
  onClose,
  onSavedAppointment: onSave,
  appointmentToEdit,
}) => {
  const { postData, patchData, isLoading, errorMsg } = useAxios();
  const { createAnalytic } = useAnalytics();
  const { token, profileInfo } = useAppSelector(selectSessionState);
  const [errors, setErrors] = useState<ErrorsObj>();
  const [values, setValues] = useState<NewAppointmentValues>({
    title: appointmentToEdit?.title || "",
    day: newAppointmentDate.valueOf(),
    startTime: newAppointmentDate.valueOf(),
    endTime: moment(newAppointmentDate)
      .add(
        appointmentToEdit?.duration || DEFAULT_APPOINTMENT_DURATION,
        "minutes"
      )
      .valueOf(),
  });
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const [allDayAppointment, setAllDayAppointment] = useState(
    appointmentToEdit ? appointmentToEdit.duration === 24 * 60 : false
  );

  useEffect(() => {
    setValues((_values) => {
      const prevHour = moment(_values.startTime).get("hour");
      return {
        ..._values,
        startTime: moment(_values.day).set("hour", prevHour).valueOf(),
        endTime: moment(_values.day)
          .set("hour", prevHour)
          .add(
            appointmentToEdit?.duration || DEFAULT_APPOINTMENT_DURATION,
            "minutes"
          )
          .valueOf(),
      };
    });
  }, [values.day, appointmentToEdit]);

  const inputs: Form = useMemo(
    () => [
      {
        propertyName: "title",
        label: "Título del evento",
        inputType: InputType.Text,
        placeholder: "Cita con ...",
        required: true,
        validation: {
          isRequired: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        propertyName: "day",
        label: "Día del evento",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: 7 }, (_, i) => {
          const nextDay = moment(newAppointmentDate).add(i, "day");
          return {
            label: nextDay.format(DATE_LONG_FORMAT),
            value: nextDay.valueOf(),
          };
        }),
      },
      {
        propertyName: "startTime",
        label: "Hora de inicio del evento",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: AVAILABLE_HOURS_LENGTH }, (_, i) => {
          const daySelected = values.day;
          const time = moment(daySelected).set("hour", i + START_WORK_HOUR);
          return {
            label: time.format("HH:mm"),
            value: time.valueOf(),
          };
        }),
        disabled: allDayAppointment,
      },
      {
        propertyName: "endTime",
        label: "Hora de fin del evento",
        inputType: InputType.Select,
        autoFocus: false,
        menuItems: Array.from({ length: AVAILABLE_HOURS_LENGTH }, (_, i) => {
          const daySelected = values.day;
          const time = moment(daySelected)
            .set("hours", i + START_WORK_HOUR)
            .add(DEFAULT_APPOINTMENT_DURATION, "minutes");
          return {
            label: time.format("HH:mm"),
            value: time.valueOf(),
          };
        }),
        disabled: allDayAppointment,
      },
    ],
    [values.day, newAppointmentDate, allDayAppointment]
  );

  const handleChange = useCallback(
    (propertyName: keyof NewAppointmentValues | string, value: any) => {
      const parsedValue = propertyName === "title" ? value.toString() : +value;

      setValues((_values) => ({ ..._values, [propertyName]: parsedValue }));
    },
    []
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      const therapistId =
        profileInfo?.type === UserType.Therapist && profileInfo?.id;

      if (!therapistId) {
        return;
      }

      if (
        !areInputsValid({
          inputs,
          values: values as unknown as Entity,
          setErrors,
        })
      ) {
        return;
      }

      if (values.endTime < values.startTime) {
        setErrors({ endTime: ErrorMsg.InvalidTimes });
        return;
      }

      const createAppointment: CreateAppointment = {
        date: values.startTime,
        therapistId,
        duration: moment(values.endTime).diff(values.startTime, "minutes"),
        title: values.title,
        isBlocking: true,
        appointmentStatus: AppointmentStatus.Confirmed,
      };

      if (allDayAppointment) {
        createAppointment.date = moment(values.endTime)
          .startOf("day")
          .valueOf();
        createAppointment.duration = 24 * 60;
      }

      let response: { _id: string } | string;

      if (appointmentToEdit) {
        response = await patchData(
          EndpointGenerator.AppointmentAPI.urlById(appointmentToEdit._id),
          createAppointment,
          setToken(token)
        );
      } else {
        response = await postData(
          EndpointGenerator.AppointmentAPI.baseURL,
          createAppointment,
          setToken(token),
          {
            409: ErrorMsg.BlockingAppointmentDuplicated,
          }
        );
      }

      createAnalytic({
        action: Action.SUBMIT,
        componentName: COMPONENTS.NEW_APPOINTMENT_MODAL,
        data: {
          createAppointment,
          response,
          updateAppointment: Boolean(appointmentToEdit),
        },
      });

      if (response) {
        setHasSucceeded(true);
        onSave(newAppointmentDate.week());
        onClose();
      }
    },
    [newAppointmentDate, values, allDayAppointment, appointmentToEdit]
  );

  return (
    <ModalLayout
      isOpen={Boolean(newAppointmentDate)}
      onClose={onClose}
      maxWidthContainer="sm"
    >
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ padding: 2, gap: 3 }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Typography
              sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}
              variant="h4"
            >
              {appointmentToEdit ? "Actualizar cita" : "Crear cita"}
            </Typography>
          </Grid>
          {inputs.map(({ gridSize = { xs: 12 }, propertyName, ...rest }, i) => (
            <Grid
              xs={12}
              id={`row-input-container-${propertyName}-${i}`}
              item
              key={`row-input-container-${propertyName}-${i}`}
              sx={{ px: 1, my: 1.5 }}
            >
              <Grid container sx={{ flexDirection: "column" }}>
                <Grid item xs={12}>
                  <Typography>{rest.label}:</Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <InputField
                    backgroundMode="transparent"
                    propertyName={propertyName}
                    value={_.get(values, propertyName, "")}
                    handleChange={handleChange}
                    helperText={errors && errors[propertyName]}
                    {...rest}
                    label=""
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid xs={12} item sx={{ px: 1, my: 1.5 }}>
            <Grid container sx={{ flexDirection: "column" }}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ display: "flex", gap: 2, alignItems: "center" }}
              >
                <MapsySwitch
                  checked={allDayAppointment}
                  onChange={(e, checked) => setAllDayAppointment(checked)}
                />
                <Typography>Todo el día</Typography>
                <InfoTooltip title="¿El evento dura toda tu jornada laboral?" />
              </Grid>
            </Grid>
          </Grid>
          {errorMsg && (
            <Grid item xs={12}>
              <Typography>{errorMsg}</Typography>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={{ mt: 3, display: "flex", justifyContent: "right" }}
          >
            <CustomButton
              customStyle={StylesEnum.primary}
              sx={{ px: 1 }}
              hasSucceeded={hasSucceeded}
              type="submit"
              isLoading={isLoading}
            >
              {appointmentToEdit ? "Actualizar " : "Continuar"}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </ModalLayout>
  );
};
