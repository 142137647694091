import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import defaultUserPic from "assets/img/user-pic.webp";

interface ImageCacheContextProps {
  cache: Record<string, string>;
  cacheImage: (url: string) => Promise<void>;
  loading: boolean;
  removeCachedImg: (url: string) => void;
}

export const ImageCacheContext = createContext<ImageCacheContextProps>({
  cache: {},
  cacheImage: () => new Promise((res) => res(undefined)),
  loading: false,
  removeCachedImg: () => {},
});

export const ImageCacheProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [cache, setCache] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  const cacheImage = useCallback(
    async (url: string) => {
      if (!cache[url]) {
        setLoading(true);
        const response = await fetch(url);
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        setCache((prevCache) => ({ ...prevCache, [url]: objectURL }));
        setLoading(false);
      }
    },
    [cache]
  );

  const removeCachedImg = useCallback(
    (url: string) => {
      if (!cache[url]) {
        return;
      }
      setCache((prevCache) => ({ ...prevCache, [url]: "" }));
    },
    [cache]
  );

  return (
    <ImageCacheContext.Provider
      value={{ cache, cacheImage, loading, removeCachedImg }}
    >
      {children}
    </ImageCacheContext.Provider>
  );
};

export const useImageCache = (url: string, loadImage: boolean) => {
  const { cache, cacheImage, loading } = useContext(ImageCacheContext);

  useEffect(() => {
    if (!loadImage) {
      return;
    }

    cacheImage(url);
  }, [url, cacheImage, loadImage]);

  return { imageBlob: cache[url] || defaultUserPic, loading };
};
