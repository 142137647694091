import { useCallback, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { Action, COLORS, StylesEnum } from "@mapsy/shared";
import { useAnalytics } from "hooks/useAnalytics";
import { useLocation } from "react-router-dom";
import COMPONENTS from "constants/componentNames";
import { ModalLayout } from "layouts/ModalLayout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FAQGenericItems, FAQPatientItems } from "constants/FAQItems";

export const FAQButton = () => {
  const { pathname } = useLocation();
  const { createAnalytic } = useAnalytics();
  const [openModal, setOpenModal] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleClick = useCallback(() => {
    createAnalytic({
      action: Action.CLICK,
      componentName: COMPONENTS.FAQ_BUTTON,
      data: {
        label: "¿Tienes dudas?",
        pathname,
      },
    });
    setOpenModal(true);
  }, [pathname]);

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setExpanded(false);
  }, []);

  return (
    <>
      <Tooltip title="¿Tienes dudas?">
        <IconButton
          onClick={handleClick}
          sx={{
            position: "absolute",
            top: "1rem",
            right: "2rem",
            color: COLORS.BLUE_1,
            cursor: "pointer",
          }}
        >
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <ModalLayout isOpen={openModal} onClose={handleClose}>
        <Grid container sx={{ padding: 2 }} spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                color: COLORS.BLUE_1,
                textAlign: "center",
                maxWidth: "sm",
                margin: "auto",
              }}
            >
              Preguntas frecuentes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {[...FAQGenericItems, ...FAQPatientItems].map(
              ({ question, answer }, i) => {
                const id = `panel-${i}`;
                return (
                  <Accordion
                    sx={{ backgroundColor: "transparent" }}
                    expanded={expanded === id}
                    onChange={handleChange(id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${id}-content"`}
                      id={`${id}-header"`}
                      sx={{
                        color: COLORS.BLUE_1,
                        fontWeight: 600,
                      }}
                    >
                      <Typography>{question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              }
            )}
          </Grid>
        </Grid>
      </ModalLayout>
    </>
  );
};
