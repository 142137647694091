import { Action } from "@mapsy/shared";
import { AxiosRequestConfig } from "axios";
import COMPONENTS from "constants/componentNames";
import {
  selectSessionState,
  setSourceInfo,
} from "features/session/session.slice";
import { useAppDispatch } from "hooks";
import { useAnalytics } from "hooks/useAnalytics";
import { useAxios } from "hooks/useAxios";
import {
  IPResponse,
  IPLocationResponse,
  SourceInfo,
} from "interfaces/analytics.interface";
import moment from "moment";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isMobileDevice } from "utils/isMobile";

interface Visit {
  pathname: string;
  visitTime?: number;
}

interface Props {
  children: ReactNode;
}

export const AnalyticsVisitProvider: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { getData } = useAxios();
  const { createAnalytic, updateAnalytic } = useAnalytics();
  const { sourceInfo } = useSelector(selectSessionState);
  const [errorGettingSourceInfo, setErrorGettingSourceInfo] = useState(false);
  const [lastVisit, setLastVisit] = useState<Visit | undefined>();
  const [lastVisitAnalyticIp, setLastVisitAnalyticIp] = useState<string>();

  const fetchSourceInfo = useCallback(
    async (config?: AxiosRequestConfig) => {
      try {
        let data: SourceInfo = { isMobile: isMobileDevice() };
        if (sourceInfo?.ip) {
          return;
        }
        const ipResponse: IPResponse = await getData(
          "https://api.ipify.org?format=json",
          config
        );
        if (!ipResponse?.ip) {
          return;
        }
        data = { ...data, ...ipResponse };

        const locationResponse: IPLocationResponse = await getData(
          `https://ipapi.co/${ipResponse.ip}/json/`,
          config
        );

        if (locationResponse) {
          const { country, country_code, city, timezone, asn, org, region } =
            locationResponse;

          data = {
            ...data,
            country,
            country_code,
            city,
            timezone,
            asn,
            org,
            region,
          };
        }
        dispatch(setSourceInfo(data));
      } catch (e: any) {
        setErrorGettingSourceInfo(true);
      }
    },
    [sourceInfo?.ip]
  );

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetchSourceInfo({ signal });
    
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!sourceInfo && !errorGettingSourceInfo) {
      return;
    }

    const now = moment().valueOf();
    setLastVisit({
      pathname,
      visitTime: now,
    });
  }, [sourceInfo, errorGettingSourceInfo]);

  const updateVisitDuration = useCallback((id: string, duration: number) => {
    updateAnalytic(id, {
      action: Action.VISIT,
      "data.duration": duration,
    });
  }, []);

  const saveVisit = useCallback(
    async (data: Visit, config?: AxiosRequestConfig) => {
      const response = await createAnalytic(
        {
          action: Action.VISIT,
          componentName: COMPONENTS.ANALYTICS_VISIT_PROVIDER,
          data,
        },
        config
      );
      if (!response?._id) {
        return;
      }
      setLastVisitAnalyticIp(response._id);
    },
    [createAnalytic]
  );

  useEffect(() => {
    if (lastVisit && pathname !== lastVisit.pathname) {
      const now = moment().valueOf();
      const duration = now - (lastVisit.visitTime || 0);
      if (lastVisitAnalyticIp) {
        updateVisitDuration(lastVisitAnalyticIp, duration);
      }

      setLastVisit({
        pathname,
        visitTime: now,
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (!lastVisit) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;
    saveVisit(lastVisit, { signal });

    return () => {
      controller.abort();
    };
  }, [lastVisit]);

  return <>{children}</>;
};
