import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logo from "../../assets/img/logo_without_label.png";

import { GetStartedPages, UserType } from "@mapsy/shared";
import COLORS from "constants/colors";
import { Entity } from "interfaces";
import React, { ReactNode } from "react";
import { Box, LinearProgress } from "@mui/material";
import { CustomLink } from "./Link";

interface Props {
  userType: UserType;
  page: GetStartedPages;
  pagesWithoutArrow: GetStartedPages[];
  changePage: (object: Entity, goBack: boolean) => void;
}

const GetStartedWrapper: React.FC<{
  children: ReactNode;
  progressValue?: number;
}> = ({ children, progressValue }) => (
  <Box
    sx={{
      display: "flex",
      width: "100%",
      justifyContent: { xs: "center", md: "start" },
      ml: { md: "1rem" },
      height: "content",
      position: "relative",
      alignContent: "baseline"
    }}
  >
    <Box
      sx={{
        // position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        height: "100%",
        alignItems: "center",
        alignSelf: "start",
        maxHeight: "150px",
      }}
    >
      {children}
    </Box>
    {progressValue && (
      <Box sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "45px",
        }}>
        <LinearProgress
                sx={{
                  width: "60%",
                  visibility: "visible",
                  flexGrow: 1
                }}
                variant="determinate"
                value={progressValue}
              />
      </Box>
      
    )}
  </Box>
);

export const GetStartedHeader: React.FC<Props> = ({
  userType,
  page,
  pagesWithoutArrow,
  changePage,
}) => {
  if (userType === UserType.Patient) {
    return (
      <GetStartedWrapper>
        <CustomLink to="/">
          <img src={logo} alt="mapsy-logo" width={130} />
        </CustomLink>
      </GetStartedWrapper>
    );
  }

  if (pagesWithoutArrow.includes(page)) {
    return (
      <GetStartedWrapper>
        <CustomLink to="/">
          <img src={logo} alt="mapsy-logo" width={130} />
        </CustomLink>
      </GetStartedWrapper>
    );
  }

  return (
    <GetStartedWrapper progressValue={page * 25}>
      <ArrowBackIcon
        sx={{ color: COLORS.BLUE_1, fontSize: 45, cursor: "pointer" }}
        onClick={() => changePage({}, true)}
      />
    </GetStartedWrapper>
  );
};
