import { UserType } from "@mapsy/shared";

import { MyProfilePatient } from "components/organisms/MyProfilePatient";
import { MyProfileTherapist } from "components/organisms/MyProfileTherapist";
import { ValidationProvider } from "providers/FormProvider";

interface TherapistProps {
  userType: UserType.Therapist;
  showLocations?: boolean;
}
interface PatientProps {
  userType: UserType.Patient;
  showLocations?: boolean;
}

export const MyProfile: React.FC<TherapistProps | PatientProps> = ({
  userType,
  showLocations,
}) => {
  if (userType === UserType.Patient) {
    return (
      <ValidationProvider>
        <MyProfilePatient />
      </ValidationProvider>
    );
  }

  if (userType === UserType.Therapist) {
    return (
      <ValidationProvider>
        <MyProfileTherapist showLocations={showLocations} />
      </ValidationProvider>
    );
  }

  return <></>;
};

export default MyProfile;
