export enum Interaction {
  OnLoadHome,
  PositionCalculated,
  PositionFailed,
  PatientCreated,
  PatientDeleted,
  TherapistCreated,
  TherapistDeleted,
  TherapistVisited,
  TherapistClosed,
  ProposalSent,
  ProposalAccepted,
  ProposalRefused,
  AppointmentCreated,
  AppointmentDeleted,
  Login,
  AdminDeleted,
  Prompt,
}

export enum Action {
  VISIT = "VISIT",
  CLICK = "CLICK",
  DOUBLE_CLICK = "DOUBLE_CLICK",
  SUBMIT = "SUBMIT",
  OPEN = "OPEN",
  CLOSE = "CLOSE",
  EDIT = "EDIT",
  UNDO = "UNDO",
  ERROR = "ERROR"
}
