import { Box } from "@mui/material";
import { MisionVisionValues } from "components/molecules/MisionVisionValues";
import { Find } from "components/organisms/Find";
import { How } from "components/organisms/How";
import { Meet } from "components/organisms/Meet";
import { Reviews } from "components/organisms/Reviews";
import { MeetProps } from "interfaces";

export const Home = () => {
  const meetProps: MeetProps = {
    title: "Conoce a los psicólogos de Mapsy",
    button: { label: "Comenzar", href: "/reason" },
    paragraphs: [
      {
        children: `Contamos con profesionales altamente cualificados
      y `,
        sx: {},
        component: "span",
      },
      {
        children: `comprometidos con tu bienestar emocional.`,
        sx: { fontWeight: 500 },
        component: "span",
      },
      {
        children: <br />,
        sx: {},
      },
      {
        children: `Cada miembro de nuestro equipo posee una
      sólida formación académica que es validada por un `,
        sx: {},
        component: "span",
      },
      {
        children: `proceso de
      verificación.`,
        sx: { fontWeight: 500 },
        component: "span",
      },
    ],
  };

  return (
    <Box>
      <Find />
      <How />
      <Meet
        title={meetProps.title}
        button={meetProps.button}
        paragraphs={meetProps.paragraphs}
      />
      <MisionVisionValues backgroundStyle="blue" />
      {/* <Reviews /> // Se comenta para el MVP1 hasta que tengamos reviews reales */}
    </Box>
  );
};
