import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import COLORS from "constants/colors";
import { AlertType } from "enums/alert.enum";
import { SuccessIcon } from "icons/SuccessIcon";
import { ModalLayout } from "layouts/ModalLayout";
import React, { ReactNode, useEffect, useState } from "react";
import { CustomButton } from "./Button";
import { StylesEnum } from "@mapsy/shared";

interface Props {
  type: AlertType;
  isOpen: boolean;
  title: string;
  body: string;
  autoclose?: boolean;
  onClose?: () => void;
  actions?: {
    onAccept: () => void;
    onCancel: () => void;
  };
}

const AlertModalWrapper: React.FC<
  Omit<Props, "type"> & {
    icon: ReactNode;
    children?: ReactNode;
  }
> = ({ icon, title, body, actions, ...props }) => {
  return (
    <ModalLayout {...props} maxWidthContainer="sm">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {icon}
        <Typography variant="h4" sx={{ color: COLORS.BLUE_1 }}>
          {title}
        </Typography>
        <Typography>{body}</Typography>
      </Box>
      {props.children}
      {actions && (
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "right",
              gap: 3,
            }}
          >
            <CustomButton
              customStyle={StylesEnum.secondary}
              sx={{
                padding: { md: "12px 48px", xs: "8px 12px" },
                borderRadius: "16px",
              }}
              onClick={actions.onCancel}
            >
              Volver
            </CustomButton>
            <CustomButton
              children={"Salir"}
              customStyle={StylesEnum.primary}
              onClick={actions.onAccept}
            />
          </Grid>
        </Grid>
      )}
    </ModalLayout>
  );
};

const AUTO_CLOSE_INTERVAL = 3000;

export const AlertModal: React.FC<Props> = ({
  type,
  autoclose = true,
  ...props
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!props.isOpen || !autoclose) {
      return;
    }

    const interval = setInterval(() => {
      setProgress((_oldProgress) => {
        if (_oldProgress === 100) {
          props.onClose?.();
          return 0;
        }

        return _oldProgress + 10;
      });
    }, AUTO_CLOSE_INTERVAL / 10);

    return () => clearInterval(interval);
  }, [props.isOpen, autoclose]);

  if (type === AlertType.Success) {
    return (
      <AlertModalWrapper {...props} icon={<SuccessIcon />}>
        <LinearProgress variant="determinate" value={progress} />
      </AlertModalWrapper>
    );
  }

  return <AlertModalWrapper {...props} icon={<></>}></AlertModalWrapper>;
};
