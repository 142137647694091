import { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import userIcon from "assets/svg/user.svg";
import { MenuOptions } from "interfaces/navbar.interface";
import { useNavigate } from "react-router-dom";
import COLORS from "constants/colors";

interface Props {
  popoverMenu: MenuOptions;
}
export const SessionPopover: React.FC<Props> = ({ popoverMenu }) => {
  const nav = useNavigate();
  const [menu, setMenu] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setMenu(event.currentTarget);
  };

  const handleClose = () => {
    setMenu(null);
  };

  const menuOpen = Boolean(menu);

  return (
    <div>
      <img
        src={userIcon}
        alt="user"
        aria-owns={"menu-click-open"}
        onClick={handleClick}
        style={{
          cursor: "pointer",
        }}
      ></img>
      <Popover
        id="menu-click-open"
        open={menuOpen}
        anchorEl={menu}
        onClose={handleClose}
        sx={{
          ".MuiPopover-paper": {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {popoverMenu.map(({ label, href, onClick, sx = {} }, i) => (
          <Typography
            key={`popover-menu-item-${label}-${i}`}
            sx={{
              p: 2,
              cursor: "pointer",
              backgroundColor: "rgba(151, 217, 219, 0.5)",
              color: COLORS.BLUE_1,
              textAlign: "center",
              border: "solid 1px rgba(151, 217, 219, 0.8)",
              fontWeight: 500,
              ":hover": {
                backgroundColor: COLORS.GREY,
              },
              ...sx,
            }}
            onClick={() => {
              handleClose();
              if (onClick) {
                onClick();
                return;
              }
              href && nav(href);
            }}
          >
            {label}
          </Typography>
        ))}
      </Popover>
    </div>
  );
};
