import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TitleUpdater: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "Mapsy Ψ - Bienvenido";
        break;
      case "/reason":
        document.title = "Mapsy Ψ - ¿Cuál es tu motivo de consulta?";
        break;
      case "/suggested-therapists":
        document.title = "Mapsy Ψ - Terapeutas sugeridos";
        break;
      case "/join":
        document.title = "Mapsy Ψ - Únete";
        break;
      case "/terms-and-conditions":
        document.title = "Mapsy Ψ - Términos y condiciones";
        break;
      case "/privacy-policy":
        document.title = "Mapsy Ψ - Aviso de privacidad";
        break;
      case "/about-us":
        document.title = "Mapsy Ψ - Quiénes somos";
        break;
      case "/contact":
        document.title = "Mapsy Ψ - Contáctanos";
        break;
      case "/users/signin":
        document.title = "Mapsy Ψ - Inicia sesión";
        break;
      case "/users/signup":
        document.title = "Mapsy Ψ - Regístrate";
        break;
      case "/users/forgot-password":
        document.title = "Mapsy Ψ - Olvidé mi contraseña";
        break;
      case "/patient/my_profile":
        document.title = "Mapsy Ψ - Consultante - Mi perfil";
        break;
      case "/patient/my_appointments":
        document.title = "Mapsy Ψ - Mis citas";
        break;
      case "/patient/review":
        document.title = "Mapsy Ψ - Opinión";
        break;
      case "/therapist/my_profile":
        document.title = "Mapsy Ψ - Terapeuta - Mi perfil";
        break;
      case "/therapist/my_calendar":
        document.title = "Mapsy Ψ - Terapeuta - Mi agenda";
        break;
      case "/therapist/my_locations":
        document.title = "Mapsy Ψ - Terapeuta - Mis consultorios";
        break;
      default:
        document.title = "Mapsy Ψ";
        break;
    }
  }, [location.pathname]);

  return null;
};

export default TitleUpdater;
