export const COLORS = {
  BLUE_1: "#02587B",
  BLUE_2: "#5CB4C2",
  BLUE_3: "#97D9DB",
  BLUE_MAPSY: "#CAFCFF",
  PURE_WHITE: "#FFFFFF",
  WHITE: "#E0E8EB",
  YELLOW: "#F4BA00",
  GREY: "#C8E0E9",
  DISABLED_GREY: "#8AA29E",
  TEXT_RED: "#FF0707",
};
