import { APINames } from "../..";
import BaseAPI from "./BaseEndpointAPI";

class PromotionsAPI extends BaseAPI {
  constructor() {
    super(APINames.PROMOTIONS);
  }

  currentPromotions(time: number, timezone: string, daysBefore: number) {
    const query = new URLSearchParams({ time, timezone, daysBefore } as any);
    return `${this.baseURL}/current-promotions?${query}`;
  }
}

const promotionsAPI = new PromotionsAPI();
export default promotionsAPI;